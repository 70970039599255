import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { useDispatch } from "react-redux";
import { createInstructions } from "../../../reducers/assessment";
import EditorComponent from '../../Common/Quill/quillEditor';
import './createInstructions.css';

const CreateInstructions = ({ onSave, instructions }) => {
  const [instruction, setInstruction] = useState(instructions || '');
  const dispatch = useDispatch();

  const handleEditorChange = (newContent) => {
    setInstruction(newContent);
  };

  const handleAddInstruction = () => {
    dispatch(createInstructions(instruction));
    onSave(instruction);
  };

  return (
    <div className="create-instructions-container">
      <Grid container justifyContent="flex-end">
        <Button className="filled-button" onClick={handleAddInstruction}>
          Save Instruction
        </Button>
      </Grid>
      <EditorComponent
        initialData={instruction}
        onChangeReference={handleEditorChange}
        placeholder="Insert instructions here"
      />
    </div>
  );
};

export default CreateInstructions;
