import getConfig from "../../config/index";

export const AssessmentAPIService = {
    async fetchOnlineTestsByExamId(assessment_id, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/by_exam/?exam_id=${assessment_id}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch online tests:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching online tests:', error);
            return null;
        }
    },

    async fetchAssessment(assessment_id, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/exam-papers/${assessment_id}/get-by-id/`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch assessment:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching assessment:', error);
            return null;
        }
    },

async updateExam(examId, examData, token) {
    const config = getConfig();
    const url = `${config.api.base_url}/exam-papers/${examId}/update_exam/`; // Include examId in the URL

    try {
        const response = await fetch(url, {
            method: "PATCH", // Use PATCH to update
            body: JSON.stringify(examData),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        });

        if (response.ok) {
            return await response.json(); // Return response JSON if successful
        } else {
            console.error("Failed to update exam:", response.status);
            return null;
        }
    } catch (error) {
        console.error("Error updating exam:", error);
        return null;
    }
},

    async refreshFetchAssessment(onlineTestId, token) {
        const config = getConfig();
        const url = `${config.api.base_url}/online-tests/${onlineTestId}/refresh-status/`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    "OnlineTest": onlineTestId
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });
            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch answers:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching answers:', error);
            return null;
        }
    },

    async getStudentResults(onlineTestId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/answers/get_feedback/?online_test_id=${onlineTestId}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch test answers feedback:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching test answers feedback:', error);
            return null;
        }
    },

    async updateFeedback(answerId, feedback, token) {
        const config = getConfig();
        const url = `${config.api.base_url}/answers/update-feedback/`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    answer_id: answerId,
                    feedback: feedback,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });
            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to update feedback:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error updating feedback:', error);
            return null;
        }
    },

    async updateMarks(answerId, allocatedMarks, token) {
        const config = getConfig();
        const url = `${config.api.base_url}/answers/update-marks/`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    answer_id: answerId,
                    allocated_marks: allocatedMarks,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });
            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to update marks:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error updating marks:', error);
            return null;
        }
    },

    async completeReview(onlineTestId, token) {
        const config = getConfig();
        const url = `${config.api.base_url}/online-tests/${onlineTestId}/complete-review/`;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to complete review:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error completing review:', error);
            return null;
        }
    },

    async fetchStudentProgressStats(assessmentId, token) {
        const config = getConfig();
        const url = `${config.api.base_url}/student-progress-stats/${assessmentId}/`;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch student progress stats:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching student progress stats:', error);
            return null;
        }
    },

    async shallowFetchAssessments(token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/exam-papers/shallow-fetch/`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to perform shallow fetch:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error performing shallow fetch:', error);
            return null;
        }
    },

    async deleteAssessment(assessmentId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/exam-papers/${assessmentId}/`;

            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return response;
            } else {
                console.error('Failed to delete assessment:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error deleting assessment:', error);
            return null;
        }
    },

    async deleteOnlineTest(onlineTestId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/${onlineTestId}/delete-test/`;

            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return response;
            } else {
                console.error('Failed to delete online test:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error deleting online test:', error);
            return null;
        }
    },

    async fetchFeatureAnnouncements(token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/feature-announcements/`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch feature announcements:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching feature announcements:', error);
            return null;
        }
    },

    async createAnnouncement(announcementData, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/feature-announcements/`;

            return await fetch(url, {
                method: "POST",
                body: JSON.stringify(announcementData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });
        } catch (error) {
            console.error('Failed to create announcement:', error);
            throw error;
        }
    },

    async fixMarking(answerId, fixingPrompts, token) {
        const config = getConfig();
        const url = `${config.api.base_url}/answers/fix_marking/`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    answer_id: answerId,
                    fixing_prompts: fixingPrompts,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fix marking:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fixing marking:', error);
            return null;
        }
    },

    // Fetch exams for a specific course
    async fetchExamsForCourse(courseId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/exam-papers/exams-for-course/?course_id=${courseId}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch exams:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching exams:', error);
            return null;
        }
    },

    async fetchExamPaperInstructions(subjectId, gradeId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/exam-paper-instructions/get_instructions/?subject=${subjectId}&grade=${gradeId}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch exam paper instructions:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching exam paper instructions:', error);
            return null;
        }
    },

    // Add this in AssessmentAPIService
async createExam(examData, token) {
    const config = getConfig();
    const url = `${config.api.base_url}/exam-papers/`;

    try {
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(examData),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to create exam:", response.status);
            return null;
        }
    } catch (error) {
        console.error("Error creating exam:", error);
        return null;
    }
},

}
export default AssessmentAPIService;