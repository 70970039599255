import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule"; // For no change

const SummaryCard = ({ title, value, description, previousAverage, trendIcon, trendColor }) => (
  <Card sx={{ height: "100%", backgroundColor: 'white' }}>
    <CardContent>
      <Typography variant="h6" component="div">
        {title}
      </Typography>
      <Box display="flex" alignItems="flex-end">
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          {value}
        </Typography>
        {trendIcon && (
          <Box display="flex" alignItems="center" ml={1} sx={{ color: trendColor }}>
            {trendIcon}
            <Typography variant="body2" sx={{ ml: 0.5, fontSize: '1rem' }}>
              {previousAverage}%
            </Typography>
          </Box>
        )}
      </Box>
      <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line', marginTop: '8px' }}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const SummaryCards = ({
  averageMark,
  previousAverageMark,
  totalLearners,
  totalSubmitted,
  totalPassed,
  totalFailedWithNonSubmit,
  passedPercentage,
  failedPercentage,
}) => {
  // Determine the trend (increase, decrease, or no change)
  let trendIcon = null;
  let trendColor = "textSecondary";

  if (previousAverageMark !== undefined) {
    if (averageMark > previousAverageMark) {
      trendIcon = <ArrowUpwardIcon />;
      trendColor = "green";
    } else if (averageMark < previousAverageMark) {
      trendIcon = <ArrowDownwardIcon />;
      trendColor = "red";
    } else {
      trendIcon = <HorizontalRuleIcon />;
      trendColor = "#FFA500"; // Bright orange for no change
    }
  }

  return (
    <Box display="flex" justifyContent="space-between" height="100%">
      <Box flex={1} margin={1}>
        <SummaryCard
          title="Average Mark"
          value={`${averageMark}%`}
          description={`Total Learners: ${totalLearners}\nTotal Submitted: ${totalSubmitted}`}
          previousAverage={previousAverageMark}
          trendIcon={trendIcon}
          trendColor={trendColor}
        />
      </Box>
      <Box flex={1} margin={1}>
        <SummaryCard
          title="Passed Learners"
          value={`${passedPercentage}%`}
          description={`Learners Passed: ${totalPassed} / ${totalLearners}`}
        />
      </Box>
      <Box flex={1} margin={1}>
        <SummaryCard
          title="Failed Learners"
          value={`${failedPercentage}%`}
          description={`Learners Failed (incl. non-submitted): ${totalFailedWithNonSubmit} / ${totalLearners}`}
        />
      </Box>
    </Box>
  );
};

export default SummaryCards;
