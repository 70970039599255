import React, { useState, useEffect } from 'react';
import './scenarios.css'; // Import the CSS file

const Scenarios = () => {
  const scenarios = [
    {
      content: "Writing feedback takes time, and I still can't track my students' progress by topic."
    },
    {
      content: "I spent 11 hours of my family time marking English paper 1, and I still have paper 2 and paper 3."
    },
    {
      content: "I spend hours creating questions in Microsoft Word, struggling to align the text."
    }
  ];

  const [currentScenario, setCurrentScenario] = useState(0);

  // Automatic slider logic
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentScenario((prevScenario) => (prevScenario + 1) % scenarios.length);
    }, 5000); // Change every 5 seconds
    return () => clearInterval(interval);
  }, [scenarios.length]);

  return (
    <div className="scenarios-container">
      {/* SVG Wave at the top (flipped along Y-axis) */}
      <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 50" fill="#22cfbc"
             className="svg-wave">
          <rect fill="#FFFBEF" width="100%" height="100%" />
          <path d="M1000 50C500 50 500 2 0 2V0h1000v50Z"></path>
        </svg>

        <div className="scenario-headline">
          If any of this sounds familiar, you need to use Educase
        </div>
      </div>
      {/* Replace Cards with Scenario Content */}
      <div className="scenario-container">
        <div className="scenario-content">
          {scenarios[currentScenario].content}
        </div>
      </div>
    </div>
  );
};

export default Scenarios;
