import React, { useState } from 'react';
import './userRolesSlider.css';
import teachersImg from '../../../assets/teacher1.jpg';
import schoolsImg from '../../../assets/learner1.jpg';
import districtsImg from '../../../assets/teacher2.jpeg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

const UserRolesTabs = () => {
  const [activeTab, setActiveTab] = useState('Teachers');

  const userRoles = {
    Teachers: {
      title: "Teachers",
      description: "Create question papers, upload scripts for marking, and get metrics for the class/learner. Teachers can also use pre-made assessments.",
      imgSrc: teachersImg,
      buttonLabel: "Get Started",
      bgColor: "#ffd3b6" // Purple background for Teachers
    },
    Schools: {
      title: "Schools",
      description: "Schools can do everything teachers can, with the ability to manage school-wide assessments and access all learner data.",
      imgSrc: schoolsImg,
      buttonLabel: "Get Started",
      bgColor: "#b8d9bb" // Blue background for Schools
    },
    Districts: {
      title: "Districts",
      description: "Districts can manage multiple schools and access comprehensive data for all schools in the district.",
      imgSrc: districtsImg,
      buttonLabel: "Get Started",
      bgColor: "#ffaaa5" // Orange background for Districts
    }
  };


  const renderContent = (role) => {
    const { title, description, imgSrc, buttonLabel, bgColor } = userRoles[role];
    return (
        <div className="user-role-card" style={{ backgroundColor: bgColor }}>
          <div className="image-and-content">
            <div className="image-container">
              <img src={imgSrc} alt={title} className="user-role-img" />
            </div>
            <div className="user-role-info">
              <h2>{title}</h2>
              <p>{description}</p>
              <Link to="/signup" className="get-started-button">
                {buttonLabel}
              </Link>
            </div>
          </div>
        </div>
    );
  };

  return (
      <div className="user-roles-tabs-container">
        {/* Navigation */}
        <div className="tabs-border">
          {Object.keys(userRoles).map((role) => (
              <button
                  key={role}
                  className={`tab-button ${activeTab === role ? 'active' : ''}`}
                  onClick={() => setActiveTab(role)}
              >
                {role}
              </button>
          ))}
        </div>

        {/* Carousel Content based on the active tab */}
        <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            infiniteLoop={true}
            autoPlay={false}
            interval={5000}
            dynamicHeight={false}
            selectedItem={Object.keys(userRoles).indexOf(activeTab)}
            onChange={(index) => setActiveTab(Object.keys(userRoles)[index])}
        >
          {Object.keys(userRoles).map((role, index) => (
              <div key={index}>
                {renderContent(role)}
              </div>
          ))}
        </Carousel>
      </div>
  );
}
export default UserRolesTabs;
