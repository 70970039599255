import React, {useState} from "react";
import Box from "@mui/material/Box";
import AssessmentDetailsStep from "./assessmentDetailStep";
import {useNavigate} from "react-router-dom";
import AssessmentAPIService from "../../services/api/AssessmentAPIService";

export default function CreateAssessmentWizard({questions = []}) { // Default to empty array if questions is not provided
    const [activeStep, setActiveStep] = useState(0);
    const [stepOneErrors] = useState({});
    const [assessmentDetails, setAssessmentDetails] = useState({
        name: "",
        subjectName: "",
        subjectId: "",
        examId: "",
        date: "",
        grade: "",
        type: "",
        instructions: "",
    });
    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const {token} = parsedUserInfo;

    const handleStartAddingQuestions = async () => {
        try {
            const {name, subjectId, grade, type, date} = assessmentDetails;

            // Fetch the instructions
            const instructions = await AssessmentAPIService.fetchExamPaperInstructions(subjectId, grade, token);

            if (instructions) {
                // Define the exam data to send in the createExam call
                const examData = {
                    name,
                    subject: subjectId,
                    grade,
                    type,
                    assessment_date: date,
                    status: "draft"
                };

                // Call createExam endpoint to create an exam instance
                const createdExam = await AssessmentAPIService.createExam(examData, token);

                localStorage.removeItem("assessmentDetails");

                localStorage.setItem("assessmentDetails", JSON.stringify(assessmentDetails));

                if (createdExam) {
                    // Navigate to the next route, passing assessment details and questions
                    navigate("/create-assessment", {
                        state: {
                            assessmentDetails: {
                                ...assessmentDetails,
                                examId: createdExam.exam_id,
                                questions: questions,
                                instructions: instructions.instructions,

                            },
                            // Pass the questions array to the next route
                        },
                    });
                } else {
                    console.error("Exam creation failed");
                    // Handle creation failure, e.g., display error message to the user
                }
            }
        } catch (error) {
            console.error("Error creating exam instance:", error);
            // Handle error if needed
        }
    };

    return (
        <Box sx={{width: "100%"}}>
            {activeStep === 0 && (
                <AssessmentDetailsStep
                    assessmentDetails={assessmentDetails}
                    setAssessmentDetails={setAssessmentDetails}
                    handleStartAddingQuestions={handleStartAddingQuestions}
                />
            )}
        </Box>
    );
}
