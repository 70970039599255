import React, {useEffect, useState} from "react";
import AssessmentPaper from "../../components/CreateAssessment/assessmentPaper/assessmentPaper";
import {Toolbar} from "@mui/material";
import {useLocation} from "react-router-dom";

const CreateAssessmentPaperStep = () => {
    const location = useLocation();
    const [assessmentDetails, setAssessmentDetails] = useState(() => {
        // Try to get data from localStorage on component mount
        const storedDetails = localStorage.getItem("assessmentDetails");
        return storedDetails ? JSON.parse(storedDetails) : null;
    });

    useEffect(() => {
        if (location.state && location.state.assessmentDetails) {
            setAssessmentDetails(location.state.assessmentDetails);
        }
    }, [location]);

    const handleMouseEnterAssessmentPaper = () => {
        document.body.style.overflow = "hidden";
    };

    const handleMouseLeaveAssessmentPaper = () => {
        document.body.style.overflow = "auto";
    };

    const containerStyle = {
        backgroundColor: "whitesmoke",
        width: "100%",
        marginInline: "auto",
    };

    const {
        examId,
        name,
        subjectName,
        subjectId,
        grade,
        type,
        date,
        questions = [],
        instructions,
    } = assessmentDetails || {};

    return (
        <div style={{position: "sticky", top: 0, zIndex: 1000, backgroundColor: "#fff"}}>
            <div style={{display: "none"}}>
                <Toolbar style={{justifyContent: "flex-end", display: "none"}}/>
            </div>

            <div className="dragndrop">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowY: "hidden",
                    }}
                >
                    <div style={containerStyle}>
                        <AssessmentPaper
                            examId={examId}
                            name={name}
                            subjectName={subjectName}
                            subjectId={subjectId}
                            grade={grade}
                            type={type}
                            date={date}
                            initialQuestions={questions}
                            onEnter={handleMouseEnterAssessmentPaper}
                            onLeave={handleMouseLeaveAssessmentPaper}
                            instructions={instructions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateAssessmentPaperStep;
