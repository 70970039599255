import React, {useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadIcon from '@mui/icons-material/Upload';
import ImageIcon from '@mui/icons-material/Image';
import ImageUploadComponent from "../ImageUploaderComponent/ImageUploader";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const UploadScriptsLearnerTable = ({
                                       learners,
                                       uploadedUrls,
                                       setUploadedUrls,
                                       courseName,
                                       handleAddLearnerToCourse,
                                       setSelectedLearnerId,
                                       setOnlineTestId,
                                       selectedLearnerId
                                   }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [newLearner, setNewLearner] = useState({firstName: '', lastName: ''});
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [selectedLearner, setSelectedLearner] = useState({first_name: "", last_name: ""});
    const [viewImagesModalOpen, setViewImagesModalOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleOpenDialog = () => {
        setNewLearner({firstName: '', lastName: ''});
        setOpenDialog(true);
    };

    const handleOpenUploadModal = (learnerId, firstName, lastName, onlineTestId) => {
        setOnlineTestId(onlineTestId)
        setSelectedLearnerId(learnerId);
        setSelectedLearner({first_name: firstName, last_name: lastName});
        setImageModalOpen(true);
    };

    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    const createInitials = (firstName, lastName) => {
        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    };

    const isScriptUploaded = (learnerId) => uploadedUrls[learnerId] && uploadedUrls[learnerId].length > 0;

    const handleImageUpload = (learnerId, url) => {
            setSelectedLearnerId(learnerId)
    setUploadedUrls((prevUrls) => {
        return {
            ...prevUrls,
            [learnerId]: prevUrls[learnerId] ? [...prevUrls[learnerId], url] : [url],
        };
    });
};


    const handleOpenImageModal = (learnerId, firstName, lastName, onlineTestId) => {
        const images = uploadedUrls[learnerId] || [];

        if (images.length > 0) {
            setSelectedImages(images);
            setSelectedLearnerId(learnerId);
        }
        setOnlineTestId(onlineTestId)
        setSelectedLearner({first_name: firstName, last_name: lastName});
        setViewImagesModalOpen(true);
    };

    const handleImageDelete = (learnerId, urlToDelete) => {
        setUploadedUrls((prevUrls) => {
            if (prevUrls[learnerId]) {
                const updatedUrls = prevUrls[learnerId].filter((url) => url !== urlToDelete);
                if (updatedUrls.length === 0) {
                    const {[learnerId]: _, ...rest} = prevUrls;
                    return rest;
                }
                return {...prevUrls, [learnerId]: updatedUrls};
            }
            return prevUrls;
        });
    };

const handleCloseUploadModal = () => {
    setImageModalOpen(false);
    setTimeout(() => {
        setSelectedLearnerId(null);
    }, 500); // Adjust the delay as needed
};


    const handleCloseViewImagesModal = () => {
        if (selectedLearnerId) {
            setSelectedLearnerId(selectedLearnerId);
        }

        setViewImagesModalOpen(false);
        setSelectedLearnerId(null);
    };

    const filteredLearners = learners.filter((learner) =>
        `${learner.first_name} ${learner.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screen size


    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: "10px", margin: {xs: "0px", sm: "10px"}}}>
            {/* Top Section for both Mobile and Desktop */}
            <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                marginBottom: "15px",
                gap: "15px"
            }}>
                {/* Left Section: Submitted count and Add Learner button */}
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px"}}>
                    <Button
                        variant="contained"
                        onClick={handleOpenDialog}
                        size="small"
                        sx={{
                            backgroundColor: "#5f9ea0",
                            color: "white",
                            fontSize: {xs: "0.55rem", sm: "0.875rem", md: "1rem"},
                            padding: {xs: "4px 8px", sm: "6px 12px"},
                            minWidth: {xs: "auto", sm: "100px"}
                        }}
                    >
                        Add Learner
                    </Button>
                    <Typography
                        variant="body2">Submitted: {Object.keys(uploadedUrls).length} / {learners.length}</Typography>
                </Box>

                {/* Right Section: Search bar */}
                <TextField
                    id="search"
                    placeholder="Search Learners..."
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    variant="outlined"
                    InputProps={{
                        style: {width: "100%", maxWidth: "200px", borderRadius: 25},
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            "& fieldset": {
                                borderColor: "#d0d4d9",
                            },
                            "&:hover fieldset": {
                                borderColor: "#b3b3b3",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#5f9ea0",
                            },
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />

            </Box>


            {/* Card containing all Learners */}
            <Card sx={{
                padding: "20px",
                backgroundColor: "#f4f4f5",
                border: "1px solid #e0e0e0",
                borderRadius: "5px",
                boxShadow: "none",
                position: "relative"
            }}>
                {/* Menu Icon positioned inside grey card */}
                {isMobile && (
                    <IconButton
                        sx={{position: "absolute", top: 8, right: 8}}
                        onClick={handleMenuOpen}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                )}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                >
                    <MenuItem onClick={handleOpenDialog}>
                        <ListItemIcon><AddIcon/></ListItemIcon>
                        Add Learner
                    </MenuItem>
                </Menu>

                <Grid container spacing={3}>
                    {filteredLearners.map((learner) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={learner.id}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#ffffff",
                                    height: "150px",
                                    justifyContent: "space-between",
                                    border: "1px solid #b6d7d7",
                                    position: "relative"
                                }}
                            >
                                {/* Avatar and Name */}
                                <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: "#f3f7f8",
                                            color: "black",
                                            width: 66,
                                            height: 66
                                        }}
                                        onClick={() =>
                                            isScriptUploaded(learner.id)
                                                ? handleOpenImageModal(learner.id, learner.first_name, learner.last_name, learner.online_test_id)
                                                : handleOpenUploadModal(learner.id, learner.first_name, learner.last_name, learner.online_test_id)
                                        }
                                    >
                                        {createInitials(learner.first_name, learner.last_name)}
                                    </Avatar>
                                    {isScriptUploaded(learner.id) && (
                                        <CheckCircleIcon sx={{
                                            position: "absolute",
                                            top: 5,
                                            right: 5,
                                            color: "#5f9ea0",
                                            backgroundColor: "white",
                                            borderRadius: "50%"
                                        }}/>
                                    )}
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>
                                        {`${learner.first_name} ${learner.last_name}`}
                                    </Typography>
                                </Box>

                                {/* Upload Icon below the name */}
                                <IconButton
                                    color="primary"
                                    onClick={() =>
                                        isScriptUploaded(learner.id)
                                            ? handleOpenImageModal(learner.id, learner.first_name, learner.last_name, learner.online_test_id)
                                            : handleOpenUploadModal(learner.id, learner.first_name, learner.last_name, learner.online_test_id)
                                    }
                                    sx={{marginTop: "10px", alignSelf: "flex-start"}}
                                >
                                    {isScriptUploaded(learner.id) ? <ImageIcon sx={{color: "#5f9ea0"}}/> :
                                        <UploadIcon sx={{color: "#5f9ea0"}}/>}
                                </IconButton>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Card>

            {/* Add Learner Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle sx={{backgroundColor: "#f5f8fa", color: "black"}}>Add Learner to {courseName}</DialogTitle>
                <DialogContent sx={{padding: "20px"}}>
                    <Typography variant="body1" sx={{marginBottom: "15px", color: "#5f9ea0"}}>Please enter the learner's
                        first and last name below to add them to the course. This information will help you manage
                        learner profiles.</Typography>
                    <TextField fullWidth label="First Name" name="firstName" value={newLearner.firstName}
                               onChange={(e) => setNewLearner({...newLearner, firstName: e.target.value})} sx={{
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {borderRadius: "10px", borderColor: "#5f9ea0"}
                    }}/>
                    <TextField fullWidth label="Last Name" name="lastName" value={newLearner.lastName}
                               onChange={(e) => setNewLearner({...newLearner, lastName: e.target.value})}
                               sx={{"& .MuiOutlinedInput-root": {borderRadius: "10px", borderColor: "#5f9ea0"}}}/>
                </DialogContent>
                <DialogActions sx={{padding: "15px 20px"}}>
                    <Button onClick={() => setOpenDialog(false)} sx={{color: "#5f9ea0"}}>Cancel</Button>
                    <Button onClick={() => {
                        handleAddLearnerToCourse(newLearner);
                        setOpenDialog(false);
                    }} variant="contained" sx={{backgroundColor: "#5f9ea0", color: "white"}}>Add Learner</Button>
                </DialogActions>
            </Dialog>

            {/* Image Upload Modal */}
            <Dialog open={imageModalOpen} onClose={handleCloseUploadModal} maxWidth="md">
                <DialogContent>
                    {selectedLearnerId && (
                        <ImageUploadComponent
                            subject={courseName}
                            grade={''}
                            assessmentId={''}
                            learnerId={selectedLearnerId}
                            firstName={selectedLearner.first_name}
                            lastName={selectedLearner.last_name}
                            onImageUpload={(url) => handleImageUpload(selectedLearnerId, url)}
                            onImageDelete={(url) => handleImageDelete(selectedLearnerId, url)}
                            setSelectedImages={setSelectedImages}
                            existingImages={[]}
                            handleCloseUploadModal={handleCloseUploadModal}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Image View Modal */}
            {selectedImages.length > 0 && (
                <Dialog open={viewImagesModalOpen} onClose={handleCloseViewImagesModal} maxWidth="100%">
                    <DialogContent>
                        <ImageUploadComponent
                            subject={courseName}
                            grade={''}
                            assessmentId={''}
                            learnerId={selectedLearnerId}
                            firstName={selectedLearner.first_name}
                            lastName={selectedLearner.last_name}
                            onImageUpload={(url) => handleImageUpload(selectedLearnerId, url)}
                            onImageDelete={(url) => handleImageDelete(selectedLearnerId, url)}
                            setSelectedImages={setSelectedImages}
                            existingImages={selectedImages}
                            handleCloseViewImagesModal={handleCloseViewImagesModal}
                            setUploadedUrls={setUploadedUrls}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default UploadScriptsLearnerTable;
