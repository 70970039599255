import React, { useState } from "react";
import { Box, Button, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { AuthenticationAPIService } from "../../../../services/api/AuthenticationAPIService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../../../assets/educase-logo-light.png";
import "./TestSignInPage.css";

const TestSignInPage = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await AuthenticationAPIService.login({
        ...credentials,
        email: credentials.email.trim(),
        password: credentials.password.trim(),
      });

      if (!response.ok) {
        // Handle different status codes or generic errors
        let errorMessage = 'An error occurred during login.';
        if (response.status === 404) {
          errorMessage = 'User not found';
        } else if (response.status === 401) {
          errorMessage = 'Incorrect email or password';
        } else if (response.status === 500) {
          errorMessage = 'Server error, please try again later';
        }
        throw new Error(errorMessage);
      }

      const res = await response.json();
      const assessmentId = localStorage.getItem("assessmentId");
      const onlineTestId = localStorage.getItem("onlineTestId");

      localStorage.setItem(
        "user",
        JSON.stringify({
          token: res.token,
          name: `${res.first_name} ${res.last_name}`,
          employerId: res.employer_id,
          userId: res.user_id,
          user_role: res.user_role,
          grade: res.grade,
          subjects: res.subjects,
          learnerId: res.learner_id
        }),
      );

      setIsLoading(false);

      if (res.user_role === "student") {
        Toast.fire({
          icon: 'success',
          title: 'Successfully signed in',
        });

        if (assessmentId) {
          navigate(`/test-instructions?assessment_id=${assessmentId}`);
        } else if (onlineTestId) {
          navigate(`/results?online_test_id=${onlineTestId}`);
        }
      } else if (res.user_role === "admin" && onlineTestId) {
        Toast.fire({
          icon: 'success',
          title: 'Successfully signed in',
        });
        navigate(`/results?online_test_id=${onlineTestId}`);
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Access denied. Only students are allowed to log in.',
        });
      }
    } catch (error) {
      setIsLoading(false);
      Toast.fire({
        icon: 'error',
        title: error.message,
      });
      console.error("Error during sign in:", error);
    }
  };

  return (
    <div className="test-sign-in-page-container">
      <img src={logo} alt="Educase Logo" className="test-sign-in-page-logo" />
      <div className="test-sign-in-page-card-wrapper">
        <Paper className="test-sign-in-page-card">
          <Typography variant="h4" className="test-sign-in-page-title">
            Student Login
          </Typography>
          <Typography variant="subtitle1" className="test-sign-in-page-subtitle">
            Please log in to continue
          </Typography>
          <form onSubmit={handleSignIn}>
            <TextField
              label="email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={credentials.email}
              onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? "Signing In..." : "Sign In"}
              </Button>
            </Box>
          </form>
          <Box mt={2}>
            <Typography variant="body2">
              Don't have an account? <Link component={RouterLink} to="/test-sign-up">Sign Up</Link>
            </Typography>
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default TestSignInPage;