import React from 'react';
import './pricingCards.css';
import { Link } from 'react-router-dom';

const PricingCards = () => {
  return (
    <div id="pricing" className="pricing-cards-container">
      {/* Top Signup Section */}
      <div className="signup-header">
        <h1>Let us mark all your scripts, so you're stress-free.</h1>
        <p>Free up your time for teaching, and let Educase handle the marking for you.</p>
        <form className="signup-form">
          <input
            type="email"
            placeholder="name@school.com"
            required
            className="signup-email-input"
          />
          <button type="submit" className="signup-button">Get started</button>
        </form>
        <p className="terms">By signing up, I agree to the <a href="#">Privacy Policy</a> and <Link to="/terms-of-service"> Terms of Service</Link>.</p>
      </div>

      {/* Pricing Plans Section */}
      <h2 className="pricing-cards-header">Compare Our Plans</h2>
      <div className="pricing-cards-wrapper">

        {/* Free Plan */}
        <div className="pricing-card free-plan">
          <h3>Basic R100/month</h3>
          <p>Basic tools for individual teachers.</p>
          <ul>
            <li>Access and download papers (PDF)</li>
            <li>Manual quick quizzes (non-AI)</li>
            <li>Track tests downloaded</li>
            <li>Basic support (48h response)</li>
          </ul>
        </div>

        {/* Standard Plan */}
        <div className="pricing-card standard-plan">
          <h3>STANDARD R30K/Year</h3>
          <p>Enhanced tools for schools to reduce marking time.</p>
          <ul>
            <li>Customizable question papers (PDF)</li>
            <li>Manual quiz creation</li>
            <li>HOD approval for papers</li>
            <li>Track tests downloaded</li>
            <li>Standard support (24h response)</li>
          </ul>
        </div>

        {/* Explorer Plan - Recommended */}
        <div className="pricing-card explorer-plan recommended">
          <div className="recommended-tag">Recommended</div>
          <h3>EXPLORER</h3> {/* Removed price */}
          <p>AI-powered tools for efficiency and scaling your marking tasks.</p>
          <ul>
            <li>AI-generated questions</li>
            <li>Manual grading (3 days)</li>
            <li>AI-powered quizzes (10/month)</li>
            <li>Personalized feedback (5 days)</li>
            <li>Quarterly marking review meetings</li>
            <li>Priority support (12h response)</li>
          </ul>
          <a href="/contact" className="contact-us-link">Contact us for pricing</a> {/* Contact link */}
        </div>

        {/* Premium Plan */}
        <div className="pricing-card premium-plan">
          <h3>PREMIUM</h3> {/* Removed price */}
          <p>Complete AI-driven marking solution to save you time.</p>
          <ul>
            <li>AI-generated questions</li>
            <li>Auto-marking system</li>
            <li>Monthly review meetings</li>
            <li>Unlimited quizzes</li>
            <li>24/7 premium support (4h response)</li>
            <li>AI credits (extra services)</li>
          </ul>
          <a href="/contact" className="contact-us-link">Contact us for pricing</a> {/* Contact link */}
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
