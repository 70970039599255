import React, { useEffect, useState } from "react";
import DisplayContent from "../../../../../components/displayContent/displayContent";
import "./Rubric.css"; // Ensure this file contains the correct styles

const Rubric = ({ question, index, onMarkChange, allocatedMarks }) => {
    // Helper function to get the composite key for allocated marks
    const getCompositeKey = (type, id) => `${type}_${id}`;

    const [subQuestionMarks, setSubQuestionMarks] = useState(
        question.sub_questions?.map((sub) => allocatedMarks[getCompositeKey("subquestion", sub.id)]?.allocated_marks || 0) || [] // Default to 0
    );
    const [nestedSubQuestionMarks, setNestedSubQuestionMarks] = useState(
        question.sub_questions?.map((sub) =>
            sub.nested_sub_questions?.map((nested) => allocatedMarks[getCompositeKey("nested_subquestion", nested.id)]?.allocated_marks || 0) || [] // Default to 0
        ) || []
    );

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState("");

    // Retrieve user information from localStorage
    useEffect(() => {
        const userInfo = localStorage.getItem("user");
        if (userInfo) {
            const parsedUserInfo = JSON.parse(userInfo);
            const { token } = parsedUserInfo;
        } else {
            console.error("No user information found in localStorage.");
        }
    }, []); // This will run only once when the component mounts

    // Function to show tooltip with a message
    const showTooltip = (message) => {
        setTooltipMessage(message);
        setTooltipVisible(true);

        setTimeout(() => {
            setTooltipVisible(false);
        }, 3000); // Tooltip disappears after 3 seconds
    };

    // Function to check if all nested sub-questions for a given sub-question are marked
    const areAllNestedSubQuestionsMarked = (subQuestion) => {
        return subQuestion.nested_sub_questions?.every(
            (nestedSubQuestion) => allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks > 0
        );
    };

// Function to handle mark selection via chips for sub-questions
const handleChipMarkSelect = (mark, subIndex) => {
    const newMarks = [...subQuestionMarks];
    newMarks[subIndex] = mark;
    setSubQuestionMarks(newMarks);

    // Update the allocatedMarks with the correct composite key
    const subQuestionId = question.sub_questions[subIndex].id;
    onMarkChange(subQuestionId, "subquestion", mark); // Pass the selected mark to parent
};

// Function to handle mark selection via chips for nested sub-questions
const handleNestedChipMarkSelect = (mark, subIndex, nestedIndex) => {
    const newMarks = [...nestedSubQuestionMarks];
    newMarks[subIndex][nestedIndex] = mark;
    setNestedSubQuestionMarks(newMarks);

    // Update the allocatedMarks with the correct composite key for the nested sub-question
    const nestedSubQuestionId = question.sub_questions[subIndex].nested_sub_questions[nestedIndex].id;
    onMarkChange(nestedSubQuestionId, "nested_subquestion", mark); // Pass the selected mark to parent
};

    const handleNestedSubQuestionMarksChange = (event, subIndex, nestedIndex) => {
        const inputMarks = parseFloat(event.target.value || 0); // Default to 0 if input is empty
        const maxNestedMarks = parseFloat(question.sub_questions[subIndex].nested_sub_questions[nestedIndex].marks || 0);

        if (inputMarks <= maxNestedMarks) {
            const newMarks = [...nestedSubQuestionMarks];
            newMarks[subIndex][nestedIndex] = inputMarks;
            setNestedSubQuestionMarks(newMarks);

            // Pass the updated marks to the parent component
            onMarkChange(
                question.sub_questions[subIndex].nested_sub_questions[nestedIndex].id,
                "nested_subquestion",
                inputMarks
            );
        } else {
            showTooltip(`Nested sub-question ${index + 1}.${subIndex + 1}.${nestedIndex + 1} cannot exceed ${maxNestedMarks} marks.`);
        }
    };

    const handleSubQuestionMarksChange = (event, subIndex) => {
        const inputMarks = parseFloat(event.target.value || 0); // Default to 0 if input is empty
        const maxSubMarks = parseFloat(question.sub_questions[subIndex].marks || 0);

        if (inputMarks <= maxSubMarks) {
            const newMarks = [...subQuestionMarks];
            newMarks[subIndex] = inputMarks;
            setSubQuestionMarks(newMarks);

            // Pass the updated marks to the parent component
            onMarkChange(question.sub_questions[subIndex].id, "subquestion", inputMarks);
        } else {
            showTooltip(`Sub-question ${index + 1}.${subIndex + 1} cannot exceed ${maxSubMarks} marks.`);
        }
    };

    // Calculate total allocated marks for the question
    const totalAllocatedMarks = question.sub_questions?.reduce((total, subQuestion) => {
        if (subQuestion.nested_sub_questions?.length) {
            return total + subQuestion.nested_sub_questions.reduce((nestedTotal, nestedSubQuestion) => {
                return nestedTotal + (allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks || 0);
            }, 0);
        }
        return total + (allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks || 0);
    }, 0);

    return (
        <div className="rubric-container" style={{ position: "relative" }}>
            <h3 className="rubric-question-headline">
                QUESTION {index + 1} ({totalAllocatedMarks} / {question.marks})
            </h3>
            {question.instructions && (
                <div className="rubric-question-instructions">
                    <DisplayContent content={question.instructions} />
                </div>
            )}

            {question.sub_questions?.map((subQuestion, subQuestionIndex) => {
    const allNestedMarked = areAllNestedSubQuestionsMarked(subQuestion);
    const isSubQuestionMarked = allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks > 0 || allNestedMarked;

    return (
        <div
            key={subQuestionIndex}
            className={`rubric-sub-question-container ${isSubQuestionMarked ? 'marked' : ''}`} // Add class if the sub-question is marked or all nested are marked
        >
            <label>{index + 1}.{subQuestionIndex + 1}</label>
            <DisplayContent content={subQuestion.text} />

            {/* Check if the sub-question has nested sub-questions */}
            {subQuestion.nested_sub_questions?.length > 0 ? (
                subQuestion.nested_sub_questions.map((nestedSubQuestion, nestedSubQuestionIndex) => (
                    <div
                        key={nestedSubQuestionIndex}
                        className={`rubric-nested-question-container ${
                            allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks > 0 ? 'marked' : ''
                        }`} // Add class if the nested sub-question is marked
                    >
                        <label>{index + 1}.{subQuestionIndex + 1}.{nestedSubQuestionIndex + 1}</label>
                        <DisplayContent content={nestedSubQuestion.text} />

                        {/* Display memo under the nested sub-question with "Answer" */}
                        {nestedSubQuestion.nested_sub_question_memo && (
                            <div className="rubric-nested-sub-question-memo">
                                <strong>Answer:</strong>
                                <DisplayContent content={nestedSubQuestion.nested_sub_question_memo.text} />
                            </div>
                        )}

                        {/* Show chips for nested sub-questions if marks <= 10 */}
                        {nestedSubQuestion.marks <= 10 ? (
                            <div className="mark-chips">
                                {[...Array(nestedSubQuestion.marks + 1).keys()].map((mark) => ( // Add 0 at the start of the chips
                                    <div
                                        key={mark}
                                        className={`chip ${
                                            allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks === mark ? 'selected' : ''
                                        }`}
                                        onClick={() => handleNestedChipMarkSelect(mark, subQuestionIndex, nestedSubQuestionIndex)}
                                    >
                                        {mark}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>
                                {/* Show input field for marks if the total marks > 10 */}
                                <input
                                    type="number"
                                    value={allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks || 0} // Allocated marks or 0 if none
                                    onChange={(e) => handleNestedSubQuestionMarksChange(e, subQuestionIndex, nestedSubQuestionIndex)}
                                    className="rubric-marks-input"
                                    placeholder={`Max: ${nestedSubQuestion.marks}`} // Placeholder for maximum marks
                                />
                            </>
                        )}
                    </div>
                ))
            ) : (
                <>
                    {/* Display memo under the sub-question with "Answer" if there are no nested sub-questions */}
                    {subQuestion.sub_question_memo && (
                        <div className="rubric-sub-question-memo">
                            <strong>Answer:</strong>
                            <DisplayContent content={subQuestion.sub_question_memo.text} />
                        </div>
                    )}

                    {/* Show chips for sub-questions */}
                    {subQuestion.marks <= 5 ? (
                        <div className="mark-chips">
                            {[...Array(subQuestion.marks + 1).keys()].map((mark) => ( // Add 0 at the start of the chips
                                <div
                                    key={mark}
                                    className={`chip ${allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks === mark ? 'selected' : ''}`}
                                    onClick={() => handleChipMarkSelect(mark, subQuestionIndex)}
                                >
                                    {mark}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {/* Show input field for marks if the total marks > 5 */}
                            <input
                                type="number"
                                value={allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks || 0} // Allocated marks or 0 if none
                                onChange={(e) => handleSubQuestionMarksChange(e, subQuestionIndex)}
                                className="rubric-marks-input"
                                placeholder={`Max: ${subQuestion.marks}`} // Placeholder for maximum marks
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
})}

            {/* Tooltip */}
            {tooltipVisible && (
                <div className="tooltip" style={tooltipStyles}>
                    {tooltipMessage}
                </div>
            )}
        </div>
    );
};

// Inline tooltip styles for fade-in and fade-out effect
const tooltipStyles = {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#333",
    color: "#fff",
    padding: "8px",
    borderRadius: "4px",
    fontSize: "12px",
    opacity: 1,
    transition: "opacity 0.3s ease-in-out",
    zIndex: 999,
};

export default Rubric;
