import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import SubjectApiService from "../../services/api/subjectAPIService";

const AssessmentDetailsStep = ({ assessmentDetails, setAssessmentDetails, handleStartAddingQuestions, userRole }) => {
    const [subjects, setSubjects] = useState([]);
    const [filteredGrades, setFilteredGrades] = useState([]);
    const [filteredSubjects, setFilteredSubjects] = useState([]);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [errors, setErrors] = useState({}); // Track field errors

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Update assessmentDetails state directly
        setAssessmentDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        // Set error if field is empty
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !value, // true if value is empty
        }));

        if (name === "subject") {
            const selectedSubject = subjects.find((subject) => subject.name === value);
            if (selectedSubject) {
                const subjectGrades = selectedSubject.grades.map((grade) => grade.grade);
                setFilteredGrades(subjectGrades);
                setAssessmentDetails((prevDetails) => ({
                    ...prevDetails,
                    subjectName: selectedSubject.name,
                    subjectId: selectedSubject.id,
                }));
            } else {
                setFilteredGrades([]);
            }
        }
    };

    // Filtered exam types based on user role
    const [examTypeOptions] = useState([
        { value: "test", label: "Test" },
        { value: "exam", label: "Exam" },
        ...(userRole === "admin" ? [
            { value: "practice_test", label: "Practice Test" },
            { value: "baseline_test", label: "Baseline Test" },
        ] : [])
    ]);

    const fetchSubjects = async () => {
        const response = await SubjectApiService.fetchSubjects();
        if (response.ok) {
            const subjects = await response.json();
            setSubjects(subjects);
            const allGrades = [...new Set(subjects.flatMap(subject => subject.grades.map(grade => grade.grade)))];
            setFilteredGrades(allGrades);
            setFilteredSubjects(subjects);
        }
    };

    useEffect(() => {
        fetchSubjects();
    }, []);

    // Check if the form is complete
    useEffect(() => {
        const isComplete = assessmentDetails.name && assessmentDetails.subject && assessmentDetails.grade && assessmentDetails.type;
        setIsFormComplete(isComplete);
    }, [assessmentDetails]);

    // CSS for blinking effect
    const blinkStyle = {
        animation: 'blink 1s infinite',
    };

    return (
        <Card sx={{
            border: "1px solid #5f9ea0",
            borderRadius: 2,
            padding: 3,
            marginLeft: { xs: 2, sm: 3, md: 5 },
            marginRight: { xs: 2, sm: 3, md: 5 },
            marginTop: 2,
            width: { xs: 'auto', sm: 'auto', md: '500px' },
            minHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="body1" sx={{ mb: 2, color: '#777f86', fontSize: '1rem' }}>
                        Start by filling in the assessment details, and then you'll be able to select the perfect questions for your paper.
                    </Typography>

                    <Typography variant="h6" sx={{ mb: 2, mt: 0, color: '#777f86', textAlign: 'left', fontSize: '1rem', fontWeight: 'bold' }}>
                        What are the assessment details?
                    </Typography>

                    {/* Assessment Name */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                            Assessment Name<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            name="name"
                            value={assessmentDetails.name}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            placeholder="Type the assessment name"
                            sx={{
                                height: '35px',
                                '& .MuiInputBase-root': {
                                    height: '100%',
                                    fontSize: '0.9rem',
                                    padding: '8px'
                                },
                                ...(errors.name && blinkStyle)
                            }}
                        />
                        {errors.name && <Typography sx={{ color: 'red', fontSize: '0.8rem' }}>This field is required</Typography>}
                    </Box>

                    {/* Subject */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold' }}>Subject<span style={{ color: 'red' }}>*</span></Typography>
                        <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px', ...(errors.subject && blinkStyle) }}>
                            <Select
                                name="subject"
                                value={assessmentDetails.subject || ''}
                                onChange={handleChange}
                                displayEmpty
                                sx={{ height: '35px' }}
                                renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select a subject</span>}
                            >
                                <MenuItem value="" disabled>Select a subject, e.g., Mathematics</MenuItem>
                                {filteredSubjects.map((subject, index) => (
                                    <MenuItem key={index} value={subject.name}>{subject.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.subject && <Typography sx={{ color: 'red', fontSize: '0.8rem' }}>This field is required</Typography>}
                    </Box>

                    {/* Grade */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold' }}>Grade<span style={{ color: 'red' }}>*</span></Typography>
                        <FormControl fullWidth variant="outlined" margin="dense" disabled={!assessmentDetails.subject} sx={{ height: '35px', ...(errors.grade && blinkStyle) }}>
                            <Select
                                name="grade"
                                value={assessmentDetails.grade || ''}
                                onChange={handleChange}
                                displayEmpty
                                sx={{ height: '35px', color: !assessmentDetails.subject ? 'grey' : 'inherit' }}
                                renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select a grade</span>}
                            >
                                <MenuItem value="" disabled>Select a grade, e.g., Grade 8</MenuItem>
                                {filteredGrades.map((grade, index) => (
                                    <MenuItem key={index} value={grade}>{grade}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.grade && <Typography sx={{ color: 'red', fontSize: '0.8rem' }}>This field is required</Typography>}
                    </Box>

                    {/* Assessment Type */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold' }}>Assessment Type<span style={{ color: 'red' }}>*</span></Typography>
                        <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px', ...(errors.type && blinkStyle) }}>
                            <Select
                                name="type"
                                value={assessmentDetails.type || ''}
                                onChange={handleChange}
                                displayEmpty
                                sx={{ height: '35px' }}
                                renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select an assessment type</span>}
                            >
                                <MenuItem value="" disabled>Select an assessment type</MenuItem>
                                {examTypeOptions.map((type, index) => (
                                    <MenuItem key={index} value={type.value}>{type.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.type && <Typography sx={{ color: 'red', fontSize: '0.8rem' }}>This field is required</Typography>}
                    </Box>

                    {/* Assessment Date */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold' }}>Date of Assessment</Typography>
                        <TextField
                            type="date"
                            name="date"
                            value={assessmentDetails.date || ''}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Select the date of the assessment"
                            variant="outlined"
                            sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Tooltip title="Next you will choose topics" arrow>
                            <span>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: 'primary', color: 'white', width: '190px', fontSize: '12px', borderRadius: '15px' }}
                                    onClick={handleStartAddingQuestions}
                                    disabled={!isFormComplete}
                                >
                                    Start adding questions
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            <style>
                {`
                    @keyframes blink {
                        50% { border-color: red; }
                    }
                `}
            </style>
        </Card>
    );
};

export default AssessmentDetailsStep;
