import React from 'react';
import "./nestedSubQuestion.css";
import { useSelector } from "react-redux";
import "./nestedSubQuestion.css";
import DisplayContent from "../../displayContent/displayContent";


const NestedSubQuestion = ({
  nestedSubQuestion,
  questionIndex,
  subIndex,
  nestedIndex,
  showMemo,
  showTags
}) => {
  const hideComponents = useSelector(state => state.componentStates.hideComponents);
  const questionNumber = `${questionIndex}.${subIndex}.${nestedIndex + 1}`;
  return (
    <>
      <ol className="list-container">
        <li className="item-container">
          <div className="sub-question-content">
            <span className="nested-question-number">{questionNumber}</span>
            <DisplayContent content={nestedSubQuestion.text}></DisplayContent>
            <span className="nested-question-marks">
              ({nestedSubQuestion.marks || ""})
            </span>
            <br />
          </div>

          {nestedSubQuestion.nested_sub_question_memo && showMemo && (
            <div className="answer-container">
              <span className="answer-label">ANSWER</span>
              <br/>
              <DisplayContent className="memo-section"
                content={nestedSubQuestion?.nested_sub_question_memo?.text}></DisplayContent>
            </div>
          )}


          <br />
          {!hideComponents && showTags && (
            <div className="flex-start-detail">
              {(() => {
                const items = [];

                if (nestedSubQuestion.level) {
                  items.push(nestedSubQuestion.level);
                }

                if (nestedSubQuestion.topic && nestedSubQuestion.topic.name) {
                  items.push(nestedSubQuestion.topic.name);
                }

                if (nestedSubQuestion.nature) {
                  items.push(nestedSubQuestion.nature);
                }

                if (nestedSubQuestion.difficulty) {
                  items.push(nestedSubQuestion.difficulty);
                }

                if (nestedSubQuestion.source) {
                  items.push(nestedSubQuestion.source);
                }

                if (nestedSubQuestion.year) {
                  items.push(nestedSubQuestion.year);
                }

                return items.join(' • ');
              })()}
            </div>
          )}
        </li>
      </ol>
    </>
  );
};

export default NestedSubQuestion;
