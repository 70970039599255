import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register the necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LearnerPerformanceCard = ({ allLearners, onLearnerClick, isMobile, averages }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [learnerFilter, setLearnerFilter] = useState("all");
  const [openAveragesModal, setOpenAveragesModal] = useState(false);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (filter) => {
    setLearnerFilter(filter);
    handleMenuClose();
  };

  const handleOpenAveragesModal = () => setOpenAveragesModal(true);
  const handleCloseAveragesModal = () => setOpenAveragesModal(false);

  const filteredLearners = allLearners.filter((learner) => {
    if (learnerFilter === "passed") return learner.learner.mark >= 50;
    if (learnerFilter === "failed") return learner.learner.mark < 50;
    if (learnerFilter === "missed") return learner.learner.mark === null || learner.learner.mark === undefined;
    return true;
  });

  const filterLabels = {
    all: "All Learners",
    passed: "Passed Learners",
    failed: "Failed Learners",
    missed: "Missed Learners"
  };

const chartData = {
  labels: averages.length > 0 ? averages.map((avg) => avg.assessment_name) : ["No Data"],
  datasets: [
    {
      label: "Class Average (%)",
      data: averages.length > 0 ? averages.map((avg) => avg.average_mark) : [0],
      borderColor: "#3f51b5",
      backgroundColor: "rgba(63, 81, 181, 0.1)",
      fill: true,
      tension: 0.4
    }
  ]
};


const options = {
  responsive: true,
  plugins: {
    legend: { display: true },
    tooltip: { enabled: true },
  },
  scales: {
    x: {
      type: 'category',
      title: {
        display: true,
        text: "Assessment"
      }
    },
    y: {
      min: 0, // Start y-axis at 0
      max: 100, // End y-axis at 100
      ticks: {
        stepSize: 10, // Show y-axis labels at intervals of 10
        callback: function(value) {
          return `${value}%`; // Format y-axis labels as percentages
        },
      },
      title: {
        display: true,
        text: "Class Average (%)"
      }
    },
  },
};



  return (
    <Card sx={{ height: "85vh", display: "flex", flexDirection: "column", margin: 1 }}>
      <CardContent sx={{ height: "90vh", display: "flex", flexDirection: "column" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" gutterBottom sx={{ color: '#849fab', display: "flex", alignItems: "center" }}>
            {filterLabels[learnerFilter]}
            <IconButton size="small" onClick={handleDropdownClick}>
              <ExpandMoreIcon />
            </IconButton>
          </Typography>

          <Button size="small" onClick={handleOpenAveragesModal} variant="outlined" sx={{ textTransform: "none" }}>
            View Class Trends
          </Button>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => handleFilterChange("all")}>All Learners</MenuItem>
            <MenuItem onClick={() => handleFilterChange("passed")}>Passed Learners</MenuItem>
            <MenuItem onClick={() => handleFilterChange("failed")}>Failed Learners</MenuItem>
            <MenuItem onClick={() => handleFilterChange("missed")}>Missed Learners</MenuItem>
          </Menu>
        </Box>

        {/* Existing Table of Learners */}
        <Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Mark (%)</TableCell>
                  <TableCell align="center">Scripts</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLearners.map((learner, index) => (
                  <TableRow
                    key={index}
                    hover={!isMobile}
                    onClick={() => !isMobile && onLearnerClick(learner.learner, learnerFilter)}
                    style={{ cursor: isMobile ? "default" : "pointer" }}
                  >
                    <TableCell>{`${learner.learner.first_name} ${learner.learner.last_name}`}</TableCell>
                    <TableCell align="right">{learner.learner.mark !== null ? `${learner.learner.mark}%` : "N/A"}</TableCell>
                    <TableCell align="center">
                      {learner.learner.pdf ? (
                        <IconButton
                          size="small"
                          href={learner.learner.pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      ) : (
                        "No script"
                      )}
                    </TableCell>
                    {/*<TableCell align="center">*/}
                    {/*  {learner.ai_analysis ? (*/}
                    {/*    <Button*/}
                    {/*      variant="outlined"*/}
                    {/*      size="small"*/}
                    {/*      onClick={() => console.log(`AI Analysis for ${learner.learner.first_name} ${learner.learner.last_name}`)}*/}
                    {/*    >*/}
                    {/*      View Analysis*/}
                    {/*    </Button>*/}
                    {/*  ) : (*/}
                    {/*    "No Analysis"*/}
                    {/*  )}*/}
                    {/*</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Modal for Class Average Trends */}
        <Dialog open={openAveragesModal} onClose={handleCloseAveragesModal} fullWidth maxWidth="md" PaperProps={{ sx: { borderRadius: 3 } }}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f5f5f5', padding: 2 }}>
            <Typography variant="h6" sx={{ color: '#3f51b5' }}>
              Class Performance Trends
            </Typography>
            <IconButton onClick={handleCloseAveragesModal} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ padding: 3, backgroundColor: '#fafafa' }}>
            <Box sx={{ height: "300px", marginBottom: 3 }}>
              <Line data={chartData} options={options} />
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Assessment Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Assessment Date</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>Average Mark (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {averages.map((avg, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{avg.assessment_name}</TableCell>
                      <TableCell>{avg.assessment_date}</TableCell>
                      <TableCell align="right">{avg.average_mark}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions sx={{ padding: 2, justifyContent: 'center' }}>
            <Button onClick={handleCloseAveragesModal} variant="contained" sx={{ borderRadius: 2, textTransform: 'none', backgroundColor: '#3f51b5', color: 'white', "&:hover": { backgroundColor: '#32408f' } }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default LearnerPerformanceCard;
