import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Card, CardContent, Grid, Chip, Button, FormControlLabel, Switch, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import getConfig from "../../config";

const SelectTopics = ({ grade, subjectId, onOpenQuestionBank, toggleMemoVisibility }) => {
    const [loading, setLoading] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [topicsState, setTopicsState] = useState([]); // Local state for topics
    const [selectedTopics, setSelectedTopics] = useState([]); // Local state for selected topics
    const scrollRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, [grade, subjectId]);

    const fetchData = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;
        const config = getConfig();

        const response = await fetch(`${config.api.base_url}/topics/topics_by_question_bank/?grade=${grade}&subject=${subjectId}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
        });

        if (response.ok) {
            const res = await response.json();
            setTopicsState(res); // Store topics data in local state
            setLoading(false);

            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            console.error("Error fetching topics:", await response.json());
            setLoading(false);
        }
    };

    const handleTopicToggle = (questionBankId, topic) => {
        const topicId = topic.id;
        const isSelected = selectedTopics.some(
            (selectedItem) => selectedItem.questionBankId === questionBankId && selectedItem.topic.id === topicId
        );

        if (isSelected) {
            // Remove the topic if it's already selected
            setSelectedTopics((prev) => prev.filter(
                (selectedItem) => !(selectedItem.questionBankId === questionBankId && selectedItem.topic.id === topicId)
            ));
        } else {
            // Add the topic to the selected list
            setSelectedTopics((prev) => [...prev, { questionBankId, topic }]);
        }
    };

    const handleOpenQuestionBank = () => {
        const topicIds = selectedTopics.map((item) => item.topic.id); // Extract topic IDs
        onOpenQuestionBank(topicIds); // Pass selected topic IDs to the parent component
    };

    const handleToggleShowAnswer = () => {
        setShowAnswer((prev) => {
            const newShowAnswer = !prev;
            toggleMemoVisibility(newShowAnswer); // Call parent function to show/hide memo
            return newShowAnswer;
        });
    };

    return (
        <Card
            sx={{
                border: "1px solid #5f9ea0",
                borderRadius: 2,
                boxShadow: 'none',
                mt: 10,
                mx: "auto",
                width: "1000px",
                padding: "20px",
                position: "relative",
            }}
        >
            <CardContent ref={scrollRef}>
                {loading ? (
                    // Show circular loading icon if loading is true
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    <>
                        {/* Conditional Rendering of the Button */}
                        {selectedTopics.length > 0 && (
                            <Box position="absolute" top={16} right={16}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenQuestionBank}
                                    sx={{
                                        backgroundColor: "#00b8d4",
                                        "&:hover": { backgroundColor: "#00a0ba" },
                                        textTransform: "none",
                                        fontWeight: 600,
                                    }}
                                >
                                    Open Question Bank
                                </Button>
                            </Box>
                        )}

                        {/* Instructional Header */}
                        <Box mb={3}>
                            <Typography variant="h6" sx={{ color: "#5f9ea0", fontWeight: 600 }}>
                                Choose Topics
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                Select the topics you want to include, then click on the "Open Question Bank" button to choose questions based on your selection.
                            </Typography>
                        </Box>

                        {/* Topics as Chips */}
                        {topicsState.map((questionBank) => (
                            <Box key={questionBank.question_bank_id} sx={{ mb: 4 }}>
                                <Box display="flex" alignItems="center" mb={1} sx={{ mt: 2 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                            color: "#333"
                                        }}
                                    >
                                        {questionBank.question_bank_name}
                                    </Typography>
                                </Box>

                                <Grid container spacing={2}>
                                    {questionBank.topics.map((topic) => {
                                        const isSelected = selectedTopics.some(
                                            (selectedItem) =>
                                                selectedItem.questionBankId === questionBank.question_bank_id &&
                                                selectedItem.topic.id === topic.id
                                        );
                                        return (
                                            <Grid item xs="auto" key={topic.id}>
                                                <Chip
                                                    icon={<AddIcon />}
                                                    label={topic.name}
                                                    onClick={() => handleTopicToggle(questionBank.question_bank_id, topic)}
                                                    clickable
                                                    sx={{
                                                        backgroundColor: isSelected ? "#e0f7fa" : "#f0f4f8",
                                                        color: isSelected ? "#00b8d4" : "#5f9ea0",
                                                        fontWeight: isSelected ? 600 : 400,
                                                        boxShadow: isSelected ? "0px 4px 6px rgba(0, 184, 212, 0.2)" : "none",
                                                        "& .MuiChip-icon": {
                                                            color: isSelected ? "#00b8d4" : "#5f9ea0"
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        ))}

                        {/* Show/Hide Answer Toggle at the Bottom Left */}
                        <Box display="flex" justifyContent="flex-start" mt={4}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showAnswer}
                                        onChange={handleToggleShowAnswer}
                                        color="primary"
                                    />
                                }
                                label={showAnswer ? "Hide Answers" : "Show Answers"}
                                sx={{
                                    color: '#5f9ea0',
                                    fontWeight: 500,
                                    ml: 1
                                }}
                            />
                        </Box>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default SelectTopics;
