import React, { useState } from 'react';
import { Box, Button, TextField, Grid, Card, CardContent, Typography } from '@mui/material';
import CourseApiService from "../../../../services/api/courseAPIService";

const EnrollLearnerForm = ({ token, setUploadedUrls, courseName, fetchLearners, courseId }) => {
    const [newLearner, setNewLearner] = useState({ firstName: "", lastName: "" });

    const handleLearnerInputChange = (e) => {
        setNewLearner({ ...newLearner, [e.target.name]: e.target.value });
    };

    const handleLearnerSubmit = async () => {
        const learnerData = {
            first_name: newLearner.firstName,
            last_name: newLearner.lastName,
            course_id: courseId,
        };

         try {
            const response = await CourseApiService.enrollLearner(learnerData, token);
            if (response?.id) {
                // Update uploadedUrls with the new learner ID
                setUploadedUrls((prevUrls) => ({
                    ...prevUrls,
                    [response.id]: []
                }));
                // Fetch learners to keep the list updated
                fetchLearners();
            }
        } catch (error) {
            console.error("Failed to add learner:", error);
        }
        setNewLearner({ firstName: "", lastName: "" });
    };

    // Determine if the button should be enabled
    const isButtonDisabled = !newLearner.firstName || !newLearner.lastName;

    return (
        <Card sx={{ width: "100%", maxWidth: "500px", margin: "auto", padding: "20px", border: "2px solid #5f9ea0", borderRadius: "16px", backgroundColor: "#ffffff" }}>
            <CardContent>
                <Typography variant="h6" sx={{ textAlign: "center", color: "#5F9EA0", marginBottom: "20px" }}>
                    Please add a learner for {courseName} to get started.
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={newLearner.firstName}
                            onChange={handleLearnerInputChange}
                            sx={{
                                "& .MuiInputBase-root": { height: "40px" },
                                "& fieldset": {
                                    borderColor: "#5F9EA0",
                                    borderRadius: "10px",
                                },
                                "& .Mui-focused fieldset": {
                                    borderColor: "#5F9EA0",
                                },
                                "& label.Mui-focused": {
                                    color: "#5F9EA0",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={newLearner.lastName}
                            onChange={handleLearnerInputChange}
                            sx={{
                                "& .MuiInputBase-root": { height: "40px" },
                                "& fieldset": {
                                    borderColor: "#5F9EA0",
                                    borderRadius: "10px",
                                },
                                "& .Mui-focused fieldset": {
                                    borderColor: "#5F9EA0",
                                },
                                "& label.Mui-focused": {
                                    color: "#5F9EA0",
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "20px" }}>
                    <Button
                        onClick={handleLearnerSubmit}
                        disabled={isButtonDisabled} // Button is disabled if first or last name is empty
                        sx={{
                            backgroundColor: isButtonDisabled ? "#c0c0c0" : "#5F9EA0",
                            color: "white",
                            "&:hover": {
                                backgroundColor: isButtonDisabled ? "#c0c0c0" : "#4d8b81",
                            },
                            borderRadius: "20px",
                            padding: "8px 16px",
                        }}
                    >
                        Enroll Learner
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EnrollLearnerForm;
