import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import getConfig from "../../config";
import "./resetPassword.css";

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = async () => {
        setIsLoading(true);
        const config = getConfig();
        const apiUrl = `${config.api.base_url}/forgot-password/`;

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                await Swal.fire("Success", "A password reset link has been sent to your email.", "success");
            } else {
                const data = await response.json();
                await Swal.fire("Error", data.error || "An error occurred. Please try again.", "error");
            }
        } catch (error) {
            Swal.fire("Error", "An unexpected error occurred. Please try again.", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignIn = () => {
        return navigate(`/signin`, { replace: true });
    }

    return (
        <div>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="94vh"
                bgcolor="#FFFFFF"
                px={2}
            >
                <Box
                    p={4}
                    bgcolor="white"
                    borderRadius="8px"
                    boxShadow={3}
                    maxWidth="400px"
                    textAlign="center"
                >
                    <Typography variant="h5" component="h1" gutterBottom>
                        Reset Password
                    </Typography>
                    <TextField
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={isLoading}
                        onClick={handleResetPassword}
                        className="reset-password-button"
                    >
                        {isLoading ? "Sending..." : "Send reset link"}
                    </Button>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ my: 1, fontSize: "17px" }}
                    >
                        Remember your password? &nbsp;
                        <Link href="#" onClick={handleSignIn}>Sign in</Link>
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default ResetPasswordPage;