import React from "react";
import Question from "../../components/CreateAssessment/Question/question"; // Adjust the import path if necessary

const QuestionColumn = ({ question, selectedQuestionIndex }) => {
    const combinedStyle = {
   backgroundColor: '#FFFFFF',
    padding: '20px',
    position: 'relative',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '800px',
    width: '400px',
    overflowY: 'auto',
  };

  return (
    <div style={combinedStyle}>
      {/* Render the passed question */}
      {question ? (
        <Question
              question={question}
              index={selectedQuestionIndex}
              showTags={false}
              showAddFromQuestionBank={false}
              showMemo={true}
          />
      ) : (
        <p>No question available</p>
      )}
    </div>
  );
};

export default QuestionColumn;
