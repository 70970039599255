import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import QuestionApiService from "../../services/api/questionAPIService";
import GenericWithSubquestionsComponent from "./genericWithSubquestionsComponent";

const UpdateQuestionComponent = ({ initialQuestion, onRemoveQuestion, handleDiscardUpdate, index, updateQuestionOnAssessmentPaper, grade, subject }) => {

  const [question, setQuestion] = useState(initialQuestion)

  const [topics, setTopics] = useState([]);
  const [, setErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleAddSubquestion = () => {
    setQuestion((prevState) => {
      const newSubquestion = {
        id: (prevState.sub_questions.length + 1).toString(),
        text: null,
      };
      return {
        ...prevState,
        sub_questions: [...prevState.sub_questions, newSubquestion]
      };
    })
  };

  const handleAddNestedSubquestion = (subIndex) => {

  };

  const handleDeleteQuestion = async (questionId) => {

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this Question!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;

      const apiResponse = await QuestionApiService.deleteQuestion(questionId, token);

      if (apiResponse.ok) {
        onRemoveQuestion(index);

        Swal.fire(
          'Deleted!',
          'Your Question has been deleted.',
          'success'
        );
      } else {
        Swal.fire(
          'Failed!',
          'Failed to delete the Question.',
          'error'
        );
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Question is safe :)',
        'error'
      );
    }
          setIsDeleting(false);
  };


const calculateTotalMarks = (question) => {
  let marks = 0;

  // Ensure Question.sub_questions is an array before iterating
  if (Array.isArray(question.sub_questions)) {
    question.sub_questions.forEach(subQuestion => {
      marks += Number(subQuestion.marks) || 0;

      // Iterate over nested sub-questions if they exist
      if (Array.isArray(subQuestion.nested_sub_questions)) {
        subQuestion.nested_sub_questions.forEach(nestedSubQuestion => {
          marks += Number(nestedSubQuestion.marks) || 0;
        });
      }
    });
  }

  return marks;
};

  const handleUpdateQuestion = async () => {
    const total_marks = calculateTotalMarks(question);

    // Update the Question state with marks
    const questionWithTotalMarks = {
      ...question,
      total_marks
    };

    try {
      setIsUpdating(true);
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;
      const response = await QuestionApiService.updateQuestion(questionWithTotalMarks.id, questionWithTotalMarks, token);
      if (response.ok) {

        const res = await response.json();
        updateQuestionOnAssessmentPaper( index, res );
        Toast.fire({
          icon: "success",
          title: "Question Successfully Updated.",
        })
      } else {
        const res = await response.json();
        // Handle API error by displaying it to the user
        setErrors({ loginError: res.error });
        Toast.fire({
          icon: "error",
          title: res.error || "Failed to update Question.",
        });
      }
    } catch (error) {

      console.error(error);
      // Handle general error by displaying it to the user
      setErrors({ loginError: error.message });
      Toast.fire({
        icon: "error",
        title: "An error occurred while updating the Question.",
      });
    }
    setIsUpdating(false);
  }

  const handleDeleteSubquestion = (subIndexToDelete) => {
    setQuestion(prevState => {
      const updatedSubQuestions = prevState.sub_questions.filter((_, index) => index !== subIndexToDelete);
      return {
        ...prevState,
        sub_questions: updatedSubQuestions
      };
    });
  };

  const handleDeleteNestedSubquestion = (subIndex, nestedIndexToDelete) => {
    setQuestion(prevState => {
      const updatedSubQuestions = [...prevState.sub_questions];
      updatedSubQuestions[subIndex].nested_sub_questions =
        updatedSubQuestions[subIndex].nested_sub_questions.filter((_, index) => index !== nestedIndexToDelete);

      return {
        ...prevState,
        sub_questions: updatedSubQuestions
      };
    });
  };

  useEffect(() => {
    setQuestion((currentQuestion) => {
      // Create a deep copy of the Question to avoid direct mutation
      const updatedQuestion = JSON.parse(JSON.stringify(currentQuestion));

      // Function to update topic_id recursively
      const updateTopicId = (questionPart) => {
        if (questionPart.topic && questionPart.topic.id) {
          questionPart.topic_id = questionPart.topic.id;
        }
        if (questionPart.sub_questions) {
          questionPart.sub_questions.forEach((subQuestion) => updateTopicId(subQuestion));
        }
        if (questionPart.nested_sub_questions) {
          questionPart.nested_sub_questions.forEach((nestedSubQuestion) => updateTopicId(nestedSubQuestion));
        }
      };

      // Update the main Question
      updateTopicId(updatedQuestion);

      return updatedQuestion;
    });
  }, []);

  // const handleMainQuestionChange = (data) => {
  //   setQuestion((prevState) => {
  //     return {
  //       ...prevState,
  //       text: data,
  //     };
  //   });
  // };

  const handleSubInstructionChange = (data, index) => {

    setQuestion((prevState) => {
      const updatedSubQuestions = [...prevState.sub_questions];
      // Update the instruction of the specific sub-Question at the index
      updatedSubQuestions[index - 1].instructions = data;

      return {
        ...prevState,
        sub_questions: updatedSubQuestions
      };
    });
  };

  const handleMainInstructionChange = (data, index = 0) => {
    setQuestion((prevState) => ({
      ...prevState,
      instructions: data
    }));
  };

  const handleNestedSubquestionChange = (content, subQuestionIndex, nestedSubQuestionIndex) => {
    setQuestion((prevState) => {
      const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) => {
        if (index === subQuestionIndex) {
          const updatedNestedSubQuestions = sub_question.nested_sub_questions.map((nested_sub_questions, nestedIndex) =>
            nestedIndex === nestedSubQuestionIndex ? { ...nested_sub_questions, text: content } : nested_sub_questions
          );
          return { ...sub_question, nested_sub_questions: updatedNestedSubQuestions };
        }
        return sub_question;
      });
      return {
        ...prevState,
        sub_questions: updatedSubQuestions,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await QuestionApiService.fetchTopics(grade, subject);
      if (response.ok) {
        const data = await response.json();
        setTopics(data);
      } else {
        console.error("Failed to fetch topics");
      }
    };
    fetchData();
  }, [grade, subject]);

  const handleSubQuestionTagChange = (tag_value, tag_name, subIndex) => {
    const updatedQuestion = { ...question };
    updatedQuestion.sub_questions = [...updatedQuestion.sub_questions];
    updatedQuestion.sub_questions[subIndex] = { ...updatedQuestion.sub_questions[subIndex] };
    updatedQuestion.sub_questions[subIndex][tag_name] = tag_value;
    setQuestion(updatedQuestion);
  };

  const handleNestedSubQuestionTagChange = (tag_value, tag_name, subIndex, nestedIndex) => {

    setQuestion(prevState => {
      // Deep copy of the previous state
      const updatedQuestion = JSON.parse(JSON.stringify(prevState));
      // Now, we can safely modify our copy
      if (updatedQuestion.sub_questions[subIndex] && updatedQuestion.sub_questions[subIndex].nested_sub_questions[nestedIndex]) {
        updatedQuestion.sub_questions[subIndex].nested_sub_questions[nestedIndex][tag_name] = tag_value;
      }
      // Return the updated copy to be used as the new state
      return updatedQuestion;
    });
  };

  const handleNestedSubQuestionMemoChange = (content, subQuestionIndex, nestedSubQuestionIndex) => {
    setQuestion((prevState) => {
      const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) => {
        if (index === subQuestionIndex) {
          const updatedNestedSubQuestions = sub_question.nested_sub_questions.map((nested_sub_question, nestedIndex) => {
            if (nestedIndex === nestedSubQuestionIndex) {
              const updatedNestedSubQuestion = {
                ...nested_sub_question,
                nested_sub_question_memo: {
                  text: content
                }
              }
              // Log the updated nested sub-Question
              return updatedNestedSubQuestion;
            } else {
              return nested_sub_question;
            }
          });
          return { ...sub_question, nested_sub_questions: updatedNestedSubQuestions };
        }
        return sub_question;
      });
      return {
        ...prevState,
        sub_questions: updatedSubQuestions,
      };
    })
  };

  const handleSubQuestionMemoChange = (data, subQuestionIndex) => {
    setQuestion((prevState) => {
      const updatedSubQuestions = prevState.sub_questions.map((subQuestion, index) => {
        if (index === subQuestionIndex) {
          const updatedSubQuestion = {
            ...subQuestion,
            sub_question_memo: {
              ...subQuestion.sub_question_memo,
              text: data
            }
          }

          return updatedSubQuestion;
        } else {
          return subQuestion;
        }
      });

      return {
        ...prevState,
        sub_questions: updatedSubQuestions
      };
    });
  };

  const handleSubquestionChange = (data, subQuestionIndex) => {
    setQuestion((prevState) => {
      const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) =>
        index === subQuestionIndex ? { ...sub_question, text: data } : sub_question
      );
      return {
        ...prevState,
        sub_questions: updatedSubQuestions,
      };
    });
  };

  const renderQuestionComponent = () => {
    let questionType;

    if (question.sub_questions && question.sub_questions.length > 0) {
      questionType = 'GenericWithSubquestions';
    } else {
      questionType = 'StandaloneGeneric';
    }
    switch (questionType) {
      case 'GenericWithSubquestions':
        return <GenericWithSubquestionsComponent
          index={index}
          question={question}
          handleAddSubquestion={handleAddSubquestion}
          handleSubInstructionChange={handleSubInstructionChange}
          handleSubQuestionTagChange={handleSubQuestionTagChange}
          handleMainInstructionChange={handleMainInstructionChange}
          handleSubquestionChange={handleSubquestionChange}
          handleAddNestedSubquestion={handleAddNestedSubquestion}
          handleNestedSubQuestionTagChange={handleNestedSubQuestionTagChange}
          handleNestedSubquestionChange={handleNestedSubquestionChange}
          topics={topics}
          handleDeleteSubquestion={handleDeleteSubquestion}
          handleDeleteNestedSubquestion={handleDeleteNestedSubquestion}
          handleSubQuestionMemoChange={handleSubQuestionMemoChange}
          handleNestedSubQuestionMemoChange={handleNestedSubQuestionMemoChange}
        />;

      // ... (other cases)
      default:
        return null;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        {/* You might want to handle this, as renderCreateQuestionComponent is not defined */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateQuestion}
          style={{ marginRight: '10px', height: '25px' }}
          disabled={isUpdating} // Disable the button while loading
        >
          {isUpdating ? 'Updating...' : 'Update'}
        </Button>
        <Button
          variant="contained"
          onClick={handleDiscardUpdate}
          style={{ marginRight: '10px', height: '25px', backgroundColor: '#004d40' }}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDeleteQuestion(question.id)}
          style={{ marginRight: '10px', height: '25px' }}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h3>Question {index + 1}</h3>
        {renderQuestionComponent()}
      </div>
    </div>
  );

};

export default UpdateQuestionComponent;