import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Grid, MenuItem, TextField, Typography } from "@mui/material";
import "./OnboardingPage.css";
import { useNavigate } from "react-router-dom";
import { AuthenticationAPIService } from "../../services/api/AuthenticationAPIService";

const OnboardingPage = () => {
    const [signupOption, setSignupOption] = useState('');
    const [province, setProvince] = useState('');
    const [suburb, setSuburb] = useState('');
    const [curriculum, setCurriculum] = useState('');
    const [organisationName, setOrganisationName] = useState('');
    const [organisationType, setOrganisationType] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState({ firstName: '', lastName: '' });
    const navigate = useNavigate();

    // State to track field errors
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        signupOption: false,
        province: false,
        suburb: false,
        curriculum: false
    });

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            const user = JSON.parse(storedUser);
            const [first_name, last_name] = user.name.split(" ");
            setName({
                firstName: first_name || '',
                lastName: last_name || ''
            });
            setEmail(user.email || '');
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if each required field is filled, and set errors if not
        const newErrors = {
            firstName: !name.firstName,
            lastName: !name.lastName,
            signupOption: !signupOption,
            province: !province,
            suburb: !suburb,
            curriculum: !curriculum
        };

        setErrors(newErrors);

        // If any field has an error, prevent form submission
        if (Object.values(newErrors).some((error) => error)) return;

        try {
            const storedUser = localStorage.getItem("user");
            if (storedUser) {
                const user = JSON.parse(storedUser);
                const token = user.token;
                const user_role = signupOption === 'Teacher/Tutor with Self Marking' ? 'marker' : 'teacher';
                const response = await AuthenticationAPIService.updateProfile(suburb, user_role, province, curriculum, organisationName, organisationType, token);

                if (response) {
                    localStorage.setItem(
                        "user",
                        JSON.stringify({
                            ...JSON.parse(localStorage.getItem("user")),
                            user_role: user_role
                        })
                    );
                    console.log("Profile updated successfully:", response);
                }

                if (signupOption === 'School') {
                    navigate('/onboarding/school');
                } else if (signupOption === 'Individual Teacher/Tutor' || signupOption === 'Teacher/Tutor with Self Marking') {
                    navigate('/onboarding/teacher');
                }
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    const handleSignupChange = (event) => {
        setSignupOption(event.target.value);
    };

    const handleProvinceChange = (event) => {
        setProvince(event.target.value);
    };

    const handleSuburbChange = (event) => {
        setSuburb(event.target.value);
    };

    const handleCurriculumChange = (event) => {
        setCurriculum(event.target.value);
    };

    const handleOrganisationNameChange = (event) => {
        setOrganisationName(event.target.value);
    };

    const handleOrganisationTypeChange = (event) => {
        setOrganisationType(event.target.value);
    };

    return (
        <div className="onboarding-card-container">
            <Card
                className="onboarding-card-educase"
                sx={{
                    borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: { xs: '5px', sm: '30px', md: '20px' },
                    width: { xs: '100vw', sm: '100vw', md: '80vw' },
                    maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                    margin: { xs: '0px', sm: '30px', md: '40px' }
                }}
            >
                <CardContent className="card-content-educase">
                    <p className="title-educase">
                        Hi, {name.firstName}! Welcome to Educase
                    </p>
                    <p className="subtitle-educase">
                        Congratulations on taking the first step towards unlocking personalized testing without the overwhelm!
                    </p>
                    <p className="instruction-educase">
                        First, let's make sure your profile looks good:
                    </p>

                    <Box sx={{ mt: 3 }}>
                        <h3 className="input-label-educase">What is your name?</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="firstName"
                                    value={name.firstName}
                                    placeholder="Enter your first name"
                                    margin="dense"
                                    error={errors.firstName}
                                    helperText={errors.firstName && <span className="error-text">First name is required</span>}
                                    sx={{
                                        height: '35px',
                                        margin: '0px',
                                        '& .MuiInputBase-root': { height: '35px' },
                                        '& fieldset': {
                                            borderColor: '#5F9EA0',
                                            borderRadius: '10px'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="lastName"
                                    value={name.lastName}
                                    placeholder="Enter your last name"
                                    margin="dense"
                                    error={errors.lastName}
                                    helperText={errors.lastName && <span className="error-text">Last name is required</span>}
                                    sx={{
                                        height: '35px',
                                        margin: '0px',
                                        '& .MuiInputBase-root': { height: '35px' },
                                        '& fieldset': {
                                            borderColor: '#5F9EA0',
                                            borderRadius: '10px'
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <h3 className="input-label-educase">Where are you located?</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="suburb"
                                    value={suburb}
                                    onChange={handleSuburbChange}
                                    placeholder="Enter your suburb or city, e.g., Midrand, Johannesburg, East Rand"
                                    margin="dense"
                                    error={errors.suburb}
                                    helperText={errors.suburb && <span className="error-text">Suburb is required</span>}
                                    sx={{
                                        height: '35px',
                                        margin: '0px',
                                        '& .MuiInputBase-root': { height: '35px' },
                                        '& fieldset': {
                                            borderColor: '#5F9EA0',
                                            borderRadius: '10px'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    variant="outlined"
                                    fullWidth
                                    id="province"
                                    value={province}
                                    onChange={handleProvinceChange}
                                    placeholder="Select your province"
                                    margin="dense"
                                    error={errors.province}
                                    helperText={errors.province && <span className="error-text">Province is required</span>}
                                    SelectProps={{
                                        displayEmpty: true,
                                    }}
                                    sx={{
                                        height: '35px',
                                        margin: '0px',
                                        '& .MuiInputBase-root': { height: '35px' },
                                        '& fieldset': {
                                            borderColor: '#5F9EA0',
                                            borderRadius: '10px'
                                        },
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select your province
                                    </MenuItem>
                                    <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
                                    <MenuItem value="Free State">Free State</MenuItem>
                                    <MenuItem value="Gauteng">Gauteng</MenuItem>
                                    <MenuItem value="KwaZulu-Natal">KwaZulu-Natal</MenuItem>
                                    <MenuItem value="Limpopo">Limpopo</MenuItem>
                                    <MenuItem value="Mpumalanga">Mpumalanga</MenuItem>
                                    <MenuItem value="Northern Cape">Northern Cape</MenuItem>
                                    <MenuItem value="North West">North West</MenuItem>
                                    <MenuItem value="Western Cape">Western Cape</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <h3 className="input-label-educase">What are you signing up for?</h3>
                                <TextField
                                    select
                                    variant="outlined"
                                    fullWidth
                                    id="signupOption"
                                    value={signupOption}
                                    onChange={handleSignupChange}
                                    placeholder="Select an option"
                                    margin="dense"
                                    error={errors.signupOption}
                                    helperText={errors.signupOption && <span className="error-text">This field is required</span>}
                                    sx={{
                                        width: '100%',
                                        height: '35px',
                                        margin: '0px',
                                        '& .MuiInputBase-root': { height: '35px' },
                                        '& fieldset': {
                                            borderColor: '#5F9EA0',
                                            borderRadius: '10px'
                                        },
                                    }}
                                >
                                    <MenuItem value="Individual Teacher/Tutor">Individual Teacher/Tutor</MenuItem>
                                    <MenuItem value="Teacher/Tutor with Self Marking">Teacher with Self Marking</MenuItem>
                                </TextField>
                            </Grid>

                            {/* Curriculum */}
                            <Grid item xs={12} sm={6}>
                                <h3 className="input-label-educase">Curriculum</h3>
                                <TextField
                                    select
                                    fullWidth
                                    value={curriculum}
                                    onChange={handleCurriculumChange}
                                    margin="dense"
                                    error={errors.curriculum}
                                    helperText={errors.curriculum && <span className="error-text">Curriculum is required</span>}
                                    sx={{
                                        width: '100%',
                                        height: '35px',
                                        margin: '0px',
                                        "& .MuiInputBase-root": { height: "35px" },
                                        "& fieldset": {
                                            borderColor: "#5F9EA0",
                                            borderRadius: "10px",
                                        },
                                    }}
                                >
                                    <MenuItem value="caps">CAPS</MenuItem>
                                </TextField>
                            </Grid>

                        </Grid>
                    </Box>

                    {/* Organisation Fields */}
                        <Box sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <h3 className="input-label-educase">Organisation Name (School Name)</h3>
                                    <TextField
                                        fullWidth
                                        value={organisationName}
                                        onChange={handleOrganisationNameChange}
                                        placeholder="Enter school/organisation name"
                                        margin="dense"
                                        sx={{
                                            width: '100%',
                                            height: '35px',
                                            margin: '0px',
                                            "& .MuiInputBase-root": { height: "35px" },
                                            "& fieldset": {
                                                borderColor: "#5F9EA0",
                                                borderRadius: "10px",
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <h3 className="input-label-educase">Organisation Type</h3>
                                    <TextField
                                        select
                                        fullWidth
                                        value={organisationType}
                                        onChange={handleOrganisationTypeChange}
                                        placeholder="Select organisation type"
                                        margin="dense"
                                        sx={{
                                            width: '100%',
                                            height: '35px',
                                            margin: '0px',
                                            "& .MuiInputBase-root": { height: "35px" },
                                            "& fieldset": {
                                                borderColor: "#5F9EA0",
                                                borderRadius: "10px",
                                            },
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select organisation type
                                        </MenuItem>
                                        <MenuItem value="primary_school">Primary School</MenuItem>
                                        <MenuItem value="high_school">High School</MenuItem>
                                        <MenuItem value="high_primary_shool">High/Primary School</MenuItem>
                                        <MenuItem value="university">University</MenuItem>
                                        <MenuItem value="college">College</MenuItem>
                                        <MenuItem value="technicon">Technicon</MenuItem>
                                        <MenuItem value="tutoring">Tutoring</MenuItem>
                                    </TextField>
                                </Grid>
                        </Grid>
                    </Box>

                    <Box mt={4} display="flex" justifyContent="flex-end">
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ backgroundColor: 'primary', color: 'white', width: '100px', fontSize: '16px', borderRadius: '16px' }}
                        >
                            Next
                        </Button>
                    </Box>

                    <Box sx={{ mt: 2, mb: 4 }}>
                        <Typography variant="body3" sx={{ color: '#777', textAlign: 'left', mb: 4 }}>
                            You're signed in as {email}. <a href="/auth/login" style={{ color: '#31c0e8', textDecoration: 'none', fontSize: '13px' }}>Sign out</a>
                        </Typography>
                    </Box>

                </CardContent>
            </Card>
        </div>
    );
};

export default OnboardingPage;
