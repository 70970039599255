import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import AIToolCard from "../../../components/AIToolCard/AIToolCard";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"; // Import the AutoAwesomeIcon from Material-UI
import lessonHook from "../../../assets/lesson-hook.png";
import criticalThinkingQuestions from "../../../assets/critical-thinking-question.png";
import exitTicket from "../../../assets/exit-ticket.png";
import realWorldApplications from "../../../assets/real-world-applications.png";
import studyNotes from "../../../assets/study-notes.png";
import summarizer from "../../../assets/summarizer.png";
import translator from "../../../assets/translator.png";
import manuallyChooseQuestions from "../../../assets/manually-choose-questions.png";
import Swal from "sweetalert2"; // Import Swal for alert messages
import "./index.css";

const GeneralAITools = () => {
  const navigate = useNavigate(); // Initialize navigate hook

  const generalCards = [
    { title: "Manually Choose Questions", description: "Manually select questions from our question bank.", image: manuallyChooseQuestions, link: "/assessment-details", isAI: false },
    { title: "Lesson Hooks", description: "Engaging ways to introduce a lesson to your class that inspire curiosity.", image: lessonHook, link: "/lesson-hooks", isAI: true },
    { title: "Exit Ticket", description: "Quick questions to gauge understanding of the lesson", image: exitTicket, link: "/exit-ticket", isAI: true },
    { title: "Critical Thinking Questions", description: "Deepen student understanding with critical thinking questions", image: criticalThinkingQuestions, link: "/critical-thinking-questions", isAI: true },
    { title: "Real World Applications", description: "Make lessons come to life with real world examples", image: realWorldApplications, link: "/real-world-applications", isAI: true },
    { title: "Study Notes", description: "Study notes summarizing what the teacher needs the learner to learn", image: studyNotes, link: "/study-notes", isAI: true },
    { title: "Summarizer", description: "Summarize text", image: summarizer, link: "/summarizer", isAI: true },
    { title: "Translator", description: "Translate text from any language", image: translator, link: "/translator", isAI: true },
  ];

  // Event handler to navigate or show "Coming Soon" for AI features
  const handleCardClick = (link, isAI) => {
    if (isAI) {
      // Display a "Coming Soon" alert for AI tools
      Swal.fire({
        title: "Coming Soon!",
        text: "This AI feature is currently under development. Stay tuned!",
        icon: "info",
        confirmButtonText: "Okay",
      });
    } else {
      navigate(link); // Navigate to the link if it's not an AI feature
    }
  };

  return (
    <div className="general-tools-grid">
      {generalCards.map((card, index) => (
        <div key={index} onClick={() => handleCardClick(card.link, card.isAI)} style={{ position: 'relative', cursor: 'pointer' }}>
          {/* AI Badge for AI-powered cards */}
          {card.isAI && (
            <div style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              padding: '4px 8px',
            }}>
              <AutoAwesomeIcon className="icon" style={{ color: '#f6a00a', marginRight: '4px' }} />
              <span style={{ color: '#333', fontWeight: 'bold' }}>AI</span>
            </div>
          )}

          {/* AIToolCard Component */}
          <AIToolCard
            title={card.title}
            description={card.description}
            image={card.image}
          />
        </div>
      ))}
    </div>
  );
};

export default GeneralAITools;
