import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress from MUI
import CourseAssessmentResultsApiService from "../../services/api/CourseAssessmentResultsApiService";
import UploadScriptsInstructions from "./Instructions/uploadScriptsInstructions";
import ScriptDetails from "./ScriptsDetails/ScriptDetails";
import UploadLearnerScripts from "./UploadScripts/UploadLearnerScripts";
import CourseAssessmentsWidgets from "./CourseAssessmentsWidgets/CourseAssessmentsWidgets";
import { useNavigate } from "react-router-dom";

const UploadScripts = () => {
  const [courseAssessments, setCourseAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCourseAssessment, setSelectedCourseAssessment] = useState(null);
  const [showScriptDetails, setShowScriptDetails] = useState(false);
  const [showUploadLearners, setShowUploadLearners] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(selectedCourseAssessment?.course || '');

  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dispatch the action when the component loads
  useEffect(() => {
    const fetchAllCourseAssessments = async () => {
      try {
        const response = await CourseAssessmentResultsApiService.getAllCourseAssessmentResults(token);
        if (response && response.length > 0) {
          setCourseAssessments(response);
        }
      } catch (error) {
        console.error("Failed to fetch course assessments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCourseAssessments();
  }, [token, dispatch]);

  const createInitials = (firstName, lastName) => {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  const handleNewMarkingSubmission = () => {
    setSelectedCourseAssessment(null);
    setShowScriptDetails(true);
  };

  const onUploadScriptsBack = () => {
    setShowUploadLearners(false);
    setShowScriptDetails(true);
  };

  const handleViewResults = (id) => {
    navigate(`/stats/${id}`);
  };

  const handleUploadLearners = (course, courseAssessment) => {
    setSelectedCourseAssessment(courseAssessment);
    setSelectedCourse(course);
    setShowUploadLearners(true);
    setShowScriptDetails(false);
  };

  const handleWidgetClick = (courseAssessment) => {
    setSelectedCourseAssessment(courseAssessment);
    setShowScriptDetails(true);
  };

  return (
    <div style={{ margin: "10px", display: "flex", flexDirection: "column", gap: "5px", overflowY: "auto" }}>
      {/* Conditionally Render ScriptDetails, UploadLearnerScripts, or the Table */}
      {showUploadLearners ? (
        <UploadLearnerScripts course={selectedCourse} assessmentId={selectedCourseAssessment.exam_paper} onBack={onUploadScriptsBack} />
      ) : showScriptDetails ? (
        <ScriptDetails
          courseAssessment={selectedCourseAssessment}
          onClose={() => setShowScriptDetails(false)}
          onUploadLearners={handleUploadLearners}
        />
      ) : loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress /> {/* Use CircularProgress component */}
        </div>
      ) : courseAssessments.length === 0 ? (
        <UploadScriptsInstructions handleNewMarkingSubmission={handleNewMarkingSubmission} />
      ) : (
        <CourseAssessmentsWidgets
          courseAssessments={courseAssessments}
          createInitials={createInitials}
          handleWidgetClick={handleWidgetClick}
          handleNewMarkingSubmission={handleNewMarkingSubmission}
          handleViewResults={handleViewResults}
        />
      )}
    </div>
  );
};

export default UploadScripts;
