/*jslint es6*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: '',
    grade: '',
    subject: 0,
    subject_name: '',
    type: '',
    total_marks: 0,
    duration: '12:00',
    status: '',
    name: '',
    source: '',
    year: 0,
    assessment_date: '',
    selectedTopics: 0,
    assessmentPaper: [],
    instructions: '',
    time: null,
};

const assessmentSlice = createSlice({
    name: "assessment",
    initialState,
    reducers: {
        setAssessment(state, action) {
            return action.payload;
        },
        addQuestion(state, action) {
            state.questions.push(action.payload);
        },
        removeQuestion(state, action) {
            state.questions = state.questions.filter((question) => question.id !== action.payload);
        },
        setInstructions(state, action) {
            state.instructions = action.payload;
        },
        setSelectedTopics(state, action) {
            state.selectedTopics = action.payload;
        },
        addQuestionToAssessmentPaper(state, action) {
            state.assessmentPaper.push(action.payload);
        },
        updateQuestion(state, action) {
            const { res, index } = action.payload;
            state.assessmentPaper[index] = res;
        },
        removeQuestionFromAssessmentPaper(state, action) {
            const index = action.payload;
            state.assessmentPaper.splice(index, 1);
        },
        updateMarks(state, action) {
            state.total_marks = action.payload;
        },
        moveQuestionUpInAssessmentPaper(state, action) {
            const questionIndex = action.payload;
            if (questionIndex > 0) {
                const newQuestions = [...state.assessmentPaper];
                const [movedQuestion] = newQuestions.splice(questionIndex, 1);
                newQuestions.splice(questionIndex - 1, 0, movedQuestion);

                state.assessmentPaper = newQuestions;
            }
        },
        moveQuestionDownInAssessmentPaper(state, action) {
            const questionIndex = action.payload;
            if (questionIndex < state.assessmentPaper.length - 1) {
                const newQuestions = [...state.assessmentPaper];
                const [movedQuestion] = newQuestions.splice(questionIndex, 1);
                newQuestions.splice(questionIndex + 1, 0, movedQuestion);

                state.assessmentPaper = newQuestions;
            }
        },
        addEmptyQuestionAtIndex(state, action) {
            const { initialQuestionState, index } = action.payload;

            if (index === undefined || index >= state.assessmentPaper.length) {
                state.assessmentPaper.push(initialQuestionState);
            } else {

                // Otherwise, insert the Question at the specified index
                state.assessmentPaper.splice(index, 0, initialQuestionState);
            }
        },
        addEmptyNestedQuestionAtIndex(state, action) {

            const { initialNestedQuestionState, index } = action.payload;
            // If the index is greater than or equal to the length of assessmentPaper
            // or not specified, add the Question to the end.
            if (index === undefined || index >= state.assessmentPaper.length) {
                state.assessmentPaper.push(initialNestedQuestionState);
            } else {
                // Otherwise, insert the Question at the specified index
                state.assessmentPaper.splice(index, 0, initialNestedQuestionState);
            }
        },
        addQuestionAtIndex(state, action) {
            const { res, index } = action.payload;

            // If the index is greater than or equal to the length of assessmentPaper
            // or not specified, add the Question to the end.
            if (index === undefined || index >= state.assessmentPaper.length) {
                state.assessmentPaper.push(res);
            } else {
                // Otherwise, insert the Question at the specified index
                state.assessmentPaper.splice(index, 0, res);
            }
        },
        populateState(state, action) {
            const { subject, questions, ...restPayload } = action.payload;
            return {
                ...state,
                ...restPayload,
                subject: subject.id,
                subject_name: subject.name,
                assessmentPaper: questions

            };
        },
        populateAssessmentPaper(state, action) {
            // Clear existing assessmentPaper array
            state.assessmentPaper = [];
            // Populate with new questions
            if (Array.isArray(action.payload)) {
                state.assessmentPaper = [...action.payload];
            }
        },
        populateExamDetails(state, action) {
            const [name, value] = action.payload;
            if (Object.prototype.hasOwnProperty.call(state, name)) {
                state[name] = value;
            } else {
                console.warn(`Attempted to update non-existent property: ${name}`);
            }
        },
        createInstructions(state, action) {
            state.instructions = action.payload;
        },
        updateAssessmentStatus(state, action) {
            state.status = action.payload;
        },
        resetAssessment(state) {
            state = initialState;

        },
        updateSubjectDetails(state, action) {
            const { id, name } = action.payload;
            state.subject_name = name;
            state.subject = id;

        },

    },
});

export const {
    updateMarks,
    removeQuestion,
    addQuestionToAssessmentPaper,
    updateQuestion,
    populateState,
    removeQuestionFromAssessmentPaper,
    moveQuestionUpInAssessmentPaper,
    moveQuestionDownInAssessmentPaper,
    addEmptyQuestionAtIndex,
    populateExamDetails,
    createInstructions,
    populateAssessmentPaper,
    updateAssessmentStatus,
    addQuestionAtIndex,
    updateSubjectDetails
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
