import React from "react";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";

import "./uploadScriptsInstructions.css";
import Question2 from "../../../assets/Quesiton2.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const UploadScriptsInstructions = ({ handleNewMarkingSubmission }) => {
  const textColor = '#494b4e';
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        border: "1px solid #5f9ea0",
        borderRadius: 2,
        boxShadow: 'none',
        mt: 2,
        mx: 'auto',  // Center the card horizontally
        padding: { xs: '10px', sm: '20px', md: '30px' },  // Responsive padding
        width: { xs: '90%', sm: '85%', md: '90%' },  // Ensure width shrinks on smaller screens
        height: 'fit-content',  // Allow height to adjust based on content
        maxWidth: '1200px',  // Ensure maximum width does not exceed a reasonable size
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Grid container spacing={3} sx={{ padding: { xs: '10px', sm: '20px' } }}>  {/* Responsive padding */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom style={{ color: textColor }}>
                How to Upload Scripts for Marking
              </Typography>
              <Typography variant="body1" paragraph style={{ color: textColor }}>
                Follow these steps to upload student scripts for marking:
              </Typography>
              <ul className="instructions-list" style={{ color: textColor }}>
                <li>Click on the "Browse" button to select the scripts from your computer.</li>
                <li>The supported file formats are PDF and image files (.pdf, .jpg, .png).</li>
                <li>Ensure that each script is clearly labeled with the student's name and subject.</li>
                <li>Click the "Upload" button once you've selected all the files.</li>
              </ul>
              <Card className="info-card" sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6" style={{ color: textColor }}>
                    Important Information
                  </Typography>
                  <Typography variant="body2" style={{ color: textColor }}>
                    Please ensure that the scripts are legible and organized before uploading. Incomplete or unreadable files may result in delayed marking.
                  </Typography>
                </CardContent>
              </Card>

              <Box textAlign="left" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  className="start-project-button"
                  onClick={handleNewMarkingSubmission}
                >
                  Start Script Upload
                </Button>
              </Box>
            </Grid>
              <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Card sx={{ padding: 2, backgroundColor: '#fff', boxShadow: 'none', border: '1px solid #e0e0e0', borderRadius: 2 }}>
              <Box
                component="img"
                src={Question2}
                alt="Question 2"
                sx={{ width: '100%', maxWidth: { xs: '250px', sm: '300px', md: '350px' }, margin: '0 auto', display: 'block' }}  // Responsive image size
              />
            </Card>
            </Grid>

          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UploadScriptsInstructions;
