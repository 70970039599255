import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import CourseApiService from "../../services/api/courseAPIService";

const ManageLearners = () => {
    const [learners, setLearners] = useState([]);
    const [newLearner, setNewLearner] = useState({ firstName: '', lastName: '', grade: '' });
    const [editIndex, setEditIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const { courseId, courseName } = useParams();
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    useEffect(() => {
        fetchLearners();
    }, []);

    const fetchLearners = async () => {
        try {
            const response = await CourseApiService.getLearnersForCourse(courseId, token);
            if (response.status === 200) {
                const learnersData = await response.json();
                const mappedData = learnersData.map(learner => ({
                    id: learner.id,
                    firstName: learner.first_name,
                    lastName: learner.last_name,
                    grade: learner.grade,
                    enrollmentId: learner.enrollment_id
                }));
                setLearners(mappedData);
            } else {
                console.error("Failed to fetch learners.");
            }
        } catch (error) {
            console.error("Error fetching learners:", error);
        }
    };

    const handleOpenModal = () => {
        setNewLearner({ firstName: '', lastName: '', grade: '' });
        setEditIndex(-1);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleLearnerInputChange = (e) => {
        const { name, value } = e.target;
        setNewLearner({ ...newLearner, [name]: value });
    };

    const handleLearnerSubmit = async (e) => {
        e.preventDefault();

        const learnerData = {
            first_name: newLearner.firstName,
            last_name: newLearner.lastName,
            course_id: courseId
        };

        try {
            if (editIndex === -1) {
                const response = await CourseApiService.enrollLearner(learnerData, token);
                if (response.status === 201) {
                    alert('Learner enrolled successfully');
                    fetchLearners();
                } else {
                    console.error("Failed to enroll learner:", response.data);
                }
            } else {
                const learnerId = learners[editIndex].id;
                const response = await CourseApiService.updateLearner(learnerId, learnerData, token);
                if (response.status === 200) {
                    alert('Learner updated successfully');
                    fetchLearners();
                } else {
                    console.error("Failed to update learner:", response.data);
                }
            }
            handleCloseModal();
        } catch (error) {
            console.error("Error enrolling/updating learner:", error);
        }
    };

    const handleLearnerEdit = (index) => {
        setNewLearner({
            firstName: learners[index].firstName,
            lastName: learners[index].lastName,
            grade: learners[index].grade
        });
        setEditIndex(index);
        setOpen(true);
    };

    const handleRemoveLearner = async (index) => {
        const learner = learners[index];
        const isConfirmed = window.confirm(`Are you sure you want to remove ${learner.firstName} ${learner.lastName} from this course?`);

        console.log(learner)

        if (isConfirmed) {
            try {
                const response = await CourseApiService.removeLearnerFromCourse(learner.enrollmentId, token);
                if (response.status === 200) {
                    setLearners(learners.filter((_, idx) => idx !== index));
                } else {
                    console.error("Failed to remove learner:", response.data);
                }
            } catch (error) {
                console.error("Error removing learner:", error);
            }
        }
    };

    // Determine if the button should be disabled
    const isButtonDisabled = !newLearner.firstName || !newLearner.lastName;

    return (
        <div>
            <Card sx={{
                borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                padding: { xs: '5px', sm: '30px', md: '40px' },
                width: { xs: '100vw', sm: '100vw', md: '80vw' },
                maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                margin: { xs: '0px', sm: '30px', md: '40px' },
            }}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                        <Typography variant="h4">Manage Learners for {courseName}</Typography>
                        <Button variant="contained" color="primary" onClick={handleOpenModal}>Add Learner</Button>
                    </Box>

                    <Typography variant="h6" sx={{ mt: 5 }}>Learner List</Typography>
                    <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {learners.map((learner, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{learner.firstName}</TableCell>
                                        <TableCell>{learner.lastName}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleLearnerEdit(index)} color="primary">
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleRemoveLearner(index)} color="tertiary">
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>

                    <Dialog open={open} onClose={handleCloseModal}>
                        <DialogTitle sx={{ fontWeight: 'bold', color: '#5F9EA0', textAlign: 'center', fontSize: '1.5rem' }}>
                            {editIndex === -1 ? 'Add Learner' : 'Edit Learner'}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        value={newLearner.firstName}
                                        onChange={handleLearnerInputChange}
                                        sx={{
                                            "& .MuiInputBase-root": { height: "40px" },
                                            "& fieldset": { borderColor: "#5F9EA0", borderRadius: "10px" },
                                            "& .MuiInputLabel-root": { color: "#5F9EA0" }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        name="lastName"
                                        value={newLearner.lastName}
                                        onChange={handleLearnerInputChange}
                                        sx={{
                                            "& .MuiInputBase-root": { height: "40px" },
                                            "& fieldset": { borderColor: "#5F9EA0", borderRadius: "10px" },
                                            "& .MuiInputLabel-root": { color: "#5F9EA0" }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
                            <Button
                                onClick={handleCloseModal}
                                sx={{ backgroundColor: '#F44336', color: 'white', '&:hover': { backgroundColor: '#D32F2F' }, borderRadius: '8px', padding: '10px 20px' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleLearnerSubmit}
                                disabled={isButtonDisabled}  // Button is disabled if first or last name is empty
                                sx={{
                                    backgroundColor: isButtonDisabled ? "#c0c0c0" : "#5F9EA0",
                                    color: 'white',
                                    '&:hover': { backgroundColor: isButtonDisabled ? "#c0c0c0" : '#467B7F' },
                                    borderRadius: '8px',
                                    padding: '10px 20px'
                                }}
                            >
                                {editIndex === -1 ? 'Add Learner' : 'Update Learner'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Card>
        </div>
    );
};

export default ManageLearners;
