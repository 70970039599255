import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import "./AdobePDFViewer.css";
import getConfig from "../../../../../config/index";
import { annotationsList } from "../../../../../utils/annotations";

const AdobePDFViewer = React.forwardRef(({ pdfUrl, onPdfSaved }, ref) => {
  const adobeDCViewRef = useRef(null);

  useEffect(() => {
    if (!pdfUrl) {
      console.error("No PDF URL provided!");
      return;
    }

    const config = getConfig();

    if (window.AdobeDC) {
      adobeDCViewRef.current = new window.AdobeDC.View({
        clientId: config.adobePdfEmbed.clientId,
        divId: "adobe-pdf-viewer",
      });

      var previewFilePromise = adobeDCViewRef.current.previewFile({
        content: {
          location: {
            url: pdfUrl,
          },
        },
        metaData: {
          fileName: "exam.pdf",
          id: "77c6fa5d-6d74-4104-8349-657c8411a834"
        },
      }, {
        embedMode: "FULL_WINDOW",
        enableAnnotationAPIs: true,
        includePDFAnnotations: true,
        downloadWithAnnotations: true,
        showAnnotationTools: true,
      });

      previewFilePromise.then(adobeViewer => {
        adobeViewer.getAnnotationManager().then(annotationManager => {
          const annotationOptions = {
            defaultColor: "#FF0000",
            opacity: 1,
            cursor: "crosshair",
            strokeWidth: 2
          };

          const annotationMode = "shape";
          annotationManager.startAnnotationMode(annotationMode, annotationOptions)
            .then(result => {
              console.log("Annotation mode started:", result);
            })
            .catch(error => {
              console.error("Error starting annotation mode:", error);
            });

          // Register save callback
          adobeDCViewRef.current.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.SAVE_API,
            function (metaData, content, options) {
              return new Promise((resolve, reject) => {
                // Save the modified PDF to Cloudflare
                const formData = new FormData();
                const blob = new Blob([content], { type: 'application/pdf' });
                const hashedFileName = `educase_learners_answer_${generateRandomHash()}.pdf`;

                formData.append("document", blob, hashedFileName);
                formData.append("metaData", JSON.stringify(metaData));

                axios.post(`https://scripts.educasetools.workers.dev/${hashedFileName}`, formData, {
                  headers: {
                    'X-Custom-Auth-Key': '94CA5CDACCEEED5A8BCA28C853137'
                  }
                }).then(response => {
                  const savedPdfUrl = response.data.url;
                  resolve({
                    code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                    data: {
                      metaData: Object.assign(metaData, { fileName: hashedFileName }),
                    }
                  });

                  // Call the callback function to pass the saved URL to the parent
                  if (onPdfSaved) {
                    onPdfSaved(savedPdfUrl);
                  }

                }).catch(error => {
                  reject({
                    code: window.AdobeDC.View.Enum.ApiResponseCode.FAIL,
                  });
                });
              });
            },
            {
              autoSaveFrequency: 0,
              showSaveButton: true
            }
          );
        });
      }).catch(error => {
        console.error("Error loading PDF:", error);
      });
    }
  }, [pdfUrl]);

  return (
    <div id="adobe-pdf-viewer" className="adobePDFViewerContainer"></div>
  );
});

export default AdobePDFViewer;

function generateRandomHash() {
  const arr = new Uint8Array(8);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join('').substring(0, 16);
}
