import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageUploadComponent from "../ImageUploader/ImageUploader";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import "./UploadAnswers.css";
import { Card, CardContent, Typography, Avatar, Box } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload"; // Add Material UI components

const UploadAnswers = () => {
    const location = useLocation();
    const { questionsData, answerImageUrls: initialAnswerImageUrls = [], onlineTestId, learnerId } = location.state;
    const [answerImageUrls, setAnswerImageUrls] = useState(Array.isArray(initialAnswerImageUrls) ? initialAnswerImageUrls : []);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const assessmentId = localStorage.getItem("assessmentId");
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { firstName, lastName } = parsedUserInfo;
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const saveAnswers = async () => {
        const { token } = parsedUserInfo;
        try {
            setIsLoading(true);
            const response = await OnlineTestApiService.submitLearnerScripts(learnerId, assessmentId, answerImageUrls, onlineTestId, token);
            setIsLoading(false);

            if (response) {
                Toast.fire({
                    icon: 'success',
                    title: 'Test submitted!'
                });
                navigate('/thank-you');
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Test failed to submit.'
            });
            console.error('Error saving answers:', error);
        }
    };

    const handleFinishUpload = () => {
        setIsConfirmationOpen(true);
    };

    const handleConfirmUpload = () => {
        setIsConfirmationOpen(false);
        saveAnswers();
    };

    const handleCancelUpload = () => {
        setIsConfirmationOpen(false);
    };

    const handleImageUpload = (imageUrl) => {
        setAnswerImageUrls((prevUrls) => [...prevUrls, imageUrl]);
    };

    const handleImageDelete = (imageUrl) => {
        setAnswerImageUrls((prevUrls) => prevUrls.filter((url) => url !== imageUrl));
    };

    const totalAnswered = answerImageUrls.length;

    if (isLoading) {
        return (
            <div className="upload-answers-container-loading-screen">
                <h2 className='upload-answers-container-heading'>Submitting your answers</h2>
                <CircularProgress className='upload-answers-container-loading' style={{ color: 'white' }} />
            </div>
        );
    } else {
        return (
            <div className="uploadAnswersContainer">
                <h1>Upload Answers</h1>

                <div className="summary">
                    <p>Subject: {questionsData.subject.name}</p>
                    <p>Grade: {questionsData.grade}</p>
                    <p>Answered: {totalAnswered} questions</p>
                </div>

                {/* Explanation and rules card */}
                <Card sx={{ marginBottom: "20px", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: 2 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            How to Upload Your Answers:
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Please make sure to upload each answer as a clear, separate image. Follow the rules below:
                        </Typography>
                        <ul className="rules-list">
                            <li>Each answer should be on a separate page.</li>
                            <li>Images must be clear and readable.</li>
                            <li>You can review the uploaded images before final submission.</li>
                        </ul>
                    </CardContent>
                </Card>

                {/* ImageUploader component */}
                <ImageUploadComponent
                    subject={questionsData.subject.name}
                    grade={questionsData.grade}
                    assessmentId={assessmentId}
                    learnerId={learnerId}
                    firstName={firstName}
                    lastName={lastName}
                    onImageUpload={handleImageUpload}
                    onImageDelete={handleImageDelete}
                    existingImages={initialAnswerImageUrls || []}
                />

                <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleFinishUpload}
                    sx={{ marginTop: 3 }}
                >
                    Submit All Answers
                </Button>


                {/* Confirmation Dialog */}
                <Dialog open={isConfirmationOpen} onClose={handleCancelUpload}>
                    <DialogContent>
                        <p>Are you sure you want to submit all the answers for this test?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelUpload} color="primary">Cancel</Button>
                        <Button onClick={handleConfirmUpload} color="primary">Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

export default UploadAnswers;
