import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import EducaseLogoLight from "../../assets/educase-logo-light2.png";
import "./navBar.css";
import HomeIconImage from "../../assets/home.png";
import ViewMetrics from "../../assets/report.png";
import ViewAssessments from "../../assets/viewassessments.png";
import { useDispatch, useSelector } from "react-redux";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { setMobileIsNavBarVisible } from "../../reducers/componentStates";
import AssignmentIcon from "@mui/icons-material/Assignment";

const NavBar = () => {
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(null);
    const [isMobile] = useState(window.innerWidth <= 700);
    const isMobileNavBarVisible = useSelector((state) => state.componentStates.isMobileNavBarVisible);

    const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = userInfo.user_role || '';
    const handleMenuItemClick = (menuItem) => {
        dispatch(setMobileIsNavBarVisible(false));
        setActiveItem(menuItem);
    }


    if (isMobile && !isMobileNavBarVisible){
        return null;
    }



    return (
    <nav
        className={'nav-bar'}
        tabIndex={0}
    >
        <div className="nav-bar-header">
            <img
                src={EducaseLogoLight}
                alt="Company Logo"
                className="nav-logo"
            />
        </div>
        <MenuList className="menu-list">
            <div className={'close-navbar-home-button'}>
                <Link to="/home" style={{ color: "#4a606e", textDecoration: "none", flex: 1 }}>
                    <MenuItem className={`menu-item ${activeItem === "/" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/")}>
                        <ListItemIcon>
                            <img src={HomeIconImage} alt="Home" className="icon" style={{ width: '20px', height: '20px' }} />
                        </ListItemIcon>
                        <ListItemText>Home</ListItemText>
                    </MenuItem>
                </Link>

                {isMobile && (
                    <IconButton className="close-nav-button" onClick={() => dispatch(setMobileIsNavBarVisible(false))}>
                        <span>X</span>
                    </IconButton>
                )}
            </div>

            {/*<Link to="/assessment-details" style={{ color: "#4a606e", textDecoration: "none" }}>*/}
            {/*    <MenuItem className={`menu-item ${activeItem === "/ai-tools" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/ai-tools")}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <AutoAwesomeIcon className="icon" style={{ color: '#f6a00a' }} />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Create Assessment</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*</Link>*/}

            <Link to="/assessments" style={{ color: "#4a606e", textDecoration: "none" }}>
                <MenuItem className={`menu-item ${activeItem === "/assessments" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/assessments")}>
                    <ListItemIcon>
                        <img src={ViewAssessments} alt="ViewAllAssessments" className="icon" style={{ width: '20px', height: '20px' }} />
                    </ListItemIcon>
                    <ListItemText>Assessments</ListItemText>
                </MenuItem>
            </Link>

            <Link to="/upload-scripts" style={{ color: "#4a606e", textDecoration: "none" }}>
                <MenuItem className={`menu-item ${activeItem === "/upload-scripts" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/upload-scripts")}>
                    <ListItemIcon>
                        <AssignmentTurnedInIcon style={{ color: '#4CAF50' }} /> {/* Marking icon */}
                    </ListItemIcon>
                    <ListItemText>Marking Submissions</ListItemText>
                </MenuItem>
            </Link>

             {/* Conditional rendering: Show Manual Marking only if not on mobile */}
                {!isMobile && (userRole === "admin" || userRole === "marker") && (
                    <Link to="/manual-marking/get-assessment-course" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem
                            className={`menu-item ${activeItem === "/manual-marking/get-assessment-course" ? "active-item" : ""}`}
                            onClick={() => handleMenuItemClick("/manual-marking/get-assessment-course")}
                        >
                            <ListItemIcon>
                                <AssignmentIcon style={{ color: '#f44336' }} /> {/* Icon for Manual Marking */}
                            </ListItemIcon>
                            <ListItemText>Manual Marking</ListItemText>
                        </MenuItem>
                    </Link>
                )}

            <Link to="/course-results" style={{ color: "#4a606e", textDecoration: "none" }}>
                <MenuItem className={`menu-item ${activeItem === "/course-results" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/course-results")}>
                    <ListItemIcon>
                        <img src={ViewMetrics} alt="ViewMetrics" className="icon" style={{ width: '20px', height: '20px' }} />
                    </ListItemIcon>
                    <ListItemText>Results & Insights</ListItemText>
                </MenuItem>
            </Link>

            {/*<Link to="/help" style={{ color: "#4a606e", textDecoration: "none" }}>*/}
            {/*    <MenuItem className={`menu-item ${activeItem === "/help" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/help")}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <HelpIcon style={{ color: '#ee907b' }} className="icon" />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Help</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*</Link>*/}
        </MenuList>

        {/* Footer Section */}
        <div className="nav-bar-footer" style={{ padding: '10px', marginTop: 'auto', textAlign: 'center', color: '#4a606e' }}>
            <Typography variant="body2" component="div">
                Copyright © 2024 Educase
            </Typography>
        </div>
    </nav>
);

};

export default NavBar;
