import React, { useState } from "react";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { AuthenticationAPIService } from "../../../../services/api/AuthenticationAPIService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../../../assets/educase-logo-light.png";
import "./TestSignUpPage.css";

const TestSignUpPage = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (credentials.password !== credentials.confirmPassword) {
      Toast.fire({
        icon: 'error',
        title: 'Passwords do not match',
      });
      return;
    }

    try {
      setIsLoading(true);
      await AuthenticationAPIService.register({
        email: credentials.email.trim(),
        password: credentials.password.trim(),
        first_name: credentials.firstName.trim(),
        last_name: credentials.lastName.trim(),
        user_role: 'student',
        contact_number: credentials.contactNumber.trim(),
      });
      navigate('/test-sign-in');
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="test-sign-up-page-container">
      <img src={logo} alt="Educase Logo" className="test-sign-up-page-logo" />
      <div className="test-sign-up-page-card-wrapper">
        <Paper className="test-sign-up-page-card">
          <Typography variant="h4" className="test-sign-up-page-title">
            Student Sign Up
          </Typography>
          <Typography variant="subtitle1" className="test-sign-up-page-subtitle">
            Create your account
          </Typography>
          <form onSubmit={handleSignUp}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={credentials.firstName}
              onChange={(e) => setCredentials({ ...credentials, firstName: e.target.value })}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={credentials.lastName}
              onChange={(e) => setCredentials({ ...credentials, lastName: e.target.value })}
            />
            <TextField
              label="Contact Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={credentials.contactNumber}
              onChange={(e) => setCredentials({ ...credentials, contactNumber: e.target.value })}
            />
            <TextField
              label="email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={credentials.email}
              onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showConfirmPassword ? "text" : "password"}
              value={credentials.confirmPassword}
              onChange={(e) => setCredentials({ ...credentials, confirmPassword: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? "Signing Up..." : "Sign Up"}
              </Button>
            </Box>
          </form>
          <Box mt={2}>
            <Typography variant="body2">
              Already have an account? <Link component={RouterLink} to="/test-sign-in">Sign In</Link>
            </Typography>
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default TestSignUpPage;