import * as React from 'react';
import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { Card, CardContent, Typography, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Divider } from "@mui/material";

// Prepare the data for the chart based on the question, topic, or cognitive level results
const prepareChartData = (data, dataType, index) => {
  return {
    name: dataType === 'questions' ? `Question ${index + 1}` : data.name,
    Passed: data.learners_passed ? data.learners_passed.length : 0,
    Failed: data.learners_failed ? data.learners_failed.length : 0,
    Skipped: data.learners_skipped ? data.learners_skipped.length : 0
  };
};

const ClassPerformanceStats = ({ questions, topics, cognitiveLevels }) => {
  const [dataType, setDataType] = useState('questions');

  // Convert cognitive levels object to an array
  const cognitiveLevelsArray = Object.entries(cognitiveLevels).map(([level_name, levelData]) => ({
    name: level_name,
    ...levelData
  }));

  // Determine the dataset based on the selection
  const data = dataType === 'questions'
    ? questions
    : dataType === 'topics'
    ? topics
    : cognitiveLevelsArray;

  const sectionType = dataType === 'questions' ? 'Question' : dataType === 'topics' ? 'Topic' : 'Cognitive Level';

  // Prepare chart data for the full dataset with dynamic names
  const chartData = data.map((dataItem, index) => prepareChartData(dataItem, dataType, index));

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  return (
    <Card style={{ backgroundColor: '#FFFFFF' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>
            Class Performance: Results by {sectionType}
          </Typography>

          {/* Radio buttons to switch between question, topic, and cognitive level */}
          <FormControl component="fieldset">
            <FormLabel component="legend">View Results By</FormLabel>
            <RadioGroup row value={dataType} onChange={handleDataTypeChange}>
              <FormControlLabel value="questions" control={<Radio />} label="Questions" />
              <FormControlLabel value="topics" control={<Radio />} label="Topics" />
              <FormControlLabel value="cognitiveLevels" control={<Radio />} label="Cognitive Levels" />
            </RadioGroup>
          </FormControl>
        </Box>

        {/* Divider before the stats */}
        <Divider sx={{ mb: 5 }} />

        {/* Multi-Series Bar Chart */}
        {chartData.length > 0 && (
          <Box marginTop={5} marginBottom={4}>
            <ResponsiveContainer width="95%" height={400}> {/* Adjust width as needed */}
              <BarChart
                data={chartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name">
                </XAxis>
                <YAxis>
                  <Label value="Number of Learners" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                </YAxis>
                <Tooltip />
                <Legend />
                <Bar dataKey="Passed" fill="#8BBD8B" />
                <Bar dataKey="Failed" fill="#E74C3C" />
                <Bar dataKey="Skipped" fill="#F1C40F" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ClassPerformanceStats;
