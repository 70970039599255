import React from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const InsertQuestionComponent = ({ index, onCreateQuestion, hideComponents, onOpenQuestionsBank, selectedTopicIds }) => {
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");

  const isAdmin = parsedUserInfo?.user_role === 'admin';

  const handleCreateQuestionClick = () => {
    onCreateQuestion(true, index);
  };

  return (
    <Box mt={2} mb={2} sx={{ '&:hover .hoverVisible': { visibility: 'visible' } }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        {/* Conditionally render Open Question Bank button only if selectedTopicIds.length > 0 */}
        {selectedTopicIds.length > 0 && (
          <Grid item>
            <Box
              onClick={() => onOpenQuestionsBank(selectedTopicIds)}
              className="hoverVisible"
              sx={{ cursor: 'pointer', color: 'grey' }}
            >
              <IconButton color="inherit" size="small">
                <LibraryBooksIcon />
              </IconButton>
              {hideComponents && (
                <Typography variant="caption" color="inherit">
                  Open Question Bank
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        {/* Create Question button for Admins */}
        {isAdmin && (
          <Grid item>
            <Box
              onClick={handleCreateQuestionClick}
              className="hoverVisible"
              sx={{ cursor: 'pointer', color: 'grey' }}
            >
              <IconButton color="inherit" size="small">
                <AddIcon />
              </IconButton>
              {hideComponents && (
                <Typography variant="caption" color="inherit">
                  Create Question
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default InsertQuestionComponent;
