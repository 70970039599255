import React, { useState } from 'react';
import './testimonials.css';
import teacher1 from '../../../assets/teacher7.jpg';
import teacher2 from '../../../assets/teacher9.jpg';
import teacher3 from '../../../assets/teacher1.jpg';

const testimonials = [
  {
    name: 'Jamie Ellman',
    image: teacher1, // Replace with actual path to the image
    quote: 'I used to spend 10 hours a week marking. Now, my marking is accurately done by Educase, and I’m stress-free!',
    subject: 'High School Teacher'
  },
  {
    name: 'Kaya Jones',
    image: teacher2,
    quote: 'My class was struggling with 2D shapes, but with Educase’s detailed reports, I could see exactly where they were having trouble.',
    subject: 'Mathematics Teacher'
  },
  {
    name: 'Sarah Johnson',
    image: teacher3,
    quote: 'Creating question papers used to be hard. Now with Educase, I can easily generate questions on topics my students struggled with most.',
    subject: 'Grade 7 Teacher'
  }
];

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-heading">Here's what other teachers say about Educase</h2>
      <div className="testimonials-arrow left-arrow" onClick={prevTestimonial}>
        &#8249;
      </div>
      <div className="testimonial">
        <div className="polaroid-frame">
          <div className="polaroid-tape"></div>
          <img src={testimonials[currentTestimonial].image} alt={testimonials[currentTestimonial].name} className="testimonial-image" />
        </div>
        <div className="polaroid-frame-bottom">
          <h4 className="polaroid-name">{testimonials[currentTestimonial].name}</h4>
        </div>
        <p className="testimonial-quote">"{testimonials[currentTestimonial].quote}"</p>
        <p className="testimonial-subject">{testimonials[currentTestimonial].subject}</p>
      </div>
      <div className="testimonials-arrow right-arrow" onClick={nextTestimonial}>
        &#8250;
      </div>
    </div>
  );
};

export default Testimonials;
