import React from "react";
import "./TermsOfService.css";
import Header from "../../components/landingPage/header/header";
import Footer from "../../components/landingPage/footer/footer";

const TermsOfService = () => {
    return (
        <div className="tos-terms-of-service">
            <Header className="tos-section-spacing" />
            <div className="tos-terms-content">
                <div className="tos-terms-header">
                    <h1 className="tos-terms-title">Terms of Service</h1>
                    <p className="tos-terms-updated">Updated September 30, 2024</p>
                </div>

                <p className="tos-terms-p">
                    From everyone at Educase Tools, thank you for using our products! We build them to help you empower
                    teachers and students. Thousands of educators and students use Educase Tools every day. Since we
                    don't know all our users personally, we have to put some Terms of Service in place to maintain smooth
                    operations.
                </p>

                <h2 className="tos-terms-h2">Account Terms</h2>

                <ul className="tos-terms-ul">
                    <li className="tos-terms-li">You are responsible for maintaining the security of your account and password...</li>
                    <li className="tos-terms-li">You may not use the Services for any restricted purposes outlined in our Use Restrictions Policy...</li>
                </ul>

                <h2 className="tos-terms-h2">Payment, Refunds, and Plan Changes</h2>

                <ul className="tos-terms-ul">
                    <li className="tos-terms-li">If you are using a free version of our Services, it is truly free...</li>
                    <li className="tos-terms-li">For paid Services with a free trial, we will specify the trial length during sign-up...</li>
                </ul>

                <h2 className="tos-terms-h2">Cancellation and Termination</h2>

                <ul className="tos-terms-ul">
                    <li className="tos-terms-li">You are solely responsible for canceling your account...</li>
                    <li className="tos-terms-li">Upon account cancellation, your content will be immediately inaccessible...</li>
                </ul>

                <h2 className="tos-terms-h2">Modifications to Service and Pricing</h2>

                <p className="tos-terms-p">
                    Sometimes we modify pricing structures. Existing customers are generally exempt from these changes...
                </p>

                <h2 className="tos-terms-h2">Uptime, Security, and Privacy</h2>

                <ul className="tos-terms-ul">
                    <li className="tos-terms-li">Your use of the Services is at your own risk. Services are provided "as is" and "as available."</li>
                    <li className="tos-terms-li">We reserve the right to temporarily disable your account if your usage exceeds average usage...</li>
                </ul>

                <h2 className="tos-terms-h2">Copyright and Content Ownership</h2>

                <ul className="tos-terms-ul">
                    <li className="tos-terms-li">All content posted must comply with Canadian copyright law.</li>
                    <li className="tos-terms-li">You retain ownership of your content...</li>
                </ul>

                <h2 className="tos-terms-h2">Features and Bugs</h2>

                <p className="tos-terms-p">
                    We design our Services with care, based on our own experience and feedback from our users...
                </p>

                <h3 className="tos-terms-h3">Restricted purposes</h3>

                <ul className="tos-terms-ul">
                    <li className="tos-terms-li">Violence, or threats thereof: If an activity qualifies as violent crime in Canada...</li>
                    <li className="tos-terms-li">Child exploitation, sexualization, or abuse: We don’t tolerate any activities that create...</li>
                </ul>

                <h3 className="tos-terms-h3">Third-Party Services</h3>

                <p className="tos-terms-p">
                    Educase Tools uses the YouTube API Services. By using our product, you are also agreeing to be bound by the YouTube Terms of Service.
                </p>

                <Footer />
            </div>
        </div>
    );
};

export default TermsOfService;
