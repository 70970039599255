import React, { useState } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
import AssessmentAPIService from "../../services/api/AssessmentAPIService";

const CreateAnnouncement = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaType, setMediaType] = useState('');

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!validTypes.includes(file.type)) {
                alert('Only JPEG, JPG, PNG, and GIF files are allowed.');
                return;
            }

            if (file.size > maxSize) {
                alert('File size should be less than 5MB.');
                return;
            }

            setMediaFile(file);
        }
    };

    const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !description) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Title and description are required!',
        });
        return;
    }

    const announcementData = {
        title,
        description,
        media_file: mediaFile, // Ensure this matches what your backend expects
    };

    try {
        const response = await AssessmentAPIService.createAnnouncement(announcementData, token);
        if (response && response.id) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Announcement created successfully!',
            });
            setTitle('');
            setDescription('');
            setMediaFile(null);
            setMediaType('');
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to create announcement.',
            });
        }
    } catch (error) {
        console.error('Error creating announcement:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while creating the announcement.',
        });
    }
};


    return (
        <Box className="create-announcement-container" sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h4" gutterBottom>
                Create Announcement
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="media-type-label">Media Type</InputLabel>
                    <Select
                        labelId="media-type-label"
                        id="media-type"
                        value={mediaType}
                        label="Media Type"
                        onChange={(e) => setMediaType(e.target.value)}
                    >
                        <MenuItem value="image">Image</MenuItem>
                        <MenuItem value="gif">GIF</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    component="label"
                    sx={{ margin: '10px 0' }}
                >
                    Upload {mediaType === 'gif' ? 'GIF' : 'Image'}
                    <input
                        type="file"
                        hidden
                        accept="image/jpeg, image/png, image/gif"
                        onChange={handleFileChange}
                    />
                </Button>
                {mediaFile && (
                    <Typography variant="body2">
                        Selected File: {mediaFile.name}
                    </Typography>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    fullWidth
                    sx={{ marginTop: '20px' }}
                >
                    Create Announcement
                </Button>
            </form>
        </Box>
    );
};

export default CreateAnnouncement;
