import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "katex/dist/katex.min.css";
import "mhchemparser/dist/mhchemParser";
import katex from "katex";
import { useSelector } from "react-redux";
import ImageUploader from "quill-image-uploader";
import PropTypes from "prop-types";

window.katex = katex;
Quill.register("modules/imageUploader", ImageUploader);

const modules = {
    toolbar: [
        [{ font: ['Times New Roman'] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image"],
        ["clean"],
        ["formula"]
    ],

    imageUploader: {
        upload: (file, subject, grade) => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                const hashedFileName = `educase_${generateRandomHash()}_${subject}_${grade}_${file.name}`;
                formData.append("image", file, hashedFileName);
                fetch(`https://educase_worker.educasetools.workers.dev/${hashedFileName}`, { // Append file name to the URL
                    method: "POST",
                    headers: {
                        'X-Custom-Auth-Key': 'e6443de7-07b1-40f3-b7f1-0f3a531bc43f'
                    },
                    body: formData
                }).then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.text(); // Use .text() to get the raw response body
                }).then((text) => {
                    try {
                        const result = JSON.parse(text); // Manually parse the text into JSON
                        resolve(result.url);
                    } catch (error) {
                        reject("Failed to parse JSON");
                    }
                })
                    .catch((error) => {
                        reject("Upload failed");
                    });
            });
        }
    }
};

function generateRandomHash() {
    const arr = new Uint8Array(8); // generate 8 bytes of random data
    window.crypto.getRandomValues(arr); // populate array with random values
    return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join('').substring(0, 16); // convert to hex and join to make a string
}

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "formula",
    "clean",
    "strike",
    "blockquote",
    "image",
    "table"

];
const EditorComponent = ({ initialData, onChangeReference, index, subIndex, placeholder, theme }) => {
    const [editorContents, setEditorContents] = useState(initialData);
    const assessment = useSelector(state => state.assessment);
    const quillRef = useRef(null);

    function insertToEditor(url) {
        const range = quillRef.current.getEditor().getSelection();
        if (range) {
            quillRef.current.getEditor().insertEmbed(range.index, 'image', url);
        }
    }

    useEffect(() => {
                 function imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                try {
                    const url = await modules.imageUploader.upload(file, assessment.subject_name, assessment.grade);
                    insertToEditor(url);
                } catch (error) {
                    console.error('Error uploading image: ', error);
                }
            }
        };
    }
        if (typeof Quill !== 'undefined' && quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', imageHandler);
        }
    }, [assessment.grade, assessment.subject_name]);


    return (
        <div>
            <div className="react-quill">
                <ReactQuill
                    theme={theme || "bubble"}
                    ref={quillRef}
                    modules={modules}
                    formats={formats}
                    placeholder={placeholder}
                    value={editorContents}
                    bounds={'.react-quill'}
                    onChange={(content, delta, source, editor) => {
                        const html = editor.getHTML();
                        setEditorContents(html);
                        onChangeReference(html, index, subIndex)
                    }}
                />
            </div>
        </div>
    );
};

EditorComponent.propTypes = {
    initialData: PropTypes.string,
    onChangeReference: PropTypes.func.isRequired,
    index: PropTypes.number,
    subIndex: PropTypes.number,
    placeholder: PropTypes.string,
    theme: PropTypes.string, // Optional prop type for theme
};
export default EditorComponent;