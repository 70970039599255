import React from 'react';
import './testingProcessValue.css';
import iconWorkflow from '../../../assets/exam-kid.webp';
import iconRealTimeInsights from '../../../assets/analysis.jpg';
import iconPersonalizedTesting from '../../../assets/handwritten.webp';
import iconDataDriven from '../../../assets/report (2).png';
import useWindowSize from './useWindowSize';

const testingProcessContent = {
  AssessmentForLearning: {
    title: "Assessment for Learning",
    description:
      "Educase works hand-in-hand with teachers to simplify testing. Personalized tests or pre-made papers make assessments more efficient, while AI ensures accurate marking.",
    imgSrc: iconWorkflow,
  },
  RealTimeInsights: {
    title: "Get Real-Time Insights",
    description:
      "Teachers get a complete view of learner progress, with clear metrics that highlight strengths and areas for improvement.",
    imgSrc: iconRealTimeInsights,
  },
  PersonalizedTesting: {
    title: "Deliver Personalized Testing",
    description:
      "Educase allows teachers to focus on individual learner needs, offering customized assessments and tailored feedback without increasing workload.",
    imgSrc: iconPersonalizedTesting,
  },
  DataDrivenDecisions: {
    title: "Make Data-Driven Decisions",
    description:
      "Track progress over time with Educase. Leverage insights to make informed decisions, ensuring that every student meets their learning goals.",
    imgSrc: iconDataDriven,
  },
};

const TestingProcessValue = () => {
  const size = useWindowSize();

  const renderGridContent = () => (
    <div className="testing-process-grid">
      {Object.values(testingProcessContent).map((process, index) => (
        <div className="process-card" key={index} style={{ backgroundColor: process.bgColor }}>
          <img src={process.imgSrc} alt={process.title} className="process-icon" />
          <h3>{process.title}</h3>
          <p>{process.description}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="testing-process-container">

      {/* Conditionally render the heading based on screen size */}
      {size.width > 750 && (
        <h2 className="testing-process-heading">Educase: Smarter Testing Solutions</h2>
      )}

      {/* Only render grid content for larger screens */}
      {size.width > 750 && renderGridContent()}

      {/* Always render the stats section */}
      <div className="testing-stats-container">
        <h3>Reduce Teacher Stress with Educase</h3>
        <div className="stats-grid">
          <div className="stat">
            <h4>10 hours</h4>
            <p>Saved on marking each week</p>
          </div>
          <div className="stat">
            <h4>50%</h4>
            <p>Less stress for teachers</p>
          </div>
          <div className="stat">
            <h4>35%</h4>
            <p>Increase in teacher productivity</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingProcessValue;
