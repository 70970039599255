import React, { useEffect, useState } from "react";
import AssessmentTable from "../../components/assessmentTable/assessmentTable";
import "./ExamTestsScreen.css";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AssessmentAPIService from "../../services/api/AssessmentAPIService";
import { useDispatch } from "react-redux";
import { setIsCreateQuestionVisible } from "../../reducers/componentStates";

const ExamTestScreen = () => {
    const [assessmentData, setAssessmentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

 useEffect(() => {
    const fetchData = async () => {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;

        const res = await AssessmentAPIService.shallowFetchAssessments(token);

        if (res) {
            // Sort the assessments by last_updated (most recent first)
            const sortedAssessments = res.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));

            setAssessmentData(sortedAssessments);
            setLoading(false);
        } else {
            console.error("Error fetching assessment data.");
        }
    };
    fetchData();
    dispatch(setIsCreateQuestionVisible(true));
}, []); // Sort by last_updated on component load
// Re-fetch when query params change

    return (
        <div className="exam-test-container">
            {loading && (
                <div className="loading-overlay">
                    <CircularProgress />
                </div>
            )}
            <div className="exam-test-container">
                <Box className="overview-screen-header">
                    <div className="overview-screen-header-content">
                        <div className="overview-screen-right-section">
                        </div>
                    </div>
                </Box>
                <AssessmentTable
                        assessments={assessmentData}
                        setAssessments={setAssessmentData}
                    />
            </div>
        </div>
    );
};

export default ExamTestScreen;
