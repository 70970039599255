import React, { useState } from "react";
import {
    Avatar,
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Swal from "sweetalert2";
import "./header.css";
import getConfig from "../../config";
import {
    setDownloadAssessmentPaper,
    setMobileIsNavBarVisible,
    setShowMemo,
} from "../../reducers/componentStates";
import DownloadIOutlinedcon from "@mui/icons-material/DownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token, profilePic, user_role } = parsedUserInfo;
    const { isCreateQuestionVisible, showMemo } = useSelector(state => state.componentStates);
    const isAssessmentPaperButtonsVisible = useSelector(state => state.componentStates.isAssessmentPaperButtonsVisible);
    const [isMobile] = useState(window.innerWidth <= 700);
    const assessmentState = useSelector((state) => state.assessment);

    const handleHomeClick = () => {
        dispatch(setMobileIsNavBarVisible(true));
    };
    const handleLogout = async () => {
        try {
            const config = getConfig();
            const response = await fetch(`${config.api.base_url}/logout/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });
            if (response.ok) {
                navigate('/auth/login');
            } else {
                await Toast.fire({
                    icon: "error",
                    title: "Logout failed, please try again.",
                });
            }
        } catch (error) {
            await Toast.fire({
                icon: "error",
                title: "An error occurred. Please try again later.",
            });
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const showMemoToggle = () => {
        // Toggle the showMemo state between true and false
        dispatch(setShowMemo(!showMemo));
    };

    const handleSaveAssessmentPaper = async () => {
        const selectedQuestions = assessmentState.assessmentPaper;

        if (selectedQuestions.length === 0) {
            await Toast.fire({
                icon: "error",
                title: "Please add at least one Question.",
            });
            return false;
        }

        try {
            const userInfo = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfo || "{}");
            const { token } = parsedUserInfo;

            if (!token) {
                navigate("/signin");
                return false;
            }

            const today = new Date().toISOString().split('T')[0];
            const assessmentDate = assessmentState.assessment_date ? assessmentState.assessment_date : today;

            const payload = {
                name: assessmentState.name,
                type: assessmentState.type,
                assessment_date: assessmentDate,
                duration: "12:00",
                instructions: assessmentState.instructions,
                status: "draft",
                time: assessmentState.time,
                total_marks: assessmentState.total_marks,
                subject: assessmentState.subject,
                grade: assessmentState.grade,
                questions: selectedQuestions.map((question) => question.id),
            };
            const config = getConfig();

            const apiEndpoint = assessmentState.id
                ? `${config.api.base_url}/exam-papers/${assessmentState.id}/update_exam/`
                : `${config.api.base_url}/exam-papers/`;
            const httpMethod = assessmentState.id ? "PATCH" : "POST";

            const response = await fetch(apiEndpoint, {
                method: httpMethod,
                body: JSON.stringify(payload),
                headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
            });

            if (!response.ok) {
                const res = await response.json();
                await Toast.fire({
                    icon: "error",
                    title: res.error || "Failed to save exam paper.",
                });
                return false;
            }

            const successMessage = assessmentState.id ? "Assessment successfully updated" : "Assessment successfully created";
            await Toast.fire({
                icon: "success",
                title: successMessage,
            });

            navigate(`/assessments`, { replace: true });
            return true;
        } catch (error) {
            console.error(error);
            await Toast.fire({
                icon: "error",
                title: "An error occurred while saving the assessment.",
            });
            return false;
        }
    };

    return (
        <header className="app-header">
            <div className="header-content">
                <div className="header-left">
                    {isMobile && (
                        <IconButton className="home-icon" onClick={handleHomeClick}>
                            <HomeIcon />
                        </IconButton>
                    )}
                </div>

                <div className="header-right">
                    {isAssessmentPaperButtonsVisible && (
    <div className="paper-options">
        {/* Save Button */}
        <Button onClick={handleSaveAssessmentPaper} startIcon={<SaveOutlinedIcon />}>
            Save
        </Button>

        {/* Download Button */}
        <Button
            onClick={async () => {
                const saveSuccess = await handleSaveAssessmentPaper();
                if (saveSuccess) {
                    dispatch(setDownloadAssessmentPaper(true));
                }
            }}
            startIcon={<DownloadIOutlinedcon />}
        >
            Download
        </Button>


        {/* Toggle Memo Button */}
        <Button
            onClick={showMemoToggle}
            startIcon={showMemo ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        >
            {showMemo ? 'Hide Memo' : 'View Memo'}
        </Button>
    </div>
)}


                            {/* Notifications Icon */}
                            <Tooltip title="Notifications">
                                <IconButton className="header-icon">
                                    <NotificationsIcon />
                                </IconButton>
                            </Tooltip>

                            {/* Profile Avatar */}
                            <Avatar
                                src={profilePic}
                                alt="Profile Picture"
                                className="header-avatar"
                                onClick={handleProfileMenuOpen} // Open profile menu on click
                            />

                            {/* Profile Dropdown Menu */}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleProfileMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                               <MenuList>
    <Link to="/profile" style={{ color: "#4a606e", textDecoration: "none" }}>
        <MenuItem onClick={handleProfileMenuClose}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
        </MenuItem>
    </Link>

    {/* Manage Subjects & Topics should remain restricted to admin, but Manage Courses is accessible to all */}
    {user_role === "admin" && (
        <Link to="/manage-subjects-topics" style={{ color: "#4a606e", textDecoration: "none" }}>
            <MenuItem onClick={handleProfileMenuClose}>
                <ListItemIcon>
                    <SchoolIcon />
                </ListItemIcon>
                <ListItemText>Manage Subjects & Topics</ListItemText>
            </MenuItem>
        </Link>
    )}

    {/* Manage Courses should be accessible to all users */}
    <Link to="/manage-courses" style={{ color: "#4a606e", textDecoration: "none" }}>
        <MenuItem onClick={handleProfileMenuClose}>
            <ListItemIcon>
                <SchoolIcon />
            </ListItemIcon>
            <ListItemText className={"marking-project-title"}>Manage Classes and Learners</ListItemText>
        </MenuItem>
    </Link>

    {user_role === "admin" && (
        <Link to="/add-announcement" style={{ color: "#4a606e", textDecoration: "none" }}>
            <MenuItem onClick={handleProfileMenuClose}>
                <ListItemIcon>
                    <AnnouncementIcon />
                </ListItemIcon>
                <ListItemText>Add Announcement</ListItemText>
            </MenuItem>
        </Link>
    )}

    <Divider style={{ margin: '10px 0' }} />
    <MenuItem onClick={() => { handleProfileMenuClose(); handleLogout(); }}>
        <ListItemIcon>
            <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText>Sign out</ListItemText>
    </MenuItem>
</MenuList>
                            </Menu>
                </div>
            </div>
        </header>
    );
};

export default Header;
