import React, { useState, useEffect } from 'react';
import './heroSection.css';
import heroImageDesktop from '../../../assets/hero.png';  // Desktop image
import heroImageMobile from '../../../assets/hero-mobile.png';  // Mobile image
import { Link } from "react-router-dom";

const HeroSection = ({ scrollToCalendly }) => {
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700); // Mobile check
  const typingSpeed = 100;
  const pauseBetweenPhrases = 1000;

  // Handle screen resize to switch between mobile and desktop images
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 700);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const phrases = [
    "endless marking hours",
    "feeling overworked",
    "sacrificing your time"
  ];
    const typeTimeout = setTimeout(() => {
      if (currentCharIndex < phrases[currentIndex].length) {
        setCurrentPhrase((prev) => prev + phrases[currentIndex].charAt(currentCharIndex));
        setCurrentCharIndex(currentCharIndex + 1);
      } else {
        setTimeout(() => {
          setCurrentPhrase('');
          setCurrentCharIndex(0);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, pauseBetweenPhrases);
      }
    }, typingSpeed);

    return () => clearTimeout(typeTimeout);
  }, [currentCharIndex, currentIndex]);

  return (
    <div className="hero-container">
      <div className="hero-content-left">
        <div className="hero-title">Unlock personalized testing</div>
        <div className="hero-title">
          <br />
          <span className="without">without</span>
          <br />
          <span className="highlight">{currentPhrase}</span>
        </div>
        <p className='hero-section-p'>
          Teachers create personalized tests with ease, and we handle the heavy work of marking—online and handwritten tests included.
        </p>

        <div className="cta-buttons-centered">
          <Link to="/auth/registration" className="cta-button">
            Try for free
          </Link>
          <button className="cta-button">Talk to sales</button>
        </div>
      </div>

      {/* Hero image */}
      <div className="hero-image-container">
        <img
          src={isMobile ? heroImageMobile : heroImageDesktop} // Dynamic image change
          alt="Hero"
          className="hero-image"
        />
      </div>
    </div>
  );
};

export default HeroSection;
