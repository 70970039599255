import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import "./TestResults.css";
import CourseAssessmentResultsApiService from "../../../services/api/CourseAssessmentResultsApiService";

const TestResults = () => {
    const [testResults, setTestResults] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const navigate = useNavigate();
    const { courseId } = useParams();

    // Detect screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Fetch the course assessment results when the component loads
    useEffect(() => {
        const fetchTestResults = async () => {
            try {
                const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
                const token = userInfo.token;

                // Fetch the assessments for this course
                const response = await CourseAssessmentResultsApiService.getCourseAssessmentResultsByCourse(courseId, token);
                setTestResults(response);
            } catch (error) {
                console.error("Error fetching test results:", error);
            }
        };

        fetchTestResults();
    }, [courseId]);

    // Handle row click event to navigate to test results for the selected course
    const handleRowClick = (params) => {
        const cAId = params.row.id;
        navigate(`/stats/${cAId}`);
    };

    // DataGrid column definitions for mobile and desktop
    const columns = [
        {
            field: "exam_name",
            headerName: "Assessment Name",
            flex: 2,
            align: "left",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", flexDirection: "column", width: "100%", padding: '5px 0' }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{params.row.exam_name}</span>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <div style={{ fontSize: '12px' }}>Average: {params.row.status === "Marking" ? "Not Available" : `${params.row.average_mark}%`}</div>
                        <div style={{ color: 'gray', fontSize: '12px' }}>
                            Passed: {params.row.status === "Marking" ? "Not Available" : params.row.passed_learners} |
                            Failed: {params.row.status === "Marking" ? "Not Available" : params.row.failed_learners} |
                            Missed: {params.row.status === "Marking" ? "Not Available" : params.row.total_learners - params.row.total_submitted}
                        </div>
                    </div>
                </div>
            ),
            valueGetter: (params) => params.row.exam_name,
        },
        {
            field: "status_display",
            headerName: "Status",
            flex: 1,
            align: "left",
            headerClassName: "centered-header",
        },
    ];

    // Filter test results based on search input
    const filteredTestResults = testResults
        .filter(
            (result) =>
                result.exam_name.toLowerCase().includes(searchText.toLowerCase()) ||
                result.average_mark.toString().includes(searchText.toLowerCase())
        );

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "20px",
                flexDirection: "column",
                gap: "20px",
            }}>
                <TextField
                    id="search"
                    placeholder="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        style: { width: "200px", borderRadius: 25 },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            borderColor: "#d0d4d9",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d4d9",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#b3b3b3",
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />

                <div style={{ height: 300, width: "100%" }}>
                    <DataGrid
                        rows={filteredTestResults}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        onRowClick={handleRowClick}
                        rowHeight={80}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: '10px',
                            boxShadow: 0,
                            border: "1px solid #d0d4d9",
                            [`& .${"MuiDataGrid-cell"}:focus-within`]: {
                                outline: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "1px solid #d0d4d9",
                                padding: "10px 15px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#f3f7f8",
                                borderBottom: "1px solid #d0d4d9",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                color: "#555f67",
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator--sideRight, & .MuiDataGrid-iconButtonContainer": {
                                display: "none",
                            },
                            "& .MuiDataGrid-sortIcon": {
                                display: "none",
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#dce7ea",
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default TestResults;
