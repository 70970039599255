import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import EditorComponent from "../../../components/Common/Quill/quillEditor";
import DisplayContent from "../../../components/displayContent/displayContent";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const LearnerResultHeader = ({ firstName, lastName, mark, assessmentName, subject, grade, rank, totalLearners, comment: initialComment, classAverage, passMark, onlineTestId }) => {
  const [openAIAnalysisModal, setOpenAIAnalysisModal] = useState(false);
  const [editing, setEditing] = useState({ comments: false });
  const [comment, setComment] = useState(initialComment || ""); // Store AI Analysis comments
  const [editedComments, setEditedComments] = useState(comment);

  // Retrieve user role and token
  const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
  const userRole = userInfo.user_role || '';
  const token = userInfo.token || ''; // Assuming the token is available in userInfo

  // Function to get initials for the avatar
  const getInitials = () => {
    if (firstName && lastName) {
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    }
    return "N/A";
  };

  // Function to format the rank with both emoji and ordinal suffix
  const formatRank = (rank) => {
    let rankSuffix;
    let emoji;

    switch (rank) {
      case 1:
        rankSuffix = "st";
        emoji = "🥇"; // Gold Medal
        break;
      case 2:
        rankSuffix = "nd";
        emoji = "🥈"; // Silver Medal
        break;
      case 3:
        rankSuffix = "rd";
        emoji = "🥉"; // Bronze Medal
        break;
      default:
        rankSuffix = "th";
        emoji = "🏅"; // General medal emoji
        break;
    }

    return `${rank}${rankSuffix} ${emoji}`;
  };

  // Indicate if the learner has failed
  const hasFailed = mark < passMark;

  // Handle saving of edited AI Analysis
  const handleSave = async () => {
    try {
      // Call the API to update AI Analysis comments
      await OnlineTestApiService.updateCommentsAndRecommendations(onlineTestId, editedComments, null, token);
      setComment(editedComments); // Update state with new comments
      setEditing({ ...editing, comments: false });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <>
      <Card>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 2, marginRight: 2 }}>
          {/* Header Information */}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingBottom: 2 }}>
            <Avatar sx={{ width: 56, height: 56, bgcolor: "#5F9EA0", marginRight: 2 }}>
              {getInitials()}
            </Avatar>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5">{`${firstName} ${lastName}`}</Typography>
              <Typography variant="body1">
                Mark: {mark}% {hasFailed && <span style={{ color: 'red' }}>(Failed)</span>}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Class Average: {classAverage}%
              </Typography>
              {/* AI Analysis link */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'primary.main' }}
                  onClick={() => setOpenAIAnalysisModal(true)}
                >
                  <AutoAwesomeIcon sx={{ marginRight: 0.5, color: 'primary.main' }} />
                  AI Analysis
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginLeft: 'auto', textAlign: 'right' }}>
              <Typography variant="h6">
                Class Rank: {formatRank(rank)} {`(out of ${totalLearners})`}
              </Typography>
              {/* Assessment, Subject, and Grade */}
              <Typography variant="body2">{`Assessment: ${assessmentName || 'N/A'}`}</Typography>
              <Typography variant="body2">{`Subject: ${subject || 'N/A'}`}</Typography>
              <Typography variant="body2">{`Grade: ${grade || 'N/A'}`}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* AI Analysis Modal */}
      <Dialog open={openAIAnalysisModal} onClose={() => setOpenAIAnalysisModal(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          AI Analysis
          {userRole === "admin" && !editing.comments && (
            <Button
              sx={{ marginLeft: 2 }}
              onClick={() => setEditing({ ...editing, comments: true })}
              color="primary"
              size="small"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          {editing.comments ? (
            <EditorComponent
              initialData={editedComments}
              onChangeReference={(value) => setEditedComments(value)}
              placeholder="Edit AI Analysis here"
            />
          ) : (
            comment ? (
              <DisplayContent content={comment} /> // Render AI Analysis using DisplayContent
            ) : (
              <Typography>No AI Analysis available.</Typography> // Show message when no analysis is available
            )
          )}
        </DialogContent>
        <DialogActions>
          {editing.comments ? (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          ) : (
            <Button onClick={() => setOpenAIAnalysisModal(false)} color="primary">
              Close
            </Button>
          )}
          {/* Close Button to explicitly close the modal */}
          <Button onClick={() => setOpenAIAnalysisModal(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LearnerResultHeader;
