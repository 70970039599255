import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import { TextField, Typography, Button, Box, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import getConfig from "../../config/index";
import { OnlineTestApiService } from "../../services/api/OnlineTestAPIService";
import CourseAssessmentResultsApiService from "../../services/api/CourseAssessmentResultsApiService";

const CopyTestLinkDialog = ({ open, onClose, selectedAssessmentId, parsedUserInfo }) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [editableEmail, setEditableEmail] = useState(parsedUserInfo.email);
  const [passPercentage, setPassPercentage] = useState(40);
  const [selectedCourse, setSelectedCourse] = useState(""); // State for single course selection
  const [markingInstructions, setMarkingInstructions] = useState("");
  const [markingObjectives, setMarkingObjectives] = useState("");
  const [courses, setCourses] = useState([]);  // State to hold the fetched courses
  const SECRET_KEY = "your-secret-key";
  const { token } = parsedUserInfo;

  // Fetch courses when the dialog opens or when the selectedAssessmentId changes
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (selectedAssessmentId) {
          const coursesData = await OnlineTestApiService.fetchCoursesByExam(selectedAssessmentId, token);
          if (coursesData) {
            setCourses(coursesData);
          }
        }
      } catch (error) {
        console.error("Failed to fetch courses", error);
        Swal.fire({
          icon: "error",
          title: "Failed to fetch courses",
        });
      }
    };

    fetchCourses();
  }, [selectedAssessmentId]);

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  };

const handleCopyLink = async () => {
  const config = getConfig();
  const selectedAssessment = selectedAssessmentId;

  const resultsData = {
    course: selectedCourse,
    exam_paper: selectedAssessment,
    assessment_date: expiryDate,
    pass_mark: passPercentage,
    instructions: markingInstructions,
    marking_objectives: markingObjectives
  };

  try {
    // Call the API to create the course assessment results
    await CourseAssessmentResultsApiService.createCourseAssessmentResults(resultsData, token);

    // Encrypt data for the test link
    const encryptedEmail = encryptData(editableEmail);
    const encryptedExpiryDateTime = encryptData(`${expiryDate}T${expiryTime}`);
    const encryptedPassPercentage = encryptData(passPercentage);

    // Generate the link with encrypted data
    const linkWithExpiry = `${config.api.fe_base_url}/test-welcome-page?assessment_id=${selectedAssessment}&expiry=${encodeURIComponent(
      encryptedExpiryDateTime
    )}&teacher=${encodeURIComponent(encryptedEmail)}&passPercentage=${encodeURIComponent(encryptedPassPercentage)}`;

    // Try to use the Clipboard API to copy the link
    try {
      await navigator.clipboard.writeText(linkWithExpiry);
      onClose(); // This will close the dialog

      await Swal.fire({
        icon: "success",
        title: "Test link copied to clipboard",
      });
    } catch (err) {
      console.warn("Clipboard API not supported, falling back to manual copy");

      const textArea = document.createElement("textarea");
      textArea.value = linkWithExpiry;
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      onClose(); // This will close the dialog


      try {
        document.execCommand('copy');
        await Swal.fire({
          icon: "success",
          title: "Test link copied to clipboard",
        });
      } catch (err) {
        console.error("Fallback copy to clipboard failed", err);
        await Swal.fire({
          icon: "error",
          title: "Failed to copy test link",
        });
      }

      document.body.removeChild(textArea);
    }

  } catch (error) {
    console.error("Error creating classes assessment or copying link:", error);
    await Swal.fire({
      icon: "error",
      title: "Failed to create classes assessment or copy test link",
    });
  }
};


  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);  // Set single course
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" PaperProps={{
      style: {
        border: "1px solid #5f9ea0",
        borderRadius: "8px",
        boxShadow: "none"
      }
    }}>
      <div style={{ backgroundColor: '#f1f3f5', padding: '15px 20px' }}> {/* Grey background */}
        <Typography variant="h6" style={{ textAlign: "center", color: '#5f9ea0', fontWeight: 'bold' }}>
          Copy Test Link
        </Typography>
      </div>
      <Divider /> {/* Divider where the background ends */}

      <DialogContent>
        <Typography variant="body1" style={{ marginBottom: '15px', color: '#6995a7' }}>
          Share this link with your learners. They will upload their answers, and Educase will notify you when the marks are ready.
        </Typography>

        {/* Input fields */}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

          {/* Course Selection */}
          <Box>
            <FormControl fullWidth>
              <InputLabel>Classes<span style={{ color: 'red' }}>*</span></InputLabel>
              <Select
                value={selectedCourse}  // Single value, not array
                onChange={handleCourseChange}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Expiry Date<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="date"
              fullWidth
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Expiry Time<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="time"
              fullWidth
              value={expiryTime}
              onChange={(e) => setExpiryTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Teacher's Email<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="email"
              fullWidth
              value={editableEmail}
              onChange={(e) => setEditableEmail(e.target.value)}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Pass Percentage<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="text"
              fullWidth
              value={`${passPercentage}%`}
              onChange={(e) => setPassPercentage(e.target.value.replace('%', ''))}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          {/* Marking Instructions */}
          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Marking Instructions
            </Typography>
            <TextField
              type="text"
              multiline
              rows={3}
              fullWidth
              value={markingInstructions}
              onChange={(e) => setMarkingInstructions(e.target.value)}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          {/* Marking Objectives */}
          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Marking Objectives
            </Typography>
            <TextField
              type="text"
              multiline
              rows={3}
              fullWidth
              value={markingObjectives}
              onChange={(e) => setMarkingObjectives(e.target.value)}
              InputProps={{
                style: {
                  fontSize: '16px',
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', padding: '15px', gap: '10px' }}>
        <Button onClick={onClose} style={{ backgroundColor: '#f5f5f5', color: '#000', borderRadius: '5px', padding: '8px 20px' }}>
          Cancel
        </Button>
        <Button onClick={handleCopyLink} style={{ backgroundColor: '#5f9ea0', color: '#fff', borderRadius: '5px', padding: '8px 20px' }}>
          Copy Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyTestLinkDialog;
