import getConfig from "../../config/index";
const config = getConfig();

export const TopicResultsApiService = {
    // Fetch topic results by ID
    async fetchTopicResultById(id, token) {
        return await fetch(`${config.api.base_url}/topic-results/${id}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            },
        });
    },

    // Update topic results by ID
    async updateTopicResult(id, updatedData, token) {
        return await fetch(`${config.api.base_url}/topic-results/${id}/update/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify(updatedData),
        });
    },

    // Optionally, if you want to delete a topic result
    async deleteTopicResult(id, token) {
        return await fetch(`${config.api.base_url}/topic-results/${id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });
    }
};

export default TopicResultsApiService;
