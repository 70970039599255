import React from "react";
import AIToolCard from "../../../components/AIToolCard/AIToolCard";
import essayTopics from "../../../assets/essay-topics.png";
import shorterTransactionalTopics from "../../../assets/shorter-transactional-text-topics.png";
import poemQuizBuilder from "../../../assets/poem-quiz-builder.png";
import novelQuizBuilder from "../../../assets/novel-quiz-builder.png";
import readingPassage from "../../../assets/reading-passages.png";
import comprehensionQuizBuilder from "../../../assets/comprehension-quiz-builder.png";
import summarizePassage from "../../../assets/summarize-passage.png";
import longerTransactionalText from "../../../assets/longer-transactional-text.png";

import "./EnglishAITools.css";


const EnglishAITools = () => {
  const englishCards = [
    { title: "Essay Topics Ideas", description: "Explore essay topic ideas", image: essayTopics },
    { title: "Longer Transactional Text Topics", description: "Topics for longer transactional texts", image: longerTransactionalText },
    { title: "Shorter Transactional Text Topics", description: "Topics for shorter transactional texts", image: shorterTransactionalTopics },
    { title: "Generate Questions from Passage", description: "Explore reading passages", image: readingPassage },
    { title: "Comprehension Quiz Builder", description: "Create a comprehension quiz", image: comprehensionQuizBuilder },
    { title: "Summarize Passages", description: "Find passages for summarizing", image: summarizePassage },
    { title: "Novel Quiz Builder", description: "Build quizzes based on novels", image: novelQuizBuilder },
    { title: "Poem Quiz Builder", description: "Build quizzes based on poems", image: poemQuizBuilder },
  ];

  return (
    <div className="english-tools-grid">
      {englishCards.map((card, index) => (
        <AIToolCard key={index} title={card.title} description={card.description} image={card.image} />
      ))}
    </div>
  );
};

export default EnglishAITools;
