import React, { useEffect, useState } from "react";
import { Nav, Toast } from "react-bootstrap";
import Question from "../Question/question";
import QuestionApiService from "../../../services/api/questionAPIService";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import "./questionBank.css";

const QuestionBank = ({ questions = [], topicIds, grade, subjectId, addQuestionToAssessmentPaper  }) => {
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [questionData, setQuestionData] = useState([]); // Stores questions fetched from backend
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);
    const [showToast, setShowToast] = useState(false); // Controls Toast visibility

    const style = {
        height: "85vh",
        backgroundColor: "#ffffff",
        transition: "width 0.3s ease-in-out",
        overflowY: "auto",
    };

    const loadMoreQuestions = () => {
        if (hasMore) {
            setCurrentPage(prev => prev + 1);
        } else {
            setShowToast(true);
        }
    };

    const handleRemoveQuestion = (questionId) => {
        setFilteredQuestions(prevQuestions =>
            prevQuestions.filter(question => question.id !== questionId)
        );
    };

    useEffect(() => {
        const questionIdsInPaper = new Set(questions.map(q => q.id));
        const questionsNotInPaper = questionData.filter(q => !questionIdsInPaper.has(q.id));

        setFilteredQuestions(questionsNotInPaper);
        setCurrentCount(questionsNotInPaper.length);
    }, [questions, questionData]);

    useEffect(() => {
        const fetchQuestions = async (page) => {
            if (isLoading || !hasMore) return;

            setIsLoading(true);

            try {
                const response = await QuestionApiService.fetchQuestions(grade, subjectId, topicIds, page);
                if (response.ok) {
                    const fetchedData = await response.json();
                    const newQuestionsNotInPaper = fetchedData.questions.filter(q => !questions.some(ap => ap.id === q.id));

                    setQuestionData(prevQuestions => [...prevQuestions, ...newQuestionsNotInPaper]);
                    setHasMore(fetchedData.has_more);
                    setTotalCount(fetchedData.total_count - questions.length);
                    setCurrentCount(newQuestionsNotInPaper.length);
                } else {
                    console.error("Error fetching questions:", await response.json());
                }
            } catch (error) {
                console.error("Error loading questions:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (currentPage > 0) {
            fetchQuestions(currentPage);
        }
    }, [currentPage, grade, subjectId, topicIds]);

    return (
        <div style={style}>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{ position: 'fixed', top: 20, right: 20 }}
            >
                <Toast.Header>
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>You have reached the end of the list.</Toast.Body>
            </Toast>

            <div>
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '50%' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Nav className="flex-column">
                        {filteredQuestions.length > 0 ? (
                            filteredQuestions.map((question, index) => (
                                <div style={{ borderBottom: '1px solid #ccc', padding: '10px 0' }} key={question.id}>
                                    <Question
                                        question={question}
                                        index={index}
                                        isAddedToAssessment={false}
                                        onRemoveQuestion={handleRemoveQuestion}
                                        showTags={true}
                                        showAddFromQuestionBank={true}
                                        addQuestionToAssessmentPaper={addQuestionToAssessmentPaper}
                                    />
                                </div>
                            ))
                        ) : (
                            <p>No questions available for the topics you selected.</p>
                        )}

                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            <p>{currentCount} of {totalCount} questions</p>
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "100px",
                        }}>
                            <div style={{ textAlign: "center", padding: "20px" }}>
                                {hasMore && !isLoading && (
                                    <Button onClick={loadMoreQuestions}>
                                        Load more questions
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Nav>
                )}
            </div>
        </div>
    );
};

export default QuestionBank;
