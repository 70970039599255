import getConfig from "../../config/index";

export const FeedbackApiService = {

    /**
     * Submit manual feedback for questions, subquestions, or nested subquestions.
     *
     * @param {number} onlineTestId - The ID of the online test.
     * @param {Array} feedbackList - The list of feedback items (type, question_id, and allocated_marks).
     * @param cAId
     * @param answerPdfUrl
     * @param {string} token - The authorization token.
     * @returns {Promise<Object|null>} - The response from the server or null in case of an error.
     */
    async submitManualFeedback(onlineTestId, feedbackList, cAId, answerPdfUrl, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/evaluate-answers/manual-feedback/`;

            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    online_test_id: onlineTestId,
                    feedback_list: feedbackList,
                    course_assessment_id : cAId,
                    answer_pdf_url : answerPdfUrl
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to submit manual feedback:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error submitting manual feedback:', error);
            return null;
        }
    },

    // Other feedback-related methods can be added here.
}
