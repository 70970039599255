import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import LearnerResultHeader from "./LearnerResultHeader"; // Your header component
import LearnerPerformanceTable from "./LearnerPerformanceTable"; // New performance table component
import LearnerPerformanceChart from "./LearnerPerformanceChart"; // New bubble chart component

const LearnerResultAnalysis = ({ learnerData, assessment_name, grade, subject, totalLearners, classAverage, passMark }) => {
  const {
    learner: {
      test_id= null,
      first_name = "Unknown",
      last_name = "Unknown",
      mark = 0,
    } = {},
    topics = [],
    questions = [],
    cognitive_levels = {},
    recommendations = null,
      comment = null,
      rank = null,
  } = learnerData || {};

  const [selectedView, setSelectedView] = useState("questions"); // State to track the selected view type
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '20px' }}>
      {/* Render the header with 100% width */}
      <Box sx={{ width: '100%' }}>
        <LearnerResultHeader
            firstName={first_name}
            lastName={last_name}
            mark={mark} grade={grade}
            subject={subject}
            assessmentName={assessment_name}
            recommendations={recommendations}
            rank={rank}
          comment={comment}
        totalLearners={totalLearners}
        classAverage={classAverage}
        passMark={passMark}
        onlineTestId={test_id}/>
      </Box>

      {/* Main container for table and bubble chart */}
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: 2, alignItems: 'flex-start' }}> {/* Align items to start */}
        {/* Render the performance table with fixed height */}
        <Box sx={{ width: '40%', height: '600px', overflowY: 'auto' }}>
          <LearnerPerformanceTable
            topics={topics}
            questions={questions}
            cognitiveLevels={cognitive_levels}
            onViewChange={setSelectedView} // Pass the setter for selected view
          />
        </Box>

        {/* Render the bubble chart with fixed height */}
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: 'auto' }}>
          {selectedView === "questions" && questions.length > 0 && (
            <>
              <LearnerPerformanceChart data={questions} viewType="questions" />
            </>
          )}
          {selectedView === "cognitiveLevels" && Object.entries(cognitive_levels).length > 0 && (
            <>
              <LearnerPerformanceChart data={Object.values(cognitive_levels)} viewType="cognitiveLevels" />
            </>
          )}
          {selectedView === "topics" && topics.length > 0 && (
            <>
              <LearnerPerformanceChart data={topics} viewType="topics" />
            </>
          )}
        </Box>
      </Box>

      {/* Render recommendations if available */}
      {recommendations && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          Recommendations: {recommendations}
        </Typography>
      )}
    </Box>
  );
};

export default LearnerResultAnalysis;
