import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import CreateInstructions from "../CreateInstructions/createInstructions";
import './Instructions.css';
import DisplayContent from "../../displayContent/displayContent";

const Instructions = ({ instructions, hideComponents }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditInstructionClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  return (
    <div className="paper-instructions-container">
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {isEditing ? (
          <CreateInstructions instructions={instructions} onSave={handleSave} />
        ) : (
          <>
            {instructions && hideComponents && (
              <Grid container justifyContent="flex-end" alignItems="center">
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Button variant="text" onClick={handleEditInstructionClick}>
                    Edit Instructions
                  </Button>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>

      {/* Display instructions using DisplayContent component if not editing and instructions exist */}
      {!isEditing && instructions && (
        <div className="instruction-text-container">
          <DisplayContent content={instructions} /> {/* Use DisplayContent to render instructions */}
        </div>
      )}
    </div>
  );
};

export default Instructions;
