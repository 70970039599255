import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import CourseApiService from "../../../services/api/courseAPIService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EnrollLearnerForm from "./EnrollLearners/EnrollLearnerForm";
import UploadScriptsLearnerTable from "./UploadScriptsLearnerTable/UploadScriptsLearnerTable";
import {OnlineTestApiService} from "../../../services/api/OnlineTestAPIService";

const UploadLearnerScripts = ({ course, assessmentId, onBack }) => {
    const [learners, setLearners] = useState([]);
    const [filteredLearners, setFilteredLearners] = useState([]);
    const [uploadedUrls, setUploadedUrls] = useState({});
    const [loading, setLoading] = useState(true); // Loading state
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;
    const [selectedLearnerId, setSelectedLearnerId] = useState(null);
    const [onlineTestId, setOnlineTestId] = useState("");
    const isInitialMount = useRef(true);




    // Fetch learners enrolled in the course
    const fetchLearners = useCallback(async () => {
        try {
            const response = await CourseApiService.getLearnersWithOnlineTests(course.id, assessmentId, token);
            if (response) {
                setLearners(response);
                setFilteredLearners(response);
                setUploadedUrls((prevUploadedUrls) => {
                    const updatedUrls = { ...prevUploadedUrls };
                    response.forEach((learner) => {
                        if (learner.answer_urls && learner.answer_urls.length > 0) {
                            updatedUrls[learner.id] = learner.answer_urls;
                        } else {
                            updatedUrls[learner.id] = []
                        }
                    });
                    return updatedUrls;
                });
            }
        } catch (error) {
            console.error("Error fetching learners:", error);
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    }, [course.id, assessmentId, token]);

           // Effect to trigger submission only after the initial mount
    useEffect(() => {
        // Skip on initial mount
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        
        const handleSubmitSingleScript = async (learnerId) => {
        try {
            await OnlineTestApiService.submitLearnerScripts(
                learnerId,
                assessmentId,
                uploadedUrls[learnerId],
                onlineTestId,
                token
            );
            console.log(`Successfully submitted script for learner ${learnerId}`);
        } catch (error) {
            console.error(`Error submitting script for learner ${learnerId}:`, error);
        }
    };

        // If not initial mount, proceed with submission for learners with updated URLs
        if (selectedLearnerId && uploadedUrls[selectedLearnerId]?.length > 0) {
            handleSubmitSingleScript(selectedLearnerId);
        }
    }, [uploadedUrls, selectedLearnerId, assessmentId, onlineTestId, token]);


    useEffect(() => {
        fetchLearners()
    }, [fetchLearners, dispatch]); // Only fetch learners when necessary

    // Handle adding a learner to the course
    const handleAddLearnerToCourse = async (newLearner) => {
        const learnerData = {
            first_name: newLearner.firstName,
            last_name: newLearner.lastName,
            grade: course.grade,
            course_id: course.id,
        };

        try {
            const response = await CourseApiService.enrollLearner(learnerData, token);
            if (response?.id) {
                // Update uploadedUrls with the new learner ID
                setUploadedUrls((prevUrls) => ({
                    ...prevUrls,
                    [response.id]: []
                }));
                await fetchLearners()
            }
        } catch (error) {
            console.error("Failed to add learner:", error);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", overflow: "auto", height: "auto", margin: "10px" }}>
            {loading ? (
                // Loading indicator while data is being fetched
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                    <CircularProgress />
                </Box>
            ) : learners.length === 0 ? (
                <EnrollLearnerForm
                    token={token}
                    setUploadedUrls={setUploadedUrls}
                    courseName={course.name}
                    courseId={course.id}
                    fetchLearners={fetchLearners()}
                />
            ) : (
                <UploadScriptsLearnerTable
                    setSelectedLearnerId={setSelectedLearnerId}
                    setOnlineTestId={setOnlineTestId}
                    learners={learners}
                    filteredLearners={filteredLearners}
                    setUploadedUrls={setUploadedUrls}
                    uploadedUrls={uploadedUrls}
                    isSmallScreen={window.innerWidth < 600}
                    courseName={course.name}
                    handleAddLearnerToCourse={handleAddLearnerToCourse}
                    selectedLearnerId={selectedLearnerId}
                />
            )}

            <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center" }}>
                <Button variant="outlined" color="primary" onClick={onBack}>
                    Back
                </Button>
                {learners.length > 0 && (
                    <Button
                        variant="contained"
                        onClick={() => navigate(`/home`)}
                        sx={{ backgroundColor: "#5f9ea0", color: "white" }}
                    >
                        Done uploading
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default UploadLearnerScripts;
