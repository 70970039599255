import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, MenuItem, TextField } from "@mui/material";
import "./ScriptDetails.css";
import CourseApiService from "../../../services/api/courseAPIService";
import AssessmentAPIService from "../../../services/api/AssessmentAPIService";
import CourseAssessmentResultsApiService from "../../../services/api/CourseAssessmentResultsApiService";

const ScriptDetails = ({ courseAssessment, onClose, onUploadLearners }) => {
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(courseAssessment?.exam_paper || '');
  const [instructions, setInstructions] = useState(courseAssessment?.instructions || '');
  const [markingObjectives, setMarkingObjectives] = useState(courseAssessment?.marking_objectives || '');
  const [courses, setCourses] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [passMark, setPassMark] = useState(courseAssessment?.pass_mark || 40);
  const todayDate = new Date().toISOString().split("T")[0];
  const [assessmentDate, setAssessmentDate] = useState(
    courseAssessment?.assessment_date
      ? new Date(courseAssessment.assessment_date).toISOString().split("T")[0]
      : todayDate  // Default to today's date
  );

  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;

  // Fetch exams based on the course ID
  const fetchExamsForCourse = async (courseId) => {
    try {
      const response = await AssessmentAPIService.fetchExamsForCourse(courseId, token);
      if (response) {
        setAssessments(response);
      } else {
        console.error("Failed to fetch assessments.");
      }
    } catch (error) {
      console.error("Error fetching assessments:", error);
    }
  };

  // Fetch courses when the component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await CourseApiService.getCoursesForTeacher(token);
        if (response.status === 200) {
          const coursesData = await response.json();
          setCourses(coursesData);

          if (courseAssessment) {
            const matchingCourse = coursesData.find((course) => course.id === courseAssessment.course);
            if (matchingCourse) {
              setSelectedCourse(matchingCourse);
              await fetchExamsForCourse(matchingCourse.id);
            }
          }
        } else {
          console.error("Failed to fetch courses.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [token, courseAssessment]);

  const handleUploadScripts = async (event) => {
    event.preventDefault();

    const newAssessmentData = {
      course: selectedCourse.id,
      exam_paper: selectedAssessmentId,
      assessment_date: assessmentDate,
      pass_mark: passMark,
      instructions: instructions,
      marking_objectives: markingObjectives,
    };

    try {
      const response = await CourseAssessmentResultsApiService.createCourseAssessmentResults(newAssessmentData, token);
      onUploadLearners(selectedCourse, response);
    } catch (error) {
      console.error("Error creating course assessment:", error);
    }
  };

  // Handle course change
  const handleCourseChange = (e) => {
  const selectedCourse = e.target.value
  setSelectedCourse(selectedCourse);
  setSelectedAssessmentId('');
  fetchExamsForCourse(selectedCourse.id);
};

  // Disable the button if course or assessment is not selected
  const isButtonDisabled = !(selectedCourse && selectedAssessmentId);

  return (
    <Card
      sx={{
        overflow: 'auto',
        border: "1px solid #5f9ea0",
        borderRadius: 2,
        boxShadow: 'none',
        mt: 2,
        mx: 'auto',
        padding: { xs: '0', sm: '10px', md: '15px' },
        width: { xs: '90%', sm: '85%', md: '90%' },
        height: 'auto',
        maxWidth: '1200px',
      }}
    >
      <CardContent>
        <form onSubmit={handleUploadScripts}>
          <div>
            <p className="marking-project-title">Start a new marking project</p>
            <p className="instructions-text">
              If you'd like a tutorial of this feature, please see the
              <a href="your-video-link-here" target="_blank" rel="noopener noreferrer" className="instructions-link">
                How-to video
              </a>.
            </p>

            {/* 1. Choose Course */}
            <Box mt={2}>
              <p className="section-title">1. Choose Class <span style={{ color: "red" }}>*</span></p>
              <TextField
                select
                fullWidth
                onChange={handleCourseChange}
                variant="outlined"
                placeholder="Select a course"
                value={selectedCourse}
                sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
                required
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course} style={{ color: '#323e48' }}>
                    {course.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {/* 2. Choose Assessment Paper */}
            <Box mt={2}>
              <p className="section-title">2. Choose Assessment Paper <span style={{ color: "red" }}>*</span></p>
              {selectedCourse ? (
                <TextField
                  select
                  fullWidth
                  value={selectedAssessmentId}
                  onChange={(e) => setSelectedAssessmentId(e.target.value)}
                  variant="outlined"
                  placeholder="Select an assessment paper"
                  sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
                  disabled={!selectedCourse}  // Disable if course is not selected
                  required
                >
                  {assessments.map((assessment) => (
                    <MenuItem key={assessment.id} value={assessment.id} style={{ color: '#323e48' }}>
                      {assessment.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  value="Waiting on class selection"
                  sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
                />
              )}
            </Box>

            {/* 3. Pass Mark */}
            <Box mt={2}>
              <p className="section-title">3. Pass Mark <span style={{ color: "red" }}>*</span></p>
              <TextField
                fullWidth
                type="number"
                value={passMark}
                onChange={(e) => setPassMark(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: <span>%</span>,
                }}
                placeholder="Enter pass percentage"
                required
              />
            </Box>

            {/* 4. Date to return */}
            <Box mt={2}>
              <p className="section-title">4. Date by which the scripts were written <span style={{ color: "red" }}>*</span></p>
              <TextField
                fullWidth
                type="date"
                value={assessmentDate}  // Default to today's date if not already set
                onChange={(e) => setAssessmentDate(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,  // Ensure that the label remains above the field when a value is entered
                }}
                required  // Make assessment date required
              />
            </Box>

            {/* 5. Marking Objectives Input */}
            <Box mt={2}>
              <p className="section-title">5. Marking Objectives <span style={{ color: "grey" }}>(optional)</span></p>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={markingObjectives}
                onChange={(e) => setMarkingObjectives(e.target.value)}
                variant="outlined"
                placeholder="Type the marking objectives here"
              />
            </Box>

            {/* 6. Instructions Input */}
            <Box mt={2}>
              <p className="section-title">6. Instructions <span style={{ color: "grey" }}>(optional)</span></p>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                variant="outlined"
                placeholder="Type the instructions for marking"
              />
            </Box>

            {/* Back and Submit Buttons */}
            <Box className="button-section" mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}  // Back button functionality to close the component
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"  // Form submission via button
                disabled={isButtonDisabled}  // Disable if course or assessment not selected
              >
                Upload Scripts
              </Button>
            </Box>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default ScriptDetails;
