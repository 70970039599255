import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import Header from "../../components/landingPage/header/header";
import { AuthenticationAPIService } from "../../services/api/AuthenticationAPIService";

const ChoosePassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { uidb64, token } = useParams();
    const navigate = useNavigate();

    const handleClickShowPassword = (field) => {
        if (field === 'newPassword') {
            setShowNewPassword(!showNewPassword);
        } else if (field === 'confirmNewPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCreateNewPassword = async () => {
        if (newPassword !== confirmNewPassword) {
            Swal.fire('Error', 'Passwords do not match.', 'error')
            return;
        }

        const response = await AuthenticationAPIService.choosePassword(newPassword, confirmNewPassword, uidb64, token);

        if (response.ok) {
            Swal.fire('Success', 'Password changed successfully!', 'success')
                .then(() => {
                    navigate('/signin');
                });
        } else {
            console.log("Response", response)
            Swal.fire('Error', response.error || 'An error occurred', 'error');
        }
    };

    return (
        <div>
            <Header className="section-spacing" />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="94vh"
                bgcolor="#FFFFFF"
                px={2}
            >
                <Box
                    p={4}
                    bgcolor="white"
                    borderRadius="8px"
                    boxShadow={3}
                    maxWidth="400px"
                >
                    <Typography variant="h5" component="h1" gutterBottom align="center">
                        Create New Password
                    </Typography>
                    <TextField
                        label="New Password"
                        type={showNewPassword ? 'text' : 'password'}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('newPassword')}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm New Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('confirmNewPassword')}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <br />
                    <Button variant="contained" color="primary" fullWidth onClick={handleCreateNewPassword}>
                        Create Password
                    </Button>
                    <Typography variant="body2" color="textSecondary" sx={{ my: 1, fontSize: "17px" }} align="center">
                        Remember your password? &nbsp;
                        <Link href="/signin">Sign in</Link>
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default ChoosePassword;