import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./CourseResults.css";
import CourseApiService from "../../../services/api/courseAPIService";

const CourseResults = () => {
    const [courses, setCourses] = useState([]);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;


    // Fetch courses when the component loads
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await CourseApiService.getCoursesForTeacher(token);
                const courseData = await response.json();
                setCourses(courseData);
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        fetchCourses();
    }, [token]);

    // Utility function to get initials for Avatar
    const getInitials = (name) => {
        if (!name) return "";
        return name.slice(0, 2).toUpperCase(); // Get the first two letters of the name
    };

    // Handle row click to navigate to the course test results
    const handleRowClick = (params) => {
        const selectedCourseId = params.row.id; // Get the course ID from the selected row
        navigate(`/test-results/${selectedCourseId}`); // Navigate to the test-results page with courseId
    };

    // DataGrid column definitions
    const columns = [
        {
            field: "name",
            headerName: "Course Name",
            flex: 2,
            align: "left",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ width: 32, height: 32, marginRight: 1, backgroundColor: '#5f9fa1' }}>
                        {getInitials(params.value)}
                    </Avatar>
                    <span>{params.value}</span>
                </div>
            ),
        },
        {
            field: "group",
            headerName: "Class",
            flex: 1,
            align: "left",
            headerClassName: "centered-header",
        },
    ];

    // Filter courses based on search input
    const filteredCourses = courses.filter(
        (course) =>
            course.name.toLowerCase().includes(searchText.toLowerCase()) ||
            course.grade.toString().includes(searchText.toLowerCase()) ||
            course.subject.toLowerCase().includes(searchText.toLowerCase()) ||
            course.group.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div style={{ padding: "20px" }}>
            <TextField
                id="search"
                placeholder="Search Courses"
                size="small"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                variant="outlined"
                InputProps={{
                    style: { width: "200px", borderRadius: 25 },
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "25px",
                        borderColor: "#d0d4d9",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#d0d4d9",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#b3b3b3",
                    },
                    "& .MuiInputBase-input": {
                        padding: "10px",
                        color: "#5f9ea0",
                    },
                }}
            />

            <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
                <DataGrid
                    rows={filteredCourses}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    getRowId={(row) => row.id}
                    onRowClick={handleRowClick} // Handle row click
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        border: "none",
                    }}
                />
            </div>
        </div>
    );
};

export default CourseResults;
