import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { TopicApiService } from "../../services/api/topicAPIService";
import SubjectApiService from "../../services/api/subjectAPIService";

const ManageSubjectsTopics = () => {
    const [subjects, setSubjects] = useState([]);
    const [grades] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
    const [topics, setTopics] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState("");// For editing a topic
    const [selectedSubjectToEdit, setSelectedSubjectToEdit] = useState(""); // For editing a subject
    const [newTopicName, setNewTopicName] = useState("");
    const [newTerm, setNewTerm] = useState("");
    const [newSubjectName, setNewSubjectName] = useState("");
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [showTopics, setShowTopics] = useState(true);
    const [subjectDialogOpen, setSubjectDialogOpen] = useState(false);
    const [topicDialogOpen, setTopicDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [setSelectedTopic, selectedTopic] = useState("");

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
    };

    const handleEditSubject = (subject) => {
        setNewSubjectName(subject.name);
        setSelectedGrades(subject.grades.map(grade => grade.grade)); // Set the grades for the subject being edited
        setEditMode(true);
        setSelectedSubjectToEdit(subject.id);
        setSubjectDialogOpen(true);
    };

    const handleEditTopic = (topic) => {
        setSelectedSubject(topic.subject.id);
        setSelectedGrade(topic.grade);
        setNewTerm(topic.term);
        setNewTopicName(topic.name);
        setEditMode(true);
        setSelectedTopic(topic.id);
        setTopicDialogOpen(true);
    };

    const fetchInitialData = async () => {
        try {
            const [subjectsResponse, topicsResponse, userResponse] = await Promise.all([
                SubjectApiService.fetchSubjects(),
                TopicApiService.fetchAllTopics(),
                SubjectApiService.fetchCurrentUser(),
            ]);

            const subjectsData = await subjectsResponse.json();
            const topicsData = await topicsResponse.json();
            const userData = await userResponse.json();

            setSubjects(subjectsData);
            setTopics(topicsData);
            setIsSuperUser(userData.is_superuser);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const handleGradeChange = (event) => {
        setSelectedGrade(event.target.value);
    };

    const handleSelectedGradesChange = (event) => {
        setSelectedGrades(event.target.value);
    };

    const handleNewTopicNameChange = (event) => {
        setNewTopicName(event.target.value);
    };

    const handleNewTermChange = (event) => {
        setNewTerm(event.target.value);
    };

    const handleNewSubjectNameChange = (event) => {
        setNewSubjectName(event.target.value);
    };

    const handleCreateOrEditSubject = async () => {
        try {
            if (!newSubjectName) {
                return Swal.fire("Error", "Subject name is required.", "error");
            }
            const subjectData = {
                name: newSubjectName,
                grades: selectedGrades.map(grade => ({ grade })), // Include selected grades in the format the API expects
            };
            if (editMode) {
                await SubjectApiService.updateSubject(selectedSubjectToEdit, subjectData); // Update API call
            } else {
                await SubjectApiService.createSubject(subjectData);
            }
            await fetchInitialData();
            setNewSubjectName("");
            setSelectedGrades([]);
            setSubjectDialogOpen(false);
            setEditMode(false);
            Swal.fire("Success", `${editMode ? "Subject updated" : "Subject created"} successfully!`, "success");
        } catch (error) {
            Swal.fire("Error", `Failed to ${editMode ? "update" : "create"} subject.`, "error");
        }
    };

    const handleCreateOrEditTopic = async () => {
        try {
            if (!newTopicName || !selectedSubject || !selectedGrade || !newTerm) {
                return Swal.fire("Error", "All fields are required.", "error");
            }
            const topicData = {
                name: newTopicName,
                subject: selectedSubject,
                grade: selectedGrade,
                term: newTerm,
            };
            if (editMode) {
                await TopicApiService.updateTopic(selectedTopic, topicData); // Update API call
            } else {
                await TopicApiService.createTopic(topicData);
            }
            await fetchInitialData();
            setNewTopicName("");
            setSelectedSubject("");
            setSelectedGrade("");
            setNewTerm("");
            setTopicDialogOpen(false);
            setEditMode(false);
            Swal.fire("Success", `${editMode ? "Topic updated" : "Topic created"} successfully!`, "success");
        } catch (error) {
            Swal.fire("Error", `Failed to ${editMode ? "update" : "create"} topic.`, "error");
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredSubjects = subjects.filter(subject =>
        subject.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subject.grades.some(grade => grade.grade.includes(searchTerm))
    );

    const filteredTopics = topics.filter(topic =>
        topic.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        topic.subject.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        topic.grade.includes(searchTerm)
    );

    const subjectsToShow = filteredSubjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const topicsToShow = filteredTopics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ padding: 3, maxHeight: '80vh', overflowY: 'auto' }}>
            {isSuperUser ? (
                <>
                    <Typography variant="h4" sx={{ color: '#466374', marginBottom: 2 }}>
                        Manage Subjects and Topics
                    </Typography>

                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                        <Switch
                            checked={showTopics}
                            onChange={() => setShowTopics(!showTopics)}
                            color="primary"
                        />
                        <Typography variant="body1">{showTopics ? "Show Topics" : "Show Subjects"}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                        <TextField
                            placeholder="Search..."
                            variant="outlined"
                            sx={{ width: "100%", borderColor: '#00b2e3' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <IconButton
                            color="primary"
                            onClick={() => {
                                if (showTopics) {
                                    setTopicDialogOpen(true);
                                } else {
                                    setSubjectDialogOpen(true);
                                }
                            }}
                            sx={{
                                backgroundColor: '#00b2e3',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#007ea7',
                                },
                                marginLeft: 2,
                                borderRadius: '50%',
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Box>

                    <Paper sx={{ padding: 2 }}>
                        {showTopics ? (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Subject</TableCell>
                                            <TableCell>Grade</TableCell>
                                            <TableCell>Term</TableCell>
                                            <TableCell>Topic</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {topicsToShow.map((topic) => (
                                            <TableRow key={topic.id}>
                                                <TableCell>{topic.subject.name}</TableCell>
                                                <TableCell>Grade {topic.grade}</TableCell>
                                                <TableCell>Term {topic.term}</TableCell>
                                                <TableCell>{topic.name}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => handleEditTopic(topic)}>Edit</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    count={filteredTopics.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        ) : (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Subject</TableCell>
                                            <TableCell>Grades</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subjectsToShow.map((subject) => (
                                            <TableRow key={subject.id}>
                                                <TableCell>{subject.name}</TableCell>
                                                <TableCell>{subject.grades.map(grade => `Grade ${grade.grade}`).join(", ")}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => handleEditSubject(subject)}>Edit</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    count={filteredSubjects.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Paper>

                    {/* Dialog for Creating or Editing Subject */}
                    <Dialog open={subjectDialogOpen} onClose={() => setSubjectDialogOpen(false)}>
                        <DialogTitle>{editMode ? "Edit Subject" : "Create New Subject"}</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Subject Name"
                                value={newSubjectName}
                                onChange={handleNewSubjectNameChange}
                                fullWidth
                                margin="normal"
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Grades</InputLabel>
                                <Select
                                    multiple
                                    value={selectedGrades}
                                    onChange={handleSelectedGradesChange}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {grades.map((grade) => (
                                        <MenuItem key={grade} value={grade}>
                                            Grade {grade}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setSubjectDialogOpen(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleCreateOrEditSubject} color="primary">
                                {editMode ? "Update Subject" : "Create Subject"}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Dialog for Creating or Editing Topic */}
                    <Dialog open={topicDialogOpen} onClose={() => setTopicDialogOpen(false)}>
                        <DialogTitle>{editMode ? "Edit Topic" : "Create New Topic"}</DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Subject</InputLabel>
                                <Select value={selectedSubject} onChange={handleSubjectChange}>
                                    {subjects.map((subject) => (
                                        <MenuItem key={subject.id} value={subject.id}>
                                            {subject.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <InputLabel>Grade</InputLabel>
                                <Select value={selectedGrade} onChange={handleGradeChange}>
                                    {grades.map((grade) => (
                                        <MenuItem key={grade} value={grade}>
                                            Grade {grade}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <InputLabel>Term</InputLabel>
                                <Select value={newTerm} onChange={handleNewTermChange}>
                                    <MenuItem value="1">Term 1</MenuItem>
                                    <MenuItem value="2">Term 2</MenuItem>
                                    <MenuItem value="3">Term 3</MenuItem>
                                    <MenuItem value="4">Term 4</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                label="Topic Name"
                                value={newTopicName}
                                onChange={handleNewTopicNameChange}
                                fullWidth
                                margin="normal"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setTopicDialogOpen(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleCreateOrEditTopic} color="primary">
                                {editMode ? "Update Topic" : "Create Topic"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <Typography variant="h6" color="error">
                    You do not have permission to manage subjects and topics.
                </Typography>
            )}
        </Box>
    );
};

export default ManageSubjectsTopics;
