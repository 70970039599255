import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ src, onClose }) => {
  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1000,
  };

  const imageStyle = {
    maxWidth: '90%',
    maxHeight: '90%',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: 'white',
  };

  return (
    <div style={modalStyle} onClick={onClose}>
      <img src={src} alt="Full size" style={imageStyle} onClick={(e) => e.stopPropagation()} />
      <CloseIcon style={closeButtonStyle} onClick={onClose} />
    </div>
  );
};

Modal.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;