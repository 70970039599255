import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import "./Metrics.css";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import TableChartIcon from "@mui/icons-material/TableChart";
import CoreSubjects from "./district/gauge/gauge";


// Mock data for yearly pass rates
const yearlyData = [
  { year: 2018, expected: 70, received: 50 },
  { year: 2019, expected: 72, received: 55 },
  { year: 2020, expected: 75, received: 58 },
  { year: 2021, expected: 78, received: 60 },
  { year: 2022, expected: 80, received: 62 },
];

// Mock data for pass rates per term
const termData = [
  { term: "Term 1", expected: 75, received: 55 },
  { term: "Term 2", expected: 77, received: 58 },
  { term: "Term 3", expected: 78, received: 60 },
  { term: "Term 4", expected: 80, received: 62 },
];

// Mock data for top 3 performing schools
const top3Schools = [
  {
    school: "School 1",
    performance: 90,
    passed: 85,
    failed: 5,
    principal: "Mr. John Doe",
    icon: "/school-icons/School1.png",
  },
  {
    school: "School 2",
    performance: 85,
    passed: 80,
    failed: 5,
    principal: "Ms. Jane Smith",
    icon: "/school-icons/School2.png",
  },
  {
    school: "School 3",
    performance: 82,
    passed: 77,
    failed: 5,
    principal: "Mr. Richard Roe",
    icon: "/school-icons/School3.png",
  },
];


const leastPerformingSchool = [
  { topic: "School 1", performance: 40, passed: 30, failed: 10 },
  { topic: "Schhol 2", performance: 35, passed: 25, failed: 10 },
  { topic: "Schhol 3", performance: 30, passed: 20, failed: 10 },
];



const subjectScores = [
  { subject: "English", score: 79, trend: "up", trendPercentage: 11, trendText: "from last year" },
  { subject: "Mathematics", score: 79, trend: "up", trendPercentage: 11, trendText: "from last year" },
  { subject: "Zulu", score: 79, trend: "down", trendPercentage: 11, trendText: "from last year" },
];

// Data for district learners, girls passed, boys passed/failed
const districtData = {
  totalLearners: 1500,
  passed: 780,
  boysPassed: 670,
  failed: 50,
};

// Mock data for 40 schools with pass rates and details
const schoolDetails = Array.from({ length: 40 }, (_, i) => ({
  school: `School ${i + 1}`, // Corrected string interpolation
  totalStudents: Math.floor(Math.random() * 100) + 100, // Random total between 100 and 200 students
  passCount: Math.floor(Math.random() * 50) + 50, // Random pass count between 50 and 100
})).map(school => ({
  ...school,
  failCount: school.totalStudents - school.passCount,
  passRate: (school.passCount / school.totalStudents) * 100
}));

// Mock data for previous year's statistics
const previousYearData = {
  totalLearners: 1400,   // Total learners last year
  passed: 1350,          // Total passed last year
  failed: 50             // Total failed last year
};


const Metrics = () => {
  const [viewMode, setViewMode] = useState("yearly"); // "yearly" or "term"
  const [showTableOnly, setShowTableOnly] = useState(false); // Toggle for showing only the table

  return (
    <div className="metrics-container">
      {/* Vele District Pass Rate Heading */}
{/* Breadcrumb Heading */}
<Box className="flex-header" style={{ display: 'flex', alignItems: 'center' }}>
  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#333' }}>
    Analytics
  </Typography>
  <Typography variant="h6" gutterBottom style={{ margin: '0 5px' }}>
    &gt;
  </Typography>
  <Typography variant="h6" gutterBottom style={{ color: '#5ac8fa' }}>
    District Performance
  </Typography>
<Tooltip title={showTableOnly ? "Show All Data" : "Show Table Only"}>
  <Button
    variant="contained"
    color="info"
    onClick={() => setShowTableOnly(!showTableOnly)}
    style={{ marginLeft: 'auto' }} // Move the icon to the right
  >
    <TableChartIcon />
  </Button>
</Tooltip>

</Box>


      {/* Conditional Rendering based on toggle */}
{!showTableOnly && (
  <>
    <Grid container spacing={2}>

      {/* Left Column: District Data and Expected vs Actual Pass Rate */}
      <Grid item xs={12} md={9}>
        {/* District Data Card */}
<Grid container spacing={2}>
{/* Total Learners */}
<Grid item xs={3}>
  <Card className="small-card">
    <CardContent>
      <Box style={{ textAlign: 'center' }}>
        <div className="card-title">Total Learners</div>
        <div className="card-value">{districtData.totalLearners}</div>
        <div className="card-small-text">({districtData.passed + districtData.boysPassed})</div>
        <div className={`card-percentage-diff ${districtData.failed > previousYearData.failed ? 'fail' : 'pass'}`}>
          {districtData.failed > previousYearData.failed ? '↑' : '↓'} {Math.abs(((districtData.failed / previousYearData.failed) * 100) - 100).toFixed(2)}% from last year
        </div>
      </Box>
    </CardContent>
  </Card>
</Grid>


{/* Passed */}
<Grid item xs={3}>
  <Card className="small-card">
    <CardContent>
      <Box style={{ textAlign: 'center' }}>
        <div className="card-title">Passed</div>
        <div className="card-value">{(((districtData.passed + districtData.boysPassed) / districtData.totalLearners) * 100).toFixed(2)}%</div>
        <div className="card-small-text">({districtData.passed + districtData.boysPassed})</div>
        <div className={`card-percentage-diff ${districtData.passed + districtData.boysPassed > previousYearData.passed ? 'pass' : 'fail'}`}>
          {(districtData.passed + districtData.boysPassed) > previousYearData.passed ? '↑' : '↓'} {Math.abs((((districtData.passed + districtData.boysPassed) / previousYearData.passed) * 100) - 100).toFixed(2)}% from last year
        </div>
      </Box>
    </CardContent>
  </Card>
</Grid>


{/* Failed */}
<Grid item xs={3}>
  <Card className="small-card">
    <CardContent>
      <Box style={{ textAlign: 'center' }}>
        <div className="card-title">Failed</div>
        <div className="card-value">{((districtData.failed / districtData.totalLearners) * 100).toFixed(2)}%</div>
        <div className="card-small-text">({districtData.failed})</div>
        <div className={`card-percentage-diff ${districtData.failed > previousYearData.failed ? 'fail' : 'pass'}`}>
          {districtData.failed > previousYearData.failed ? '↑' : '↓'} {Math.abs(((districtData.failed / previousYearData.failed) * 100) - 100).toFixed(2)}% from last year
        </div>
      </Box>
    </CardContent>
  </Card>
</Grid>


{/* New Column: Pass Rate */}
<Grid item xs={3}>
  <Card className="small-card">
    <CardContent>
      <Box style={{ textAlign: 'center' }}>
        <div className="card-title">Pass Rate</div>
        <div className="card-value">{(((districtData.passed + districtData.boysPassed) / districtData.totalLearners) * 100).toFixed(2)}%</div>
        <div className="card-small-text">({districtData.passed + districtData.boysPassed})</div>
        <div className={`card-percentage-diff ${districtData.failed > previousYearData.failed ? 'fail' : 'pass'}`}>
          {districtData.failed > previousYearData.failed ? '↑' : '↓'} {Math.abs(((districtData.failed / previousYearData.failed) * 100) - 100).toFixed(2)}% from last year
        </div>
      </Box>
    </CardContent>
  </Card>
</Grid>

</Grid>

        {/* New Row for Two Graphs */}
            <Grid container spacing={2}>
{/* Top 3 Performing Schools */}
<Grid item xs={6}>
  <Card className="chart-card">
    <CardContent>
      <div className="chart-title">Top 3 Performing Schools</div>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={top3Schools}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="school" />
          <YAxis />
          <Tooltip content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              const schoolData = payload[0].payload;
              return (
                <div className="custom-tooltip">
                  <div className="tooltip-header">
                    {/* Display school icon */}
                    <img src={schoolData.icon} alt={label} className="school-icon" />
                    <strong>{label}</strong>
                  </div>
                  <p>Principal: {schoolData.principal}</p>
                  <p>Pass Rate: <span style={{ color: "#5f9ea0" }}>{schoolData.passed} Learners</span></p>
                  <p>Fail Rate: <span style={{ color: "pink" }}>{schoolData.failed} Learners</span></p>
                  <p>Total Performance: {schoolData.performance}%</p>
                </div>
              );
            }
            return null;
          }} />
          {/* Pass/Fail Bars */}
          <Bar dataKey="passed" fill="#28a745" name="Passed Learners" />
          <Bar dataKey="failed" fill="#dc3545" name="Failed Learners" />
        </BarChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
</Grid>


{/* Top 3 Least Performing Schools */}
<Grid item xs={6}>
  <Card className="chart-card">
    <CardContent>
      <div className="chart-title">Top 3 most improved schools</div>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={leastPerformingSchool}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="school" />
          <YAxis />
          <Tooltip content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              const schoolData = payload[0].payload;
              return (
                <div className="custom-tooltip">
                  <div className="tooltip-header">
                    {/* Add a school icon */}
                    <img src={`/path/to/school-icons/${label}.png`} alt={label} className="school-icon" />
                    <strong>{label}</strong>
                  </div>
                  <p>Pass Rate: <span style={{ color: "#5f9ea0" }}>{schoolData.passed} Learners</span></p>
                  <p>Fail Rate: <span style={{ color: "pink" }}>{schoolData.failed} Learners</span></p>
                  <p>Total Performance: {schoolData.performance}%</p>
                </div>
              );
            }
            return null;
          }} />
          {/* Pass/Fail Bars */}
          <Bar dataKey="passed" fill="#28a745" name="Passed Learners" />
          <Bar dataKey="failed" fill="#dc3545" name="Failed Learners" />
        </BarChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
</Grid>
            </Grid>
        {/* Expected vs Actual Pass Rate Graph */}
        <Card style={{ marginBottom: '20px', height: '35%', width: '100%', borderRadius: '15px' }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <div className="chart-title">Expected vs. Actual Pass Rate</div>
             <Box className="toggle-container">
  <Tooltip title={viewMode === "yearly" ? "Switch to Term View" : "Switch to Year View"}>
    <Button
      variant="contained"
      className="toggle-button"
      onClick={() => setViewMode(viewMode === "yearly" ? "term" : "yearly")}
    >
      {viewMode === "yearly" ? "Year View" : "Term View"}
    </Button>
  </Tooltip>
</Box>
            </Box>

            <ResponsiveContainer width="100%" height={200}>
            <LineChart
                data={viewMode === "yearly" ? yearlyData : termData}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={viewMode === "yearly" ? "year" : "term"} />
                <YAxis />
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="expected"
                  stroke="#8884d8"
                  strokeWidth={2}
                  dot={{ r: 6 }}
                  activeDot={{ r: 8 }}
                  name="Expected Pass Rate"
                />
                <Line
                  type="monotone"
                  dataKey="received"
                  stroke="#82ca9d"
                  strokeWidth={2}
                  dot={{ r: 6 }}
                  activeDot={{ r: 8 }}
                  name="Actual Pass Rate"
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      {/* Right Column: Core Subject Average Marks */}
      <Grid item xs={12} md={3}>
        <Card style={{ height: '94%', borderRadius: '15px' }}>
          <CardContent>
            <div className="chart-title">Core Subject Average Marks</div>
            <div className="subject-score-container">
              {subjectScores.map((data, index) => (
                  <CoreSubjects
                      key={index}
                      subject={data.subject}
                      score={data.score}
                      trend={data.trend}
                      trendPercentage={data.trendPercentage}
                      trendText={data.trendText}
                  />
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
)}

      {/* Show/Hide Pass/Fail Table based on toggle */}
      {showTableOnly && (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Detailed Pass/Fail per School</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>School Name</TableCell>
                    <TableCell align="right">Total Students</TableCell>
                    <TableCell align="right">Passed</TableCell>
                    <TableCell align="right">Failed</TableCell>
                    <TableCell align="right">Pass Rate (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolDetails.map((school) => (
                    <TableRow key={school.school}>
                      <TableCell component="th" scope="row">{school.school}</TableCell>
                      <TableCell align="right">{school.totalStudents}</TableCell>
                      <TableCell align="right">{school.passCount}</TableCell>
                      <TableCell align="right">{school.failCount}</TableCell>
                      <TableCell align="right">{school.passRate.toFixed(2)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Metrics;
