// TestHeading.js
import React from "react";
import "./TestHeading.css";
import logo from "../../../assets/educase-logo-dark.png"; // Import your logo image here

const TestHeading = ({ testName, subjectName, grade }) => {
    return (
        <div className="test-header">
            <img src={logo} alt="Company Logo" className="logo-image" />
            <div className="test-details">
                <h1 className="assessmentHeading">
                    Test Name: {testName}
                </h1>
                <h2 className="subjectHeading">
                    Subject: {subjectName}
                </h2>
                <h2 className="gradeHeading">
                    Grade: {grade}
                </h2>
            </div>
        </div>
    );
};

export default TestHeading;